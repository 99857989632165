import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import { Button, Form, Input, Select } from "antd";
import MainButton from "components/button";
import React, { FC } from "react";
const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
  onClose: () => void;
}

const FormEditUser: FC<IProps> = ({ onFinish, onClose }: IProps) => {
  return (
    <Form name="basic" onFinish={onFinish} autoComplete="off">
      <SimpleGrid columns={3} spacing={3}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Vui lòng nhập tên tổ chức!" }]}
        >
          <Input className="custom-input " placeholder="Tên tổ chức" />
        </Form.Item>
        <Form.Item
          name="username2"
          rules={[{ required: true, message: "Vui lòng nhập mã tổ chức!" }]}
        >
          <Input className="custom-input " placeholder="Mã tổ chức" />
        </Form.Item>
        <Form.Item
          name="username3"
          rules={[
            { required: true, message: "Vui lòng nhập Email!", type: "email" },
          ]}
        >
          <Input className="custom-input" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="username4"
          rules={[{ required: true, message: "Vui lòng nhập số điện thoại!" }]}
        >
          <Input className="custom-input" placeholder="Số điện thoại" />
        </Form.Item>
        <Form.Item
          name="username4"
          rules={[{ required: true, message: "Vui lòng nhập địa chỉ!" }]}
        >
          <Input className="custom-input" placeholder="Địa chỉ" />
        </Form.Item>

        <Form.Item
          name="gender"
          rules={[{ required: true, message: "Vui lòng nhập quyền!" }]}
        >
          <Select placeholder="Quyền" className="w-custom-1 custom-input">
            <Option value="male">male</Option>
            <Option value="female">female</Option>
            <Option value="other">other</Option>
          </Select>
        </Form.Item>
      </SimpleGrid>

      <Flex flexDirection={"row"} alignContent="center" justifyContent="center">
        <Button
          htmlType="submit"
          className="custom-button button-dash"
          onClick={onClose}
          style={{
            width: "95px",
          }}
        >
          Hủy
        </Button>
        <Box ml="2">
          <MainButton htmlType="submit" text="Xác nhận" />
        </Box>
      </Flex>
    </Form>
  );
};

export default FormEditUser;
