import React from "react";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import OsaLayout from "./layouts/osa";
import CsdtLayout from "./layouts/csdt";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { store } from "./store";
import { Provider } from "react-redux";
import { ADMIN, AUTH, CSDT, OSA } from "util/Constant";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const DEFAULT_ROUTER = "/organization-management";

Sentry.init({
  dsn: process.env.REACT_APP_BASE_SENTRY,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <HashRouter>
          <Switch>
            <Route path={AUTH} component={AuthLayout} />
            <Route path={ADMIN} component={AdminLayout} />
            <Route path={OSA} component={OsaLayout} />
            <Route path={CSDT} component={CsdtLayout} />
            <Redirect from="/" to={`${ADMIN}${DEFAULT_ROUTER}`} />
          </Switch>
        </HashRouter>
      </React.StrictMode>
    </ChakraProvider>
  </Provider>
);
