import { InputRef } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { IPlainOptionsMap } from "types/general";
import { validResponse, NetWorkService } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import { EnumPermission } from "util/Constant";
import View from "./View";
import * as Sentry from "@sentry/browser";

const TransactionManagement = () => {
  const HEGHT = 36;
  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Thành công", value: "1", type: "eq" },
    { name: "Đang chờ xử lý", value: "2", type: "eq" },
    { name: "Thất bại", value: "0", type: "eq" },
  ];
  const defaultChecked = "";

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { appPermission, authInfor } = appState;

  const [billList, setBillList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);

  const {
    handleSearch,
    handleSetQuery,
    getColumnDateProps,
    getColumnSearchProps,
    getColumnSelectProps,
  } = FilterAntdHook({
    searchInput,
    // plainOptions,
    searchedColumn,
    plainOptionsMap,
    defaultChecked,
    setCurrentPage,
    setSearchedColumn,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
        expaned: e?.chi_tiet ? (e?.chi_tiet.trim() ? "1" : "") : "",
        height: e?.noi_dung
          ? `${
              e?.noi_dung.length
                ? e?.noi_dung[0]?.split("<br>").length * HEGHT
                : e?.noi_dung?.split("<br>").length * HEGHT
            }px`
          : `${HEGHT}px`,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      const URL =
        appPermission === EnumPermission.ADMIN
          ? "/payment-history"
          : `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/payment-history`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setBillList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setBillList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <View
      loading={loading}
      pageSize={pageSize}
      billList={billList}
      totalRow={totalRow}
      currentPage={currentPage}
      handleSearch={handleSearch}
      getColumnDateProps={getColumnDateProps}
      onChangeCurrentPage={onChangeCurrentPage}
      getColumnSearchProps={getColumnSearchProps}
      getColumnSelectProps={getColumnSelectProps}
    />
  );
};

export default TransactionManagement;
