import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import MainButton from "components/button";
import { Button, Form, FormInstance, Input } from "antd";

interface IProps {
  onFinish: (values: any) => void;
  onClose: () => void;
  formRef: React.MutableRefObject<FormInstance<any>>;
}

const FormAddNewBranch = ({ onFinish, onClose, formRef }: IProps) => {
  const onReset = () => {
    formRef?.current?.setFieldsValue({
      organizationCode: "",
      organizationName: "",
    });
  };

  return (
    <Form
      name="FormAddNewOrganization"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      ref={formRef}
    >
      <Box>
        <Form.Item
          labelAlign="left"
          label="Tên chi nhánh"
          name="branchName"
          rules={[{ required: true, message: "Vui lòng nhập tên chi nhánh!" }]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã chi nhánh"
          name="branchCode"
          rules={[{ required: true, message: "Vui lòng nhập mã chi nhánh!" }]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
      </Box>
      <Flex flexDirection={"row"} alignContent="center" justifyContent="center">
        <Button
          className="custom-button button-dash"
          onClick={() => {
            onClose();
            onReset();
          }}
          style={{
            width: "95px",
          }}
        >
          Hủy
        </Button>
        <Box ml="2">
          <MainButton htmlType="submit" text="Xác nhận" />
        </Box>
      </Flex>
    </Form>
  );
};

export default FormAddNewBranch;
