import { Box } from "@chakra-ui/react";
import { Input } from "antd";
import React from "react";
import { PaymentConfigPartnerBIDV } from "service/OrganizationAdmin/Organization";

interface P {
  item: PaymentConfigPartnerBIDV;
  index: number;
  onChangeValue: (
    index: number,
    key: string,
    values: string,
    type: "nested" | "plane"
  ) => void;
}

const RenderRowInput = ({ item, index, onChangeValue }: P) => {
  return (
    <>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập tên tk"
            onChange={(e) =>
              onChangeValue(index, "configName", e.target.value, "nested")
            }
            defaultValue={item.configName}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập clientId"
            onChange={(e) =>
              onChangeValue(index, "clientId", e.target.value, "nested")
            }
            defaultValue={item.extraData.clientId}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập clientSecret"
            onChange={(e) =>
              onChangeValue(index, "clientSecret", e.target.value, "nested")
            }
            defaultValue={item.extraData.clientSecret}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập clientName"
            onChange={(e) =>
              onChangeValue(index, "clientName", e.target.value, "nested")
            }
            defaultValue={item.extraData.clientName}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập serviceId"
            onChange={(e) => onChangeValue(index, "serviceId", e.target.value, "nested")}
            defaultValue={item.extraData.serviceId}
          />
        </Box>
      </td>
      <td>
        {/* <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập ghi chú"
            onChange={(e) => onChangeValue(index, "desc", e.target.value)}
            defaultValue={item.desc}
          />
        </Box> */}
      </td>
    </>
  );
};

export default RenderRowInput;
