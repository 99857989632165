import { Box } from "@chakra-ui/react";
import { Form, FormInstance, Input, Select } from "antd";
import ButtonFooter from "components/ButtonFooter";
import { InputOrganizationCreate } from "service/Auth";
import { PATTERN_PHONE } from "util/Constant";

const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
  formRef: React.MutableRefObject<FormInstance<any>>;
  organizationCreate: InputOrganizationCreate;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
}

const FormLoginAccount = ({
  isEdit,
  formRef,
  organizationCreate,
  onFinish,
  setIsEdit,
}: IProps) => {
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Box w="80%" maxW="755px">
        <Form.Item
          name="username"
          label="Tên tài khoản"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng nhập tên tài khoản!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Tên tài khoản"
            disabled={organizationCreate ? true : false || !isEdit}
          />
        </Form.Item>
        <Form.Item
          name="fullName"
          label="Họ và tên"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Họ và tên"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          labelAlign="left"
          rules={[
            { required: true, message: "Vui lòng nhập Email!" },
            { type: "email", message: "Vui lòng nhập đúng định dạng email!" },
          ]}
        >
          <Input
            className="custom-input "
            placeholder="Email"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Số điện thoại"
          labelAlign="left"
          rules={[
            {
              pattern: PATTERN_PHONE,
              message: "Vui lòng nhập đúng định dạng số điện thoại!",
            },
            {
              required: true,
              message: "Vui lòng nhập số điện thoại!",
            },
          ]}
        >
          <Input
            className="custom-input "
            placeholder="Số điện thoại"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Địa chỉ"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng nhập Địa chỉ!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Địa chỉ"
            disabled={!isEdit}
          />
        </Form.Item>
        {organizationCreate && (
          <Form.Item
            labelAlign="left"
            label="Trạng thái"
            name="status"
            // initialValue={initValue?.status}
            rules={[{ required: false, message: "Vui lòng nhập trạng thái!" }]}
          >
            <Select className="w-full custom-input" disabled={!isEdit}>
              <Option value="1">Hoạt động</Option>
              <Option value="0">Không hoạt động</Option>
            </Select>
          </Form.Item>
        )}

        <ButtonFooter
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          text={"Xác nhận"}
          text2="Cập nhật"
          formRef={formRef}
        />
      </Box>
    </Form>
  );
};

export default FormLoginAccount;
