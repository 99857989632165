import { DatePicker, Form, Input, InputRef } from "antd";
import { useDebounce } from "util/debounce";
import { FilterOutlined } from "@ant-design/icons";
import FilterStatus from "components/filterAntd/FilterStatus";
import MainButton from "components/button";
// import moment from "moment";
import { IPlainOptionsMap } from "types/general";
import moment from "moment";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";

interface IProps {
  searchedColumn: any;
  defaultChecked?: string;
  searchInput: React.MutableRefObject<InputRef>;
  plainOptionsMap?: IPlainOptionsMap[];
  setSearchedColumn: React.Dispatch<any>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const { RangePicker } = DatePicker;

let searched: any = {}; // biến nhận filter các field

export const FilterAntdHook = ({
  searchInput,
  searchedColumn,
  plainOptionsMap,
  defaultChecked,
  setCurrentPage,
  setSearchedColumn,
}: IProps) => {
  const [form] = Form.useForm();

  const handleSearchDate = useDebounce(({ value, key, filterType }: any) => {
    setCurrentPage(1);
    const startDate = value[0]
      ? new Date(
          moment(new Date(value[0]).getTime()).startOf("day").toString()
        ).getTime()
      : "";
    const endDate = value[0]
      ? new Date(
          moment(new Date(value[1]).getTime()).endOf("day").toString()
        ).getTime()
      : "";

    const obj: any = {};

    obj[key] = {
      filterType,
      type: "date",
      startDate,
      endDate,
    };
    searched = { ...searched, ...obj };

    let data: any = {};
    Object.keys(searched).forEach((e) => {
      if (
        searched[e].filter ||
        searched[e].startDate ||
        searched[e].filter === 0 ||
        searched[e].option1
      ) {
        data[`${e}`] = searched[e];
      }
    });

    searched = { ...data };

    setSearchedColumn(data);
  }, 1000);

  const handleSearch = useDebounce(
    ({ value, key, type, filterType, option1, option2, isSeachText }: any) => {
      const obj: any = {};
      setCurrentPage(1);

      if (filterType === "mutil") {
        obj[key] = {
          option1,
          option2,
          filterType,
          type,
        };
      } else {
        obj[key] = {
          filter: value,
          filterType,
          type,
        };
      }

      searched = { ...searched, ...obj };

      let data: any = {};
      Object.keys(searched).forEach((e) => {
        if (
          searched[e].filter ||
          searched[e].filter === 0 ||
          searched[e].option1 ||
          searched[e].startDate
        ) {
          data[`${e}`] = searched[e];
        }
      });

      searched = { ...data };

      !isSeachText && setSearchedColumn(data);

      !value && setSearchedColumn(data);
    },
    0
  );

  useEffect(() => {
    // reset filter khi moi load trang
    if (!searchedColumn) {
      searched = {};
    }
  }, [searchedColumn]);

  const handleSetShowIcon = (dataIndex: string) => {
    for (const key in searched) {
      if (dataIndex === key) {
        return true;
      }
    }

    return false;
  };

  const getColumnSearchProps = (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ): any => ({
    filterDropdown: () => (
      <Form form={form}>
        <Flex
          style={{
            padding: "8px",
          }}
          flexDirection={"column"}
          justifyContent="flex-end"
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Form.Item name={dataIndex} className={"mb-0"}>
            <Input
              allowClear
              ref={searchInput}
              onChange={(e) => {
                handleSearch({
                  value: e.target.value,
                  key: dataIndex,
                  type,
                  filterType,
                  isSeachText: true,
                });
              }}
              style={{
                marginBottom: 8,
              }}
            />
          </Form.Item>

          <Box ml="auto">
            <MainButton
              text="Tìm kiếm"
              onClick={() => {
                handleSearch({
                  value: form.getFieldsValue()[`${dataIndex}`],
                  key: dataIndex,
                  type,
                  filterType,
                });
              }}
            />
          </Box>
        </Flex>
      </Form>
    ),
    filterIcon: (filtered: boolean) => (
      <FilterOutlined
        style={{ color: filtered ? "#1677ff" : undefined }}
        className={handleSetShowIcon(dataIndex) ? `hidden-icon-search` : ""}
      />
    ),
  });

  const getColumnDateProps = (
    dataIndex: any,
    type: "eq" | "contains" | "lte",
    filterType: "number" | "text" | "date",
    showTime?: boolean
  ): any => ({
    filterDropdown: () => (
      <Flex
        style={{
          padding: "8px",
        }}
        flexDirection={"column"}
        justifyContent="flex-end"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          showTime={showTime}
          onChange={(_, dateString) => {
            handleSearchDate({
              value: dateString,
              key: dataIndex,
              filterType,
            });
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
      </Flex>
    ),
    filterIcon: (filtered: boolean) => (
      <FilterOutlined
        style={{ color: filtered ? "#1677ff" : undefined }}
        className={handleSetShowIcon(dataIndex) ? `hidden-icon-search` : ""}
      />
    ),
  });

  const getColumnSelectProps = (dataIndex: any): any => ({
    filterDropdown: () => (
      <Flex
        style={{
          padding: "8px",
        }}
        flexDirection={"column"}
        justifyContent="flex-end"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <FilterStatus
          defaultChecked={defaultChecked}
          plainOptionsMap={plainOptionsMap}
          dataIndex={dataIndex}
          handleSearch={handleSearch}
        />
      </Flex>
    ),
    filterIcon: (filtered: boolean) => (
      <FilterOutlined
        style={{ color: filtered ? "#1677ff" : undefined }}
        className={handleSetShowIcon(dataIndex) ? `hidden-icon-search` : ""}
      />
    ),
  });

  const getColumnSelectProps1 = (
    dataIndex: any,
    defaultChecked: string,
    plainOptionsMap: {
      name: string;
      value: string | number;
      type: any;
    }[]
  ): any => ({
    filterDropdown: () => (
      <Flex
        style={{
          padding: "8px",
        }}
        flexDirection={"column"}
        justifyContent="flex-end"
        onKeyDown={(e) => e.stopPropagation()}
      >
        <FilterStatus
          defaultChecked={defaultChecked}
          plainOptionsMap={plainOptionsMap}
          dataIndex={dataIndex}
          handleSearch={handleSearch}
        />
      </Flex>
    ),
    filterIcon: (filtered: boolean) => (
      <FilterOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
  });

  const handleSetQuery = (query: string) => {
    if (searchedColumn && Object.keys(searchedColumn).length > 0) {
      for (const key in searchedColumn) {
        const type = searchedColumn[key].type;
        const filter = searchedColumn[key].filter;
        const filterType = searchedColumn[key].filterType;

        switch (filterType) {
          case "text" || "number":
            query += `&${key}[${type}]=${filter}`;
            break;
          case "mutil":
            const option1 = searchedColumn[key].option1;
            const option2 = searchedColumn[key].option2;
            query += `&${key}[${type}]=${option1}&${key}[${type}]=${option2}`;
            break;
          case "date":
            const startTime = searchedColumn[key].startDate;
            const endTime = searchedColumn[key].endDate;
            query += `&${key}[gte]=${startTime}&${key}[lte]=${endTime}`;
            break;
          default:
            break;
        }
      }
    }
    return query;
  };

  return {
    handleSearch,
    handleSetQuery,
    getColumnDateProps,
    getColumnSelectProps,
    getColumnSearchProps,
    getColumnSelectProps1,
  };
};
