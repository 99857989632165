import { useDispatch, useSelector } from "react-redux";
import { CODE_SUCCESS } from "util/Constant";
import { validResponse } from "util/API";
import { FormInstance, message } from "antd";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import FormBasicInformation from "./FormLoginAccountShool";
import { InputSchoolCreate } from "service/Auth";
import AuthService from "service/Auth/AuthService";
import { RootState } from "store";
import React, { useEffect, useMemo, useState } from "react";
import { handleThrowError } from "util/general";
import * as Sentry from "@sentry/browser";

const FormLoginAccountShool = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const { CreateSsa, UpdateSsa } = AuthService;
  const formRef = React.useRef<FormInstance>(null);
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const [isEdit, setIsEdit] = useState(false);
  const [schoolCreate, setSchoolCreate] = useState<
    InputSchoolCreate | undefined
  >(undefined);

  const onFinish = async (values: InputSchoolCreate) => {
    try {
      dispatch(setLoadingApp(true));
      const data = {
        ...values,
        roles: [values.roles],
        orgCode: schoolDetail.orgCode,
        idSchool: schoolDetail.orgCode,
        // schoolName: schoolDetail.schoolName,
        schoolCode: schoolDetail.schoolCode,
        status:
          values.status === "Hoạt động"
            ? 1
            : values.status === "Không hoạt động"
            ? 0
            : +values.status,
      };

      const orgCode = schoolDetail.orgCode;
      const schoolCode = schoolDetail.schoolCode;

      const { status, ...rest } = data;
      const { username, ...updatedata } = data;

      const res = !schoolCreate
        ? await CreateSsa(orgCode, schoolCode, rest)
        : await UpdateSsa(orgCode, schoolCode, schoolCreate._id, updatedata);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        onHandleGetDetailAccount();

        message.success(
          !schoolCreate
            ? "Tạo tài khoản thành công"
            : "Cập nhật tài khoản thành công"
        );
      } else {
        if (res && validResponse(res)) {
          handleThrowError(res.data.data?.errors ? res.data.data?.errors : res.data.data?.message);
          res.data.data?.errors ? Sentry.captureException(res.data.data?.errors) : Sentry.captureMessage(res.data.data?.message);
          // message.error(`${res.data.data?.message}`);
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const onHandleGetDetailAccount = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res: any = await AuthService.GetAllOsa(orgCode, ID);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        res.data.data?.records[0] && setSchoolCreate(res.data.data.records[0]);
      } else {
        message.error("Có lỗi xảy ra !");
        Sentry.captureMessage("Có lỗi xảy ra !")
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    schoolCreate &&
      formRef?.current?.setFieldsValue({
        username: schoolCreate.username,
        fullName: schoolCreate.fullName,
        email: schoolCreate.email,
        phone: schoolCreate.phone,
        address: schoolCreate.address,
        roles: schoolCreate.roles[0],
        status: schoolCreate.status ? "Hoạt động" : "Không hoạt động",
      });
  }, [schoolCreate]);

  useEffect(() => {
    !isEdit &&
      schoolCreate &&
      formRef?.current?.setFieldsValue({
        username: schoolCreate.username,
        fullName: schoolCreate.fullName,
        email: schoolCreate.email,
        phone: schoolCreate.phone,
        address: schoolCreate.address,
        roles: schoolCreate.roles[0],
        status: schoolCreate.status ? "Hoạt động" : "Không hoạt động",
      });
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [schoolCreate]);

  useEffect(() => {
    onHandleGetDetailAccount();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <FormBasicInformation
        onFinish={onFinish}
        formRef={formRef}
        schoolCreate={schoolCreate}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
      />
    </div>
  );
};

export default FormLoginAccountShool;
