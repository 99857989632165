import { Col, Form, Input, Row } from "antd";
import MainButton from "components/button";
import React from "react";
import { SearchIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";

interface P {
  handleSearchInfor: () => Promise<void>;
  setStudentCode: React.Dispatch<React.SetStateAction<string>>;
}

const Search = ({ setStudentCode, handleSearchInfor }: P) => {
  return (
    <Form name="basic1" autoComplete="off" labelCol={{ span: 6 }}>
      <Box width="600px" mt="4">
        <Row gutter={24}>
          <Col span={20}>
            <Form.Item
              name="studentCode"
              label="Mã sinh viên"
              labelAlign="left"
            >
              <Input
                className="custom-input "
                placeholder="Mã sinh viên"
                onPressEnter={handleSearchInfor}
                onChange={(e) => setStudentCode(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <MainButton
              text=""
              onClick={handleSearchInfor}
              icon={
                <>
                  <SearchIcon
                    color={"#fff"}
                    borderColor="#fff"
                    fontSize="18px"
                    className="icon-search"
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Box>
      <HSeparator />
    </Form>
  );
};

export default Search;
