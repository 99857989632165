import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { globalStyles } from "theme/styles";
import FormStudent from "./FormStudent";

const DetailStudent = () => {
  const onFinish = async () => {};

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Text
        color={globalStyles.colors.text[202027]}
        fontSize="15px"
        fontWeight={500}
        my="3"
      >
        Thông tin sinh viên
      </Text>
      <FormStudent onFinish={onFinish} />
    </Box>
  );
};

export default DetailStudent;
