import { Box, Text } from "@chakra-ui/react";
import { InputRef, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import HistoryReconciliation from "./Components/HistoryReconciliation";
import ModalReconciliationFailed from "./Components/ModalReconciliationFailed";
import ModalReconciliationProgress from "./Components/ModalReconciliationProgress";
import ModalReconciliationSuccess from "./Components/ModalReconciliationSuccess";
import Reconciliation from "./Components/Reconciliation";
import * as Sentry from "@sentry/browser";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { IReconcileHistory } from "types/general";
import { handleThrowError } from "util/general";
import { RootState } from "store";
import {
  IPartners,
  IReconcileCompare,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";

const ReconciliationManagement = () => {
  const dispatch = useDispatch();
  const { GetSchoolPartner } = OrganizationAdminService;
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [pageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listTgtt, setListTgtt] = useState<IPartners[]>([]);
  const [isModalReconciliationSuccess, setIsModalReconciliationSuccess] =
    useState(false);
  const [isModalReconciliationFailed, setIsModalReconciliationFailed] =
    useState(false);
  const [isModalReconciliationProgress, setIsModalReconciliationProgress] =
    useState(false);
  const [date, setDate] = useState<number | undefined>(undefined);

  const [isReconciliation, setIsReconciliation] = useState(false); // check nhan button upload
  const [reconciliation, setReconciliation] = useState<
    IReconcileCompare | undefined
  >(undefined);
  const [listReconciliation, setListReconciliation] = useState<
    IReconcileHistory[]
  >([]); // danh sach lich su doi soat
  const [loading, setLoading] = useState(false);
  const [totalRow, setTotalRow] = useState(0);

  const searchInput = useRef<InputRef>(null);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);

  const { handleSetQuery, getColumnDateProps, getColumnSearchProps } =
    FilterAntdHook({
      searchInput,
      searchedColumn,
      setCurrentPage,
      setSearchedColumn,
    });

  const GetListTgtt = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetSchoolPartner(
        authInfor?.orgCode,
        authInfor?.schoolCode
      );
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setListTgtt(
          res.data.data.partners.map((e) => ({
            ...e,
          }))
        );
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureException("Có lỗi xảy ra");
      }
    } catch (error) {
      console.log(error);
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const handleGetReconcileHistory = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&implementTime[sort]=desc`;
      const URL = `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/reconcile/history`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setListReconciliation(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        res &&
          validResponse(res) &&
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
        setTotalRow(0);
        setListReconciliation([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      handleGetReconcileHistory();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      handleGetReconcileHistory();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    authInfor?.orgCode && GetListTgtt(); // eslint-disable-next-line
  }, [authInfor]);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Reconciliation
        listTgtt={listTgtt}
        isReconciliation={isReconciliation}
        setDate={setDate}
        setReconciliation={setReconciliation}
        setIsReconciliation={setIsReconciliation}
        handleGetReconcileHistory={handleGetReconcileHistory}
        setIsModalReconciliationFailed={setIsModalReconciliationFailed}
        setIsModalReconciliationSuccess={setIsModalReconciliationSuccess}
        setIsModalReconciliationProgress={setIsModalReconciliationProgress}
      />

      <HistoryReconciliation
        loading={loading}
        pageSize={pageSize}
        totalRow={totalRow}
        currentPage={currentPage}
        dataSource={listReconciliation}
        getColumnDateProps={getColumnDateProps}
        onChangeCurrentPage={onChangeCurrentPage}
        getColumnSearchProps={getColumnSearchProps}
      />
      {/* modal */}
      <ModalReconciliationSuccess
        date={date}
        reconciliation={reconciliation}
        isOpen={isModalReconciliationSuccess}
        onClose={() => setIsModalReconciliationSuccess(false)}
      />
      <ModalReconciliationFailed
        onClose={() => setIsModalReconciliationFailed(false)}
        isOpen={isModalReconciliationFailed}
      />
      <ModalReconciliationProgress
        onClose={() => setIsModalReconciliationProgress(false)}
        isOpen={isModalReconciliationProgress}
      />
      {/* end modal */}
    </Box>
  );
};

export default ReconciliationManagement;
