import React from "react";
import UseDownloadFIleHook from "./UseDownloadFIleHook";

interface P {
  text: string;
  data: any;
}

const FileReconciliation = ({ text, data }: P) => {
  if (!data) {
    return <></>;
  }

  const { implementTime: date, fileId: id, fileName } = data;

  const { handleDownloadExcel } = UseDownloadFIleHook({ date, id, fileName });

  return (
    <div
      onClick={() => handleDownloadExcel()}
      style={{
        cursor: "pointer",
        color: "#045993",
      }}
    >
      {text}
    </div>
  );
};

export default FileReconciliation;
