import { AuthInfor } from "service/Auth/Auth.d";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { EnumPermission } from "util/Constant";
import {
  OrganizationDetail,
  SchoolDetail,
} from "service/OrganizationAdmin/Organization";

export interface Type_Initstate {
  appLoading: boolean;
  authInfor: AuthInfor | undefined;
  appPermission: EnumPermission | undefined;
  activeRoute: string;
  detailOrganization: OrganizationDetail | undefined;
  breadcrumb: { name: string; link: string }[];
  brandText: { name: string; link: string } | undefined;
  schoolDetail: SchoolDetail | undefined;
}

const initialState: Type_Initstate = {
  appLoading: false,
  authInfor: undefined,
  appPermission: undefined,
  detailOrganization: undefined,
  activeRoute: "",
  breadcrumb: [],
  brandText: undefined,
  schoolDetail: undefined,
};

const appSlice = createSlice({
  name: "sample",
  initialState,
  reducers: {
    setLoadingApp: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setBrandText: (
      state,
      action: PayloadAction<{ name: string; link: string } | undefined>
    ) => {
      state.brandText = action.payload;
    },
    setAuthInfor: (state, action: PayloadAction<AuthInfor>) => {
      state.authInfor = action.payload;
    },
    setActiveRoute: (state, action: PayloadAction<string>) => {
      state.activeRoute = action.payload;
    },
    setDetailOrganization: (
      state,
      action: PayloadAction<OrganizationDetail>
    ) => {
      state.detailOrganization = action.payload;
    },
    setBreadcrumb: (
      state,
      action: PayloadAction<{ name: string; link: string }[]>
    ) => {
      state.breadcrumb = action.payload;
    },
    setAppPermisson: (
      state,
      action: PayloadAction<EnumPermission | undefined>
    ) => {
      state.appPermission = action.payload;
    },
    setSchoolDetail: (
      state,
      action: PayloadAction<SchoolDetail | undefined>
    ) => {
      state.schoolDetail = action.payload;
    },
  },
});

export const {
  setLoadingApp,
  setAuthInfor,
  setAppPermisson,
  setActiveRoute,
  setBreadcrumb,
  setDetailOrganization,
  setBrandText,
  setSchoolDetail,
} = appSlice.actions;

export default appSlice.reducer;
