import { Box } from "@chakra-ui/react";
import React from "react";

const IconLogo = () => {
  return (
    <Box
      borderRadius="8px"
      bg="#F5F5F5"
      p="15px"
      display="flex"
      justifyContent="center"
    >
      <svg
        width="209"
        height="218"
        viewBox="0 0 209 218"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M104.566 176.663C141.599 176.663 172.22 144.876 172.22 106.434C172.22 67.924 141.533 36.1378 104.5 36.1378C70.2155 36.1378 41.3612 63.4413 37.37 98.2838C40.3143 97.6046 43.3241 97.265 46.4647 97.265C66.6823 97.265 83.7594 112.004 88.1431 131.157C93.1158 129.934 98.5464 129.187 104.5 129.187C123.213 129.187 137.608 136.319 144.478 144.401C134.794 155.608 120.204 162.739 104.5 162.739C98.3501 162.739 91.6109 161.449 85.8531 159.071C84.7408 162.739 82.0582 167.29 78.6559 171.161C86.7037 174.693 95.4058 176.663 104.566 176.663ZM104.5 117.437C91.8072 117.301 82.0582 106.298 81.9928 91.7636C81.8619 78.0439 91.8726 66.5656 104.5 66.5656C117.063 66.5656 127.008 78.0439 127.008 91.7636C127.008 106.298 117.194 117.573 104.5 117.437ZM46.4647 176.934C64.7849 176.934 80.1608 161.041 80.1608 141.888C80.1608 122.735 64.9812 106.91 46.4647 106.91C28.0136 106.91 12.7686 122.735 12.7686 141.888C12.7686 161.109 28.0136 176.934 46.4647 176.934ZM45.6795 150.378C43.1278 150.378 41.4266 148.952 41.4266 146.371V146.099C41.4266 142.228 43.5858 140.054 46.5301 137.881C49.9978 135.436 52.0262 134.009 52.0262 131.157C52.0262 128.644 50.0633 126.946 47.0535 126.946C44.4363 126.946 42.8006 128.168 41.3612 129.662C40.118 130.953 39.1366 132.243 37.0428 132.243C34.4911 132.243 32.9862 130.545 32.9862 128.304C32.9862 122.735 40.0526 118.863 47.2498 118.863C55.6902 118.863 61.3825 123.821 61.3825 130.681C61.3825 136.251 57.9802 138.356 54.2507 141.209C51.7644 143.043 50.4558 144.333 50.0633 146.846C49.6707 149.155 48.1658 150.378 45.6795 150.378ZM45.6141 164.437C42.6043 164.437 40.1834 162.128 40.1834 159.004C40.1834 155.947 42.6043 153.638 45.6141 153.638C48.7547 153.638 51.0447 155.947 51.0447 159.004C51.0447 162.128 48.6893 164.437 45.6141 164.437Z"
          fill="#A8A8A8"
        />
      </svg>
    </Box>
  );
};

export default IconLogo;
