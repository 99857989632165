import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FormInstance, InputRef } from "antd";
import Table from "components/table";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS, OSA } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import * as Sentry from "@sentry/browser";
import AddNewBranch from "./Components/AddNewBranch";

const BranchManagement = () => {
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [isVisibleModalAdd, setIsVisibleModalAdd] = useState(false);
  const [branchList, setBranchList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const searchInput = useRef<InputRef>(null);

  const handleNavigate = (id: string) => {
    history.push(`${OSA}/branch-detail/${id}`);
  };

  const { handleSetQuery, getColumnSearchProps, getColumnSelectProps } =
    FilterAntdHook({
      searchInput,
      searchedColumn,
      setCurrentPage,
      setSearchedColumn,
    });

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      const URL = `/organization/${authInfor?.orgCode}/branch`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setBranchList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setBranchList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  const columnDefs = [
    {
      title: "STT",
      key: "stt",
      dataIndex: "stt",
      width: "70px",
    },
    {
      title: "Tên chi nhánh",
      dataIndex: "branchName",
      key: "branchName",
      ...getColumnSearchProps("branchName", "contains", "text"),
      render: (value: any, record: any) => (
        <span
          onClick={() => handleNavigate(record._id)}
          style={{
            cursor: "pointer",
            color: "#045993",
          }}
        >
          {value}
        </span>
      ),
      width: "30%",
    },
    {
      title: "Mã chi nhánh",
      dataIndex: "branchCode",
      key: "branchCode",
      ...getColumnSearchProps("branchCode", "contains", "text"),
    },
  ];

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text style={{ color: "#202027", fontWeight: "bold" }}>
          {"Danh sách chi nhánh"}
        </Text>
        <Button
          colorScheme="whatsapp"
          backgroundColor={"#5FAD67"}
          borderRadius="6px"
          onClick={() => setIsVisibleModalAdd(true)}
        >
          Thêm mới
        </Button>
      </Flex>
      <Table
        columns={columnDefs}
        dataSource={branchList}
        text={"chi nhánh"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width="75vw"
      />
      <AddNewBranch
        isOpen={isVisibleModalAdd}
        onClose={() => setIsVisibleModalAdd(false)}
        formRef={formRef}
      />
    </Box>
  );
};

export default BranchManagement;
