import { Box } from "@chakra-ui/react";
import { Input } from "antd";
import React from "react";
import { IidSHB } from "service/OrganizationAdmin/Organization";

interface P {
  item: IidSHB;
  index: number;
  onChangeValue: (index: number, key: string, values: string) => void;
}

const RenderRowInput = ({ item, index, onChangeValue }: P) => {
  return (
    <>
    {/* Tên tài khoản */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập tên tài khoản"
            onChange={(e) => onChangeValue(index, "TIDName", e.target.value)}
            defaultValue={item.TIDName}
          />
        </Box>
      </td>
      {/* ClientID */}
      <td>
        <Box p="3" pl="0">
          <Input
          className="custom-input min-width-200"
            placeholder="Nhập client ID"
            onChange={(e) => onChangeValue(index, "clientId", e.target.value)}
            defaultValue={item.clientId}
          />
        </Box>
      </td>
      {/* username */}
      <td>
        <Box p="3" pl="0">
          <Input
          className="custom-input min-width-200"
            placeholder="Nhập username"
            onChange={(e) => onChangeValue(index, "username", e.target.value)}
            defaultValue={item.username}
          />
        </Box>
      </td>
      {/* password */}
      <td>
        <Box p="3" pl="0">
          <Input
          className="custom-input min-width-200"
            placeholder="Nhập password"
            onChange={(e) => onChangeValue(index, "password", e.target.value)}
            defaultValue={item.password}
          />
        </Box>
      </td>
      {/* ip */}
      <td>
        <Box p="3" pl="0">
          <Input
          className="custom-input min-width-200"
            placeholder="Nhập IP"
            onChange={(e) => onChangeValue(index, "ip", e.target.value)}
            defaultValue={item.ip}
          />
        </Box>
      </td>
      {/* ClientSecret */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập client secret"
            onChange={(e) =>
              onChangeValue(index, "clientSecret", e.target.value)
            }
            defaultValue={item.clientSecret}
          />
        </Box>
      </td>
      {/* taxNO */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập tax NO"
            onChange={(e) =>
              onChangeValue(index, "taxNo", e.target.value)
            }
            defaultValue={item.taxNo}
          />
        </Box>
      </td>
      {/* accountNo */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập account NO"
            onChange={(e) => onChangeValue(index, "accountNo", e.target.value)}
            defaultValue={item.accountNo}
          />
        </Box>
      </td>
      {/* SaleTypeCD */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập sale type cd"
            onChange={(e) => onChangeValue(index, "saleTypeCd", e.target.value)}
            defaultValue={item.saleTypeCd}
          />
        </Box>
      </td>
      {/* Tiền tố */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập tiền tố"
            onChange={(e) => onChangeValue(index, "prefixAccount", e.target.value)}
            defaultValue={item.prefixAccount}
          />
        </Box>
      </td>
      {/* Consumer Key */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập consumer key"
            onChange={(e) => onChangeValue(index, "consumerKey", e.target.value)}
            defaultValue={item.consumerKey}
          />
        </Box>
      </td>
      {/* Consumer Secret */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input min-width-200"
            placeholder="Nhập consumer secret"
            onChange={(e) => onChangeValue(index, "consumerSecret", e.target.value)}
            defaultValue={item.consumerSecret}
          />
        </Box>
      </td>
    </>
  );
};

export default RenderRowInput;
