import { InputRef } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { IPlainOptionsMap, IRefundHistory } from "types/general";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import View from "./View";
import * as Sentry from "@sentry/browser";

const RefundHistory = () => {
  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Thành công", value: 1, type: "eq" },
    { name: "Thất bại", value: 0, type: "eq" },
  ];

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const defaultChecked = "";
  const searchInput = useRef<InputRef>(null);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [refundHistorys, setRefundHistorys] = useState<IRefundHistory[]>([]);

  const {
    getColumnDateProps,
    getColumnSearchProps,
    getColumnSelectProps,
    handleSetQuery,
  } = FilterAntdHook({
    searchedColumn,
    setSearchedColumn,
    setCurrentPage,
    searchInput,
    plainOptionsMap,
    defaultChecked,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;

      if (!handleSetQuery(query).includes("status")) {
        query += "&status[or]=1&status[or]=0";
      }

      const BILL = `/organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/refund-history`;

      const res = await NetWorkService.Get({
        url: BILL + handleSetQuery(query),
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const respons = res.data;
        setRefundHistorys(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setRefundHistorys([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <View
      billList={refundHistorys}
      currentPage={currentPage}
      getColumnDateProps={getColumnDateProps}
      getColumnSearchProps={getColumnSearchProps}
      getColumnSelectProps={getColumnSelectProps}
      loading={loading}
      onChangeCurrentPage={onChangeCurrentPage}
      pageSize={pageSize}
      totalRow={totalRow}
    />
  );
};

export default RefundHistory;
