import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { globalStyles } from "theme/styles";
import TabsPanel from "./Components/TabsPanel";
import { useDispatch, useSelector } from "react-redux";
import { setBrandText, setBreadcrumb, Type_Initstate } from "store/appSlice";
import { ADMIN } from "util/Constant";
import { RootState } from "store";
import FormBasicInformation from "../CSDTDetail/Components/FormBasicInformation";
import { handleGetDetail } from "util/general";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";

const BasicInformation = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);

  const { GetDetailShool } = OrganizationAdminService;

  useEffect(() => {
    handleGetDetail(dispatch, GetDetailShool, orgCode, ID);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (schoolDetail) {
      const list = [
        {
          name: schoolDetail.orgName,
          link: `${ADMIN}/organization-detail/${schoolDetail.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${schoolDetail.orgCode}`,
        },
        {
          name: schoolDetail.schoolName,
          link: `${ADMIN}/${schoolDetail.orgCode}/add-school-information/${schoolDetail.schoolCode}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );
      dispatch(setBreadcrumb(list));
    }
  }, [dispatch, schoolDetail]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={1} orgCode={orgCode} ID={ID} />
      <Box>
        <Text
          my="3"
          fontWeight={300}
          fontSize="13px"
          fontStyle={"italic"}
          color="#000"
          opacity={"0.5"}
        >
          Đây là thông tin chung của cơ sở đào tạo sử dụng cổng thanh toán AQ
          Bill Gateway. Các trường đánh dấu sao là các trường bắt buộc nhập
        </Text>
        <FormBasicInformation />
      </Box>
    </Box>
  );
};

export default BasicInformation;
