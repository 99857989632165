import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import MainButton from "components/button";
import { Button, Form, FormInstance, Input } from "antd";

interface IProps {
  onFinish: (values: any) => void;
  onClose: () => void;
  formRef: React.MutableRefObject<FormInstance<any>>;
}

const FormAddNewOrganization = ({ onFinish, onClose, formRef }: IProps) => {
  const onReset = () => {
    formRef?.current?.setFieldsValue({
      organizationCode: "",
      organizationName: "",
    });
  };

  const validateSpace = (_: any, value: any) => {
    if (/\s/.test(value)) {
      return Promise.reject(new Error("Mã cơ sở không được chứa khoảng trắng"));
    }
    return Promise.resolve();
  };

  return (
    <Form
      name="FormAddNewOrganization"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      ref={formRef}
    >
      <Box>
        <Form.Item
          labelAlign="left"
          label="Tên tổ chức"
          name="orgName"
          rules={[{ required: true, message: "Vui lòng nhập tên tổ chức!" }]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã tổ chức"
          name="orgCode"
          rules={[
            { required: true, message: "Vui lòng nhập mã tổ chức!" },
            { validator: validateSpace },
          ]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
      </Box>
      <Flex flexDirection={"row"} alignContent="center" justifyContent="center">
        <Button
          className="custom-button button-dash"
          onClick={() => {
            onClose();
            onReset();
          }}
          style={{
            width: "95px",
          }}
        >
          Hủy
        </Button>
        <Box ml="2">
          <MainButton htmlType="submit" text="Xác nhận" />
        </Box>
      </Flex>
    </Form>
  );
};

export default FormAddNewOrganization;
