import { NetWorkService } from "util/API";
import {
  AuthAdminLoginResponsive,
  AuthCsdtResponsive,
  AuthInputDataType,
  InputOrganizationCreate,
  InputSchoolCreate,
} from "./Auth";

const LOGIN_ADMIN = "/auth/admin/login";
const LOGIN_SCHOOL = "/auth/school/login";
const LOGIN_ORGANIZATION = "/auth/organization/login";
const ADMIN_DETAIL = "/auth/admin/detail";
const ORGANIZATION_DETAIL = "/auth/organization/detail";
const ORGANIZATION_CREATE = "/auth/organization/create";
const AUTH_SCHOOL_CREATE = "/auth/school/create";
const SCHOOL_DETAIL = "/auth/school/detail";
const ADMIN_CREATE = "/auth/admin/create";
const ADMIN_UPDATE = "/auth/admin/update";

const CREATE_OSA = "/auth/organization";

const { Post, Get } = NetWorkService;

export default class AuthService {
  static LoginAdmin = async (data: AuthInputDataType) => {
    return await Post<AuthAdminLoginResponsive>({
      url: LOGIN_ADMIN,
      body: { ...data },
    });
  };
  static LoginOrganization = async (data: AuthInputDataType) => {
    return await Post<AuthAdminLoginResponsive>({
      url: LOGIN_ORGANIZATION,
      body: { ...data },
    });
  };
  static LoginSchool = async (data: AuthInputDataType) => {
    return await Post<AuthAdminLoginResponsive>({
      url: LOGIN_SCHOOL,
      body: { ...data },
    });
  };
  static CreateOrganization = async (data: InputOrganizationCreate) => {
    return await Post<AuthAdminLoginResponsive>({
      url: ORGANIZATION_CREATE,
      body: { ...data },
    });
  };
  static CreateAuthSchool = async (data: InputSchoolCreate) => {
    return await Post<AuthAdminLoginResponsive>({
      url: AUTH_SCHOOL_CREATE,
      body: { ...data },
    });
  };
  static GetAdminDetail = async () => {
    return await Get<AuthAdminLoginResponsive>({ url: ADMIN_DETAIL });
  };
  static GetOrganizationDetail = async () => {
    return await Get<AuthAdminLoginResponsive>({
      url: ORGANIZATION_DETAIL,
    });
  };
  static GetOrganizationDetail1 = async (query?: string) => {
    return await Get<any>({
      url: "/auth/organization/detail?username[eq]=7747",
    });
  };
  static GetOrganizationDetail12 = async (query?: string) => {
    return await Get<any>({
      url: "/admin/school?username[eq]=CSDT1",
    });
  };
  static GetSchoolDetail = async () => {
    return await Get<AuthCsdtResponsive>({ url: SCHOOL_DETAIL });
  };
  // Tạo tài khoản tổ chức
  static AuthCreateOrganization = async (orgCode: string, data: any) => {
    return await Post<any>({
      url: `${CREATE_OSA}/${orgCode}/create`,
      body: { ...data },
    });
  };

  static AuthGetCreateOrganization = async (query: string) => {
    return await Get<any>({
      url: `/admin/organization?${query}`,
      // body: { ...data },
    });
  };

  // Lấy danh sách tất cả các tài khoản cơ sở đào tạo thuoc tổ chức
  static GetAllOsa = async (orgCode: string, schoolCode: string) => {
    return await Get<any>({
      url: `/admin/organization/${orgCode}/school/${schoolCode}`,
    });
  };
  // Chỉnh sửa tài khoản tổ chức
  static UpdateOsa = async (orgCode: string, id: string, data: any) => {
    return await Post<any>({
      url: `/auth/organization/${orgCode}/${id}/update`,
      body: { ...data },
    });
  };
  // Tạo tài khoản cơ sở đào tạo
  static CreateSsa = async (orgCode: string, schoolCode: string, data: any) => {
    return await Post<any>({
      url: `/auth/organization/${orgCode}/school/${schoolCode}/create`,
      body: { ...data },
    });
  };
  // CHỉnh sửa tài khoản cơ sở đào tạo
  static UpdateSsa = async (
    orgCode: string,
    schoolCode: string,
    id: string,
    data: any
  ) => {
    return await Post<any>({
      url: `/auth/organization/${orgCode}/school/${schoolCode}/${id}/update`,
      body: { ...data },
    });
  };
  // Tạo mới admin
  static CreateAdmin = async (data: any) => {
    return await Post<any>({
      url: ADMIN_CREATE,
      body: { ...data },
    });
  };
  // Lấy chi tiết tài khoản tổ chức thuộc tổ chức của mình
  static AuthGetOrganization = async (query: string, orgCode: string) => {
    return await Get<any>({
      url: `/admin/organization/${orgCode}?${query}`,
      // body: { ...data },
    });
  };
  // cap nhat Admin
  static AuthAdminUpdate = async (data: any) => {
    return await Post<any>({
      url: ADMIN_UPDATE,
      body: { ...data },
    });
  };
  // cap nhat Admin
  static DetailCSDT = async (orgCode: string, schoolCode: string) => {
    return await Get<any>({
      url: `organization/${orgCode}/school/${schoolCode}/edubill/detail`,
    });
  };
}
