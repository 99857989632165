import { Text } from "@chakra-ui/react";
import { ColumnsType } from "antd/es/table";
import Table from "components/table";
import moment from "moment";
import React from "react";
import { IReconcileHistory } from "types/general";
import FileReconciliation from "./FileReconciliation";

interface P {
  loading: boolean;
  pageSize: number;
  totalRow: number;
  currentPage: number;
  dataSource: Array<IReconcileHistory>;
  onChangeCurrentPage: (e: any) => void;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains" | "lte",
    filterType: "number" | "text" | "date",
    showTime?: boolean
  ) => any;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
}

const HistoryReconciliation = ({
  loading,
  totalRow,
  pageSize,
  dataSource,
  currentPage,
  getColumnDateProps,
  getColumnSearchProps,
  onChangeCurrentPage,
}: P) => {
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "60px",
    },
    {
      title: "File đối soát",
      dataIndex: "fileName",
      key: "fileName",
      ...getColumnSearchProps("fileName", "contains", "text"),
      render: (value, _) => (
        <FileReconciliation
          text={value}
          data={_}
          // đata={đata}
        />
      ),
    },
    {
      title: "Ngày thực hiện",
      dataIndex: "implementTime",
      key: "implementTime",
      ...getColumnDateProps("implementTime", "contains", "date"),
      render: (value) => (
        <div>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</div>
      ),
    },
    {
      title: "Người thực hiện",
      dataIndex: "implementer",
      key: "implementer",
      ...getColumnSearchProps("count", "contains", "text"),
      render: (value) => (
        <div
        // style={{
        //   color: "#045993",
        // }}
        >
          {value ? value : ""}
        </div>
      ),
    },
    {
      title: "Ngày đối soát",
      dataIndex: "date",
      key: "date",
      ...getColumnDateProps("date", "contains", "date"),
      render: (value) => (
        <div>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</div>
      ),
    },
    {
      title: "Đối tác thanh toán",
      dataIndex: "paymentPartner",
      key: "paymentPartner",
      ...getColumnSearchProps("paymentPartner", "contains", "text"),
      render: (value) => (
        <div
        // style={{
        //   color: "#045993",
        // }}
        >
          {value ? value : ""}
        </div>
      ),
    },
  ];

  return (
    <>
      <Text color="#202027" fontSize="15px" fontWeight={500} mb="4">
        Lịch sử đối soát
      </Text>
      <Table
        width="80vw"
        loading={loading}
        columns={columns}
        pageSize={pageSize}
        totalRow={totalRow}
        dataSource={dataSource}
        currentPage={currentPage}
        onChangeCurrentPage={onChangeCurrentPage}
      />
    </>
  );
};

export default HistoryReconciliation;
