import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";
import { ResponseBase } from "../types/general";
import {
  AUTH,
  CODE_SUCCESS,
  CODE_TIME_OUT,
  ERROR_CODE,
  ERROR_NETWORK_CODE,
  RESULT_CODE_PUSH_OUT,
  STATUS_TIME_OUT,
  TIME_OUT,
  TOKEN,
} from "./Constant";
import { deleteCookie, readValueCookie } from "./general";

export const API_URL = process.env.REACT_APP_BASE_API;
export const API_GATEWAY_RESOURCE =
  process.env.REACT_APP_BASE_API_GATEWAY_RESOURCE;
export const API_GATEWAY_PAYMENT = process.env.REACT_APP_BASE_API_PAYMENT;
const tokenKeyHeader = "authorization";

export interface ParamsNetwork extends AxiosRequestConfig {
  url: string;
  params?: Record<string, string | number>;
  path?: Record<string, string | number>;
  body?: Record<string, any>;
  controller?: AbortController;
}

const responseDefault: ResponseBase<Record<string, unknown>> = {
  code: -500,
  status: false,
  msg: ERROR_CODE.have_error,
};

export const handleResponseAxios = <T = Record<string, unknown>>(
  res: AxiosResponse<T>
): ResponseBase<T> => {
  if (res.data) {
    return {
      code: CODE_SUCCESS,
      status: true,
      data: res.data,
      headers: res.headers,
    };
  }
  return responseDefault as ResponseBase<T>;
};

export const handleErrorApi = (status: number | string) => {
  const result = { status: false, code: status, msg: "" };
  if (status >= 500) {
    result.msg = ERROR_CODE.server_error;
    return result;
  }
  if (status < 500 && status >= 418) {
    result.msg = ERROR_CODE.error_on_request;
    return result;
  }
  result.msg = ERROR_CODE[status];
  return result;
};

export const handleErrorAxios = <T = Record<string, unknown>>(
  error: AxiosError
): ResponseBase<T> => {
  if (error.code === STATUS_TIME_OUT) {
    // timeout
    return handleErrorApi(CODE_TIME_OUT) as unknown as ResponseBase<T>;
  }
  if (error.response) {
    if (error.response.status === RESULT_CODE_PUSH_OUT) {
      return handleErrorApi(RESULT_CODE_PUSH_OUT) as unknown as ResponseBase<T>;
    } else {
      return handleErrorApi(error.response.status) as any as ResponseBase<T>;
    }
  }
  return handleErrorApi(ERROR_NETWORK_CODE) as any as ResponseBase<T>;
};

export const handlePath = (url: string, path: ParamsNetwork["path"]) => {
  if (!path || Object.keys(path).length <= 0) {
    return url;
  }
  let resUrl = url;
  Object.keys(path).forEach((k) => {
    resUrl = resUrl.replaceAll(`{${k}}`, String(path[k]));
    resUrl = resUrl.replaceAll(`:${k}`, String(path[k]));
  });
  return resUrl;
};

export const handleParameter = <T extends ParamsNetwork>(
  props: T,
  method: Method
): ParamsNetwork => {
  const { url, body, path, params } = props;
  return {
    ...props,
    method,
    url: handlePath(url, path),
    data: body,
    params,
  };
};

export const validResponse = (
  response: ResponseBase<any>
): response is ResponseBase<any, true> => {
  if (!response.status) {
    // TODO: handle error
    return false;
  }
  return true;
};

// base
function Request<T = Record<string, unknown>>(
  config: ParamsNetwork,
  url?: string
) {
  const token = readValueCookie(TOKEN);

  const defaultConfig: AxiosRequestConfig = {
    baseURL: `${url ? url : API_URL}`,
    timeout: TIME_OUT,
    headers: {
      "Content-Type": "application/json",
      [tokenKeyHeader]: `Bearer ${token}` ?? "",
    },
  };

  const AxiosInstance = Axios.create(defaultConfig);

  return new Promise<ResponseBase<T> | null>((rs) => {
    AxiosInstance.request(config)
      .then((res: AxiosResponse<T>) => {
        const result = handleResponseAxios(res);
        rs(result);
      })
      .catch((error: AxiosError<T>) => {
        if (error.code === AxiosError.ERR_CANCELED) {
          rs(null);
        }
        const result = handleErrorAxios(error);

        // nếu bị lỗi unauthorization mặc định về login
        if (result.code === RESULT_CODE_PUSH_OUT) {
          window.location = `/#${AUTH}/login` as any;
          deleteCookie(TOKEN);
          rs(null);
        } else {
          rs(result as ResponseBase<T>);
        }
      });
  });
}

// get
async function Get<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "GET"));
}

// get
async function Get_URL_RESOURCE<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "GET"), API_GATEWAY_RESOURCE);
}

// get
async function POST_URL_PAYMENT<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "POST"), API_GATEWAY_PAYMENT);
}

// post
async function Post<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "POST"));
}
// PUT
async function Put<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "PUT"));
}

// PATCH
async function PATCH<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "PATCH"));
}

// DELETE
async function DELETE<T>(params: ParamsNetwork) {
  return Request<T>(handleParameter(params, "DELETE"));
}

export const NetWorkService = {
  Get,
  Put,
  Post,
  PATCH,
  DELETE,
  Request,
  Get_URL_RESOURCE,
  POST_URL_PAYMENT,
};
