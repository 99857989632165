import { Box, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";
import { Button, Form, FormInstance, Input, Select } from "antd";
import { HSeparator } from "components/separator/Separator";
import React from "react";
const { Option } = Select;

const HeaderSearch = () => {
  const formRef = React.useRef<FormInstance>(null);

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  return (
    <Box width={"100%"}>
      <Form onFinish={onFinish} ref={formRef} style={{ maxWidth: "100%" }}>
        <Grid templateColumns="repeat(10, 1fr)" gap={6}>
          <GridItem colSpan={9}>
            <SimpleGrid columns={3} spacing={3}>
              <Box mr="2">
                <Form.Item name="username1" className="wrap-input">
                  <Input placeholder="Tên nhân sự" className="custom-input" />
                </Form.Item>
              </Box>
              <Box mr="2">
                <Form.Item name="username2">
                  <Input placeholder="Mã nhân sự" className="custom-input" />
                </Form.Item>
              </Box>
              <Box mr="2">
                <Form.Item name="username4">
                  <Input placeholder="Username" className="custom-input" />
                </Form.Item>
              </Box>
              <Box mr="2">
                <Form.Item name="username6">
                  <Input placeholder="Số điện thoại" className="custom-input" />
                </Form.Item>
              </Box>
              <Box mr="2">
                <Form.Item name="username5">
                  <Input placeholder="Email" className="custom-input" />
                </Form.Item>
              </Box>
              <Form.Item name="status">
                <Select placeholder="Quyền" className="w-full ">
                  <Option value="male">male</Option>
                  <Option value="female">female</Option>
                  <Option value="other">other</Option>
                </Select>
              </Form.Item>
            </SimpleGrid>
          </GridItem>
          <GridItem colSpan={1} display="flex" justifyContent="flex-end">
            <Box>
              <Form.Item>
                <Button htmlType="submit" className="btn-F3C262 custom-button">
                  Sửa
                </Button>
              </Form.Item>
            </Box>
          </GridItem>
        </Grid>
      </Form>
      <HSeparator my={4} />
    </Box>
  );
};

export default HeaderSearch;
