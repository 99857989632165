import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { globalStyles } from "theme/styles";
import Brand from "components/sidebar/components/Brand";
import FormLogin from "./Components/FormLogin";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { AuthInputDataType } from "service/Auth";
import { validResponse } from "util/API";
import {
  CODE_SUCCESS,
  EnumPermission,
  DEFAULT_ROUTER,
  TOKEN,
} from "util/Constant";
import { useDispatch } from "react-redux";
import { setAppPermisson, setAuthInfor, setLoadingApp } from "store/appSlice";
import { saveValueInCookie } from "util/general";
import AuthService from "service/Auth/AuthService";
import * as Sentry from "@sentry/browser";
import UseGetAvatarUrlHook from "util/hook/UseGetAvatarUrlHook";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { LoginAdmin, LoginOrganization, LoginSchool } = AuthService;
  const [active, setActive] = useState(1);

  const { onHandleGetAvatarUrl } = UseGetAvatarUrlHook();

  const onFinish = async (values: AuthInputDataType) => {
    try {
      dispatch(setLoadingApp(true));
      const res =
        active === 1
          ? await LoginAdmin(values)
          : active === 2
          ? await LoginSchool(values)
          : await LoginOrganization(values);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        dispatch(setAuthInfor(res.data.data.data));
        dispatch(setAppPermisson(res.data.data.data.accountType));
        saveValueInCookie(TOKEN, res.data.data.access_token);

        res.data.data.data.accountType === EnumPermission.CSDT &&
          onHandleGetAvatarUrl(res.data.data.data);

        history.push(
          "/" +
            res.data.data.data.accountType.toLocaleLowerCase() +
            DEFAULT_ROUTER
        );
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data.message}`);
          Sentry.captureMessage(res.data.data.message);
        } else {
          message.error(`Có lỗi xảy ra`);
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh)"
      background={globalStyles.colors.bg_color[200]}
    >
      <Brand />
      <FormLogin onFinish={onFinish} setActive={setActive} active={active} />
    </Box>
  );
};

export default Login;
