import { Box, Text } from "@chakra-ui/react";
import { FormInstance } from "antd";
import React, { useMemo } from "react";
import { PaymentPartnerDetail } from "service/OrganizationAdmin/Organization";
import { globalStyles } from "theme/styles";
import FormSetUp from "./FormSetUp";

interface IProps {
  formRef: React.MutableRefObject<FormInstance<any>>;
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
}

const FormSetUpPayment = ({
  formRef,
  paymentPartnerDetail,
  handleGetDetailPaymentParner,
}: IProps) => {
  const ID = useMemo(() => window.location.hash.split("/")[5], []);

  return (
    <Box>
      <Text
        color={globalStyles.colors.text[202027]}
        fontSize="15px"
        fontWeight="600"
        my="3"
      >
        Thiết lập thanh toán qua {ID}
      </Text>
      <Text
        my="3"
        fontWeight={300}
        fontSize="13px"
        fontStyle={"italic"}
        color="#000"
        opacity={"0.5"}
      >
        Để hoàn tất tạo mới cơ sở đào tạo, vui lòng nhập đủ thông tin cơ sở đào
        tạo và thiết lập đối tác trung gian thanh toán. Bạn có thể tạo mới nhiều
        cơ sở đào tạo trước khi hoàn tất quy trình. Các cơ sở được tạo mới thành
        công sẽ được hiển thị ở Danh sách cơ sở đào tạo
      </Text>

      <FormSetUp
        formRef={formRef}
        paymentPartnerDetail={paymentPartnerDetail}
        handleGetDetailPaymentParner={handleGetDetailPaymentParner}
      />
    </Box>
  );
};

export default FormSetUpPayment;
