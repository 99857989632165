import { Box } from "@chakra-ui/react";
import { Form, Input, Select, DatePicker, FormInstance } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { dateFormat, PATTERN_PHONE } from "util/Constant";
import dayjs from "dayjs";
import moment from "moment";
import ButtonFooter from "components/ButtonFooter";
const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
}

const FormOrganization = ({ onFinish }: IProps) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;

  const [isEdit, setIsEdit] = useState(false);
  const [prevDate, setPrevDate] = useState<number | undefined>(undefined);
  const [nextDate, setNextDate] = useState<number | undefined>(undefined);
  const formRef = React.useRef<FormInstance>(null);

  useEffect(() => {
    if (detailOrganization) {
      formRef?.current?.setFieldsValue({
        orgName: detailOrganization.orgName,
        orgCode: detailOrganization.orgCode,
        address: detailOrganization.address,
        email: detailOrganization.email,
        phone: detailOrganization.phone,
        endTime: detailOrganization.endTime
          ? dayjs(detailOrganization.endTime)
          : undefined,
        startTime: detailOrganization.startTime
          ? dayjs(detailOrganization.startTime)
          : undefined,
        status: detailOrganization.status ? "Hoạt động" : "Không hoạt động",
      });
      detailOrganization.startTime &&
        setPrevDate(
          new Date(
            moment(detailOrganization.startTime).add(1, "day").format()
          ).getTime()
        );
      detailOrganization.endTime && setNextDate(detailOrganization.endTime);
    }
  }, [detailOrganization]);

  useEffect(() => {
    if (!isEdit && detailOrganization) {
      formRef?.current?.setFieldsValue({
        orgName: detailOrganization.orgName,
        orgCode: detailOrganization.orgCode,
        address: detailOrganization.address,
        email: detailOrganization.email,
        phone: detailOrganization.phone,
        endTime: detailOrganization.endTime
          ? dayjs(detailOrganization.endTime)
          : undefined,
        startTime: detailOrganization.startTime
          ? dayjs(detailOrganization.startTime)
          : undefined,
        status: detailOrganization.status ? "Hoạt động" : "Không hoạt động",
      });
      detailOrganization.startTime &&
        setPrevDate(
          new Date(
            moment(detailOrganization.startTime).add(1, "day").format()
          ).getTime()
        );
      detailOrganization.endTime && setNextDate(detailOrganization.endTime);
    }
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [detailOrganization]);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Box w="80%" maxW="755px">
        <Form.Item
          name="orgName"
          label="Tên tổ chức"
          labelAlign="left"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập tên tổ chức!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Tên tổ chức"
            disabled={!isEdit}
            // defaultValue={detailOrganization?.orgName}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã tổ chức"
          name="orgCode"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập mã tổ chức!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Mã tổ chức"
            disabled
            // defaultValue={detailOrganization?.orgCode}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Email"
          name="email"
          className="text-bold"
          rules={[
            { required: true, message: "Vui lòng nhập Email!" },
            { type: "email", message: "Vui lòng nhập đúng định dạng email!" },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Email"
            disabled={!isEdit}
            // defaultValue={detailOrganization?.email}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Số điện thoại"
          className="text-bold"
          name="phone"
          rules={[
            {
              pattern: PATTERN_PHONE,
              message: "Vui lòng nhập đúng định dạng số điện thoại!",
            },
            {
              required: true,
              message: "Vui lòng nhập số điện thoại!",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Số điện thoại"
            disabled={!isEdit}
            // defaultValue={detailOrganization?.phone}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Địa chỉ"
          className="text-bold"
          name="address"
          rules={[{ required: true, message: "Vui lòng nhập địa chỉ!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Địa chỉ"
            disabled={!isEdit}
            // defaultValue={detailOrganization?.address}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Trạng thái"
          className="text-bold"
          name="status"
          rules={[{ required: false, message: "Vui lòng nhập trạng thái!" }]}
        >
          <Select
            className="w-full custom-input"
            defaultValue={"Hoạt động"}
            disabled={!isEdit}
          >
            <Option value="1">Hoạt động</Option>
            <Option value="0">Không hoạt động</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="startTime"
          labelAlign="left"
          label="Ngày kích hoạt"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
        >
          <DatePicker
            disabled={!isEdit}
            format={dateFormat}
            className="custom-input w-full"
            placeholder="Chọn ngày"
            disabledDate={(current: any) => {
              if (!nextDate) {
                return undefined;
              }
              return (
                new Date(
                  moment(current.toString()).endOf("day").format()
                ).getTime() >= nextDate
              );
            }}
            onChange={(e) => {
              e &&
                setPrevDate(
                  new Date(moment(e.toString()).endOf("day").format()).getTime()
                );
            }}
          />
        </Form.Item>
        <Form.Item
          name="endTime"
          labelAlign="left"
          label="Ngày kết thúc"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
        >
          <DatePicker
            disabled={!isEdit}
            format={dateFormat}
            className="custom-input w-full"
            placeholder="Chọn ngày"
            disabledDate={(current: any) => {
              if (!prevDate) {
                return undefined;
              }
              return new Date(current).getTime() < prevDate;
            }}
            onChange={(e) => {
              e &&
                setNextDate(
                  new Date(moment(e.toString()).endOf("day").format()).getTime()
                );
            }}
          />
        </Form.Item>
        <ButtonFooter
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          text={"Xác nhận"}
          text2="Cập nhật"
          formRef={formRef}
        />
      </Box>
    </Form>
  );
};

export default FormOrganization;
