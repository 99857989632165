import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, Form, FormInstance, message, Switch } from "antd";
import MainButton from "components/button";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  IPartners,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { setLoadingApp } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import ColConfig from "./ColConfig";
import * as Sentry from "@sentry/browser";

interface P {
  paymentMethod: PaymentMethodDetail;
  status: {
    _id: string;
    status: number;
  }[];
  handleGetPaymentMethodDetail: () => Promise<void>;
  setStatus: React.Dispatch<
    React.SetStateAction<
      {
        _id: string;
        status: number;
      }[]
    >
  >;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodDetail>>;
}

const Intermediatepaymentqateway = ({
  status,
  paymentMethod,
  setStatus,
  setPaymentMethod,
  handleGetPaymentMethodDetail,
}: P) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const { PaymentMethodUpdate, GetSchoolPartner } = OrganizationAdminService;
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);

  const [listTgtt, setListTgtt] = useState<IPartners[]>([]);
  const [signValue, setSignValue] =
    useState<{ [key: string]: string | undefined }>(undefined);

  const GetListTgtt = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetSchoolPartner(orgCode, ID);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setListTgtt(res.data.data.partners);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra")
      }
    } catch (error) {
      console.log(error);
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleConvertData = (values: any) => {
    if (!paymentMethod?.paymentMethod) {
      return [];
    }

    const arr = [...paymentMethod?.paymentMethod];

    const data = arr
      .map((e) => {
        const itemGroup: any = {};
        Object.keys(values).forEach((el) => {
          if (el.includes(e._id)) {
            itemGroup[el] = values[el];
          }
        });
        return { ...itemGroup };
      })
      .map((e) => {
        const paymentMethod: any = {};
        for (const key in e) {
          const methodKey = key.split("_")[1];
          const paymentMethodKey = key.split("_")[0];
          const paymentMethodObj = arr.find(
            (item) => item._id === paymentMethodKey
          );
          paymentMethod[methodKey] = e[key];

          if (methodKey === "status") {
            paymentMethod[methodKey] = e[key] ? 1 : 0;
          }

          if (methodKey === "amount") {
            paymentMethod[methodKey] = +e[key];
          }

          if (paymentMethodObj) {
            paymentMethod["_id"] = paymentMethodObj._id;
            paymentMethod["name"] = paymentMethodObj.name;
          }
        }
        delete paymentMethod.percent;
        return { ...paymentMethod };
      });
    return data;
  };

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const paymentMethod1: any = handleConvertData(values);
      const res = await PaymentMethodUpdate(orgCode, ID, {
        paymentMethod: paymentMethod1,
      });
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setPaymentMethod({ ...paymentMethod, paymentMethod: paymentMethod1 });
        handleSetSignValue({ ...paymentMethod, paymentMethod: paymentMethod1 });
        setIsEdit(false);
        message.success("Chỉnh sửa phương thức thanh toán thành công");
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors ? Sentry.captureException(res.data.data?.errors) : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleSetSignValue = (paymentMethod: PaymentMethodDetail) => {
    const sign =
      paymentMethod &&
      paymentMethod?.paymentMethod.reduce((a, b) => {
        a = { ...a, [`${b._id}_sign`]: `${b?.sign ?? ""}` };
        return a;
      }, {});
    sign && setSignValue(sign);
  };

  useEffect(() => {
    handleSetSignValue(paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    handleGetPaymentMethodDetail();
    GetListTgtt();
    // eslint-disable-next-line
  }, []);

  return (
    <Form name="basic" onFinish={onFinish} autoComplete="off" ref={formRef}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text color="#595959" fontSize="15px" fontWeight="500">
          Cổng thanh toán trung gian
        </Text>
        <Form.Item>
          {!isEdit ? (
            <Button
              onClick={() => {
                setIsEdit(true);
              }}
              className="btn-F3C262 custom-button"
            >
              Sửa
            </Button>
          ) : (
            <Flex
              flexDirection={"row"}
              alignContent="center"
              justifyContent="center"
            >
              <Button
                className="custom-button button-dash"
                onClick={() => {
                  setIsEdit(false);
                  formRef?.current?.resetFields();
                  handleGetPaymentMethodDetail();
                }}
                style={{
                  width: "95px",
                }}
              >
                Hủy
              </Button>
              <Box ml="2">
                <MainButton htmlType="submit" text="Xác nhận" />
              </Box>
            </Flex>
          )}
        </Form.Item>
      </Flex>
      <table className={`${isEdit ? "w-90 max-w-1078 my-table" : "my-table"}`}>
        <thead>
          <tr>
            <th style={{ width: "35%" }}>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Tên PTTT
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Trạng thái
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Ưu tiên chọn
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Điều kiện sử dụng
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {paymentMethod?.paymentMethod.map((e, i) => (
            <tr key={e._id}>
              <td>
                <Text
                  color="#045993"
                  fontSize="15px"
                  fontWeight="400"
                  p="4"
                  pl="0"
                >
                  {e.name}
                </Text>
              </td>
              <td>
                <Flex alignItems="center">
                  <Form.Item
                    name={`${e._id}_status`}
                    className="m-0"
                    initialValue={e.status === 1 ? "checked" : false}
                    valuePropName="checked"
                  >
                    <Switch
                      disabled={!isEdit}
                      onChange={(el) =>
                        setStatus([
                          ...status.slice(0, i),
                          { _id: e._id, status: el ? 1 : 0 },
                          ...status.slice(i + 1),
                        ])
                      }
                    />
                  </Form.Item>
                </Flex>
              </td>
              <ColConfig
                item={e}
                isEdit={isEdit}
                status={status}
                formRef={formRef}
                listTgtt={listTgtt}
                signValue={signValue}
                setSignValue={setSignValue}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </Form>
  );
};

export default Intermediatepaymentqateway;
