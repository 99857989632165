import { Box, Flex } from "@chakra-ui/react";
import { Button, FormInstance } from "antd";
import MainButton from "components/button";
import React from "react";

interface IProps {
  text?: string;
  text2?: string;
  isEdit: boolean;
  formRef: React.MutableRefObject<FormInstance<any>>;
  setIsEdit: (value: React.SetStateAction<boolean>) => void;
}

const ButtonFooter = ({ isEdit, setIsEdit, text, text2, formRef }: IProps) => {
  // const fileRef = useRef<HTMLInputElement>(null);

  // const handleClick = () => {
  //   fileRef.current?.focus();
  // };

  return (
    <Flex justifyContent="flex-end">
      {!isEdit ? (
        <Button
          onClick={() => {
            setIsEdit(true);
          }}
          className="btn-F3C262 custom-button"
        >
          {text2 ? text2 : "Sửa"}
        </Button>
      ) : (
        <Flex
          flexDirection={"row"}
          alignContent="center"
          justifyContent="center"
        >
          <Button
            className="custom-button button-dash"
            onClick={() => {
              setIsEdit(false);
              formRef?.current?.resetFields();
            }}
            style={{
              width: "95px",
            }}
          >
            Hủy
          </Button>
          <Box ml="2">
            <MainButton
              htmlType="submit"
              text={text ? text : "Xác nhận"}
              // onMouseDown={() => setIsEdit(false)}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default ButtonFooter;
