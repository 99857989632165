import { Box } from "@chakra-ui/react";
import { Form, FormInstance, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./style.css";

interface Props {
  deptBrick: any;
}

const ViewDetailTransaction = ({ deptBrick }: Props) => {
  const [isDisabled] = useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const handleSetData = () => {
    formRef?.current?.setFieldsValue({
      ...deptBrick,
      createDate: moment(deptBrick?.createdAt).format("HH:mm:ss DD/MM/YYYY"),
      status:
        deptBrick.status === 1
          ? "Thành công"
          : deptBrick.status === 2
          ? "Đang chờ xử lý"
          : "Thất bại",
    });
  };

  useEffect(() => {
    deptBrick && handleSetData();
    // eslint-disable-next-line
  }, [deptBrick]);

  return (
    <Form
      name="basic"
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
      className="custom-disable-input"
    >
      <Box maxW={"755px"}>
        <Form.Item labelAlign="left" label="Mã sinh viên" name="ma_sv">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã cơ sở" name="schoolCode">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tên cơ sở"
          name="schoolName"
        >
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Tên sinh viên" name="studentName">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã phiếu thu" name="so_phieu_bao">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Thời gian" name="createdAt">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Số lần" name="count">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Trạng thái" name="status">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
      </Box>
    </Form>
  );
};

export default ViewDetailTransaction;
