import { Box, Flex, Text } from "@chakra-ui/react";
import IconCheck from "components/icons/IconCheck";
import React from "react";
import { useHistory } from "react-router-dom";
import { ADMIN } from "util/Constant";
import { LIST_TTGT } from "../../TGTTPartner";

interface IProps {
  img: string;
  type: any;
  id: string;
  schoolCode: string;
  orgCode: string;
  showCheck: boolean;
  isParner: boolean;
}

const ALL_LIST_TTGT = {
  VNPAY: "VNPAY",
  BIDV: "BIDV",
  ONEPAY: "ONEPAY",
  MOMO: "MOMO",
  VETTELMONEY: "VETTELMONEY",
  SHB: "SHB",
  ACB: "ACB",
};
// const ALL_LIST_TTGT = ["VNPAY", "BIDV", "ONEPAY", "MOMO", "VETTELMONEY"];

const ItemParner = ({
  id,
  img,
  type,
  orgCode,
  isParner,
  showCheck,
  schoolCode,
}: IProps) => {
  const history = useHistory();

  const handleNavigate = (id: string) => {
    if (id === LIST_TTGT[0]) {
      history.push(
        `${ADMIN}/${orgCode}/set-up-payment-vnpay/${schoolCode}/${id}`
      );
    } else if (id === LIST_TTGT[1]) {
      history.push(
        `${ADMIN}/${orgCode}/set-up-payment-bidv/${schoolCode}/${id}`
      );
    } else if (id === LIST_TTGT[2]) {
      history.push(
        `${ADMIN}/${orgCode}/set-up-payment-shb/${schoolCode}/${id}`
      );
    } else if (id === LIST_TTGT[3]) {
      history.push(
        `${ADMIN}/${orgCode}/set-up-payment-onepay/${schoolCode}/${id}`
      );
    } else {
      history.push(
        `${ADMIN}/${orgCode}/set-up-payment-acb/${schoolCode}/${id}`
      );
    }
  };

  const renderImg = () => {
    switch (img) {
      case ALL_LIST_TTGT.BIDV:
        return (
          <img
            src={require("assets/img/Logo/logo-bidv.png")}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        );
      case ALL_LIST_TTGT.MOMO:
        return (
          <img
            src={require("assets/img/Logo/logo-momo.png")}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        );
      case ALL_LIST_TTGT.ONEPAY:
        return (
          <img
            src={require("assets/img/Logo/logo-onepay.png")}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        );
      case ALL_LIST_TTGT.VETTELMONEY:
        return (
          <img
            src={require("assets/img/Logo/viettel-money.png")}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        );
      case ALL_LIST_TTGT.VNPAY:
        return (
          <img
            src={require("assets/img/Logo/VNPAY.png")}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        );
      case ALL_LIST_TTGT.SHB:
        return (
          <img
            src={require("assets/img/Logo/SHB.png")}
            alt=""
            style={{
              maxWidth: "140px",
              maxHeight: "55.367px",
            }}
          />
        );
      case ALL_LIST_TTGT.ACB:
        return (
          <img
            src={require("assets/img/Logo/ACB.png")}
            alt=""
            style={{
              maxWidth: "140px",
              maxHeight: "55.367px",
            }}
          />
        );

      default:
        return (
          <img
            src={require("assets/img/Logo/VNPAY.png")}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        );
    }
  };

  return (
    <Box
      py="2"
      p="4"
      mr="6"
      boxShadow={"0px 2px 16px 0px rgba(0, 0, 0, 0.15)"}
      borderRadius={"16px"}
      w={"250px"}
      h={"140px"}
      display="flex"
      flexDirection="column"
      mb="4"
    >
      <Flex justifyContent={"space-between"}>
        {renderImg()}

        {type === 1 && showCheck && <IconCheck />}
      </Flex>

      {isParner && (
        <Text
          cursor={"pointer"}
          color={"#045993"}
          textDecorationLine="underline"
          onClick={() => handleNavigate(id)}
          className="text-align"
        >
          {"Thiết lập"}
        </Text>
      )}
      {!isParner && (
        <Text
          color={"#4A4A4A"}
          textAlign="right"
          className="text-align"
          textDecorationLine="underline"
        >
          Commingsoon
        </Text>
      )}
    </Box>
  );
};

export default ItemParner;
