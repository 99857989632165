// Chakra imports
import { Box, useDisclosure } from "@chakra-ui/react";
import { message } from "antd";
import Loading from "components/loading";
import AdminNavbar from "components/navbar/NavbarAdmin";
// Layout components
import Sidebar from "components/sidebar/Sidebar";
import { SidebarContext } from "contexts/SidebarContext";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import routes from "routes";
import AuthService from "service/Auth/AuthService";
import { RootState } from "store";
import { setAppPermisson, setAuthInfor, Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { validResponse } from "util/API";
import { CODE_SUCCESS, OSA } from "util/Constant";
import { handleThrowError } from "util/general";
import * as Sentry from "@sentry/browser";

const DEFAULT_ROUTER = "/branch-management";
// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { appLoading, authInfor, brandText } = appState;
  const localtion = useLocation();
  const [pathname, setPathname] = useState("");

  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getActiveRoute = (
    routes: RoutesType[],
    currenRoute: string
  ): { name: string; url: string } => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (currenRoute.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return { name: routes[i].name, url: routes[i].path };
      } else if (brandText) {
        return { name: brandText.name, url: brandText.link };
      }
    }

    return { name: activeRoute, url: "#" };
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === `${OSA.toLocaleLowerCase()}`) {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const onHandleGetDetailAccount = async () => {
    try {
      const res = await AuthService.GetOrganizationDetail();
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        dispatch(setAuthInfor(res.data.data));
        dispatch(setAppPermisson(res.data.data.accountType));
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : (res.data.data?.message as any)
          );
          res.data.data?.errors ? Sentry.captureException(res.data.data?.errors) : Sentry.captureMessage(res.data.data?.message as any)
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error)
    }
  };

  useEffect(() => {
    // lấy thông tin tài khoàn admin nếu không có thông tin
    !authInfor && onHandleGetDetailAccount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPathname(localtion.pathname);
  }, [localtion]);

  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 280px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 280px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box>
            <AdminNavbar
              onOpen={onOpen}
              logoText={"edusoft"}
              brandText={getActiveRoute(routes, pathname)}
              secondary={getActiveNavbar(routes)}
              message={getActiveNavbarText(routes)}
              fixed={fixed}
              {...rest}
            />
          </Box>

          <Box
            mx="auto"
            p={{ base: "10px", md: "10px" }}
            pe="20px"
            // minH="100vh"
            pt="50px"
            style={{
              backgroundColor: globalStyles.colors.bg_color[100],
            }}
          >
            <Box display={appLoading ? "none" : ""}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to={OSA + DEFAULT_ROUTER} />
              </Switch>
            </Box>
            {appLoading && <Loading />}
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
