import { Box } from "@chakra-ui/react";
import { Button } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveRoute, setBreadcrumb } from "store/appSlice";
import { ADMIN } from "util/Constant";

interface P {
  activeBtn: number;
  ID: string;
  orgCode?: string;
}

const TabsPanel = ({ activeBtn, ID, orgCode }: P) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveRoute("/organization-management"));
    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBreadcrumb([]));
      // localStorage.clear();
    };
  }, [dispatch]);

  return (
    <Box className="tabs-panel">
      <Button
        className={`organization-information ${
          activeBtn === 1 ? "active" : ""
        }`}
        onClick={() => {
          history.push(`${ADMIN}/${orgCode}/school-information/${ID}`);
        }}
      >
        Thông tin cơ sở
      </Button>
      <Button
        className={`login-account ${activeBtn === 2 ? "active" : ""}`}
        onClick={() => history.push(`${ADMIN}/${orgCode}/bill-type/${ID}`)}
      >
        Danh mục loại thu
      </Button>
      <Button
        className={`login-account ${activeBtn === 3 ? "active" : ""}`}
        onClick={() => history.push(`${ADMIN}/${orgCode}/tgtt-partner/${ID}`)}
      >
        Đối tác TGTT
      </Button>
      <Button
        className={`login-account ${activeBtn === 4 ? "active" : ""}`}
        onClick={() =>
          history.push(`${ADMIN}/${orgCode}/payment-methods/${ID}`)
        }
      >
        Phương thức thanh toán
      </Button>
      <Button
        className={`login-account ${activeBtn === 5 ? "active" : ""}`}
        onClick={() =>
          history.push(`${ADMIN}/${orgCode}/management-fees/${ID}`)
        }
      >
        Quản lý phí
      </Button>
      <Button
        className={`training-facilities ${activeBtn === 6 ? "active" : ""}`}
        onClick={() => history.push(`${ADMIN}/${orgCode}/login-shool/${ID}`)}
      >
        Tài khoản đăng nhập
      </Button>
    </Box>
  );
};

export default TabsPanel;
