/* eslint-disable */

import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useMemo } from "react";
import { EnumPermission } from "util/Constant";
import { globalStyles } from "theme/styles";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import Collapse from "./Collapse";

export function SidebarLinks(props: { routes: RoutesType[] }) {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { appPermission, activeRoute } = appState;

  //   Chakra color mode
  let location = useLocation();
  let activeIcon = useColorModeValue("white", "white");
  let textColor = globalStyles.colors.text[202027];

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const onActiveRoute = (routeName: string) => {
    return location.pathname.includes(routeName) || routeName === activeRoute;
  };

  const backgroundBtn = useMemo(() => {
    switch (appPermission) {
      case EnumPermission.ADMIN:
        return globalStyles.colors.permission.ADMIN;
      case EnumPermission.CSDT:
        return globalStyles.colors.permission.CSDT;
      case EnumPermission.OSA:
        return globalStyles.colors.permission.OSA;

      default:
        return globalStyles.colors.permission.ADMIN;
    }
  }, [appPermission]);

  const BtnColor = useMemo(() => {
    switch (appPermission) {
      case EnumPermission.ADMIN:
        return "#a4daff";
      case EnumPermission.CSDT:
        return "#C7E5D9";
      case EnumPermission.OSA:
        return "#ffe6ca";

      default:
        return globalStyles.colors.permission.ADMIN;
    }
  }, [appPermission]);

  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, index: number) => {
      if (
        route.layout === `/${appPermission?.toLocaleLowerCase()}` &&
        !route.hidden
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            {!route?.children ? (
              <Box>
                <HStack
                  spacing={
                    onActiveRoute(route.path.toLowerCase()) ? "22px" : "26px"
                  }
                  ps="0px"
                >
                  <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor={
                      onActiveRoute(route.path.toLowerCase())
                        ? BtnColor
                        : "transparent"
                    }
                    p={2.5}
                    borderRadius={"6px"}
                  >
                    <Box
                      color={
                        onActiveRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      <i
                        className={`fa-solid ${route.icon} ${
                          onActiveRoute(route.path.toLowerCase())
                            ? "active-color"
                            : "inactive-color"
                        }`}
                        style={{
                          color: onActiveRoute(route.path.toLowerCase())
                            ? backgroundBtn
                            : textColor,
                        }}
                      />
                    </Box>
                    <Text
                      me="auto"
                      fontSize={"16px"}
                      color={
                        onActiveRoute(route.path.toLowerCase())
                          ? backgroundBtn
                          : textColor
                      }
                      fontWeight={
                        onActiveRoute(route.path.toLowerCase()) ? 500 : 400
                      }
                    >
                      {route.name}
                    </Text>
                  </Flex>
                </HStack>
              </Box>
            ) : (
              <Collapse
                backgroundBtn={backgroundBtn}
                onActiveRoute={onActiveRoute}
                route={route}
                textColor={textColor}
                BtnColor={BtnColor}
              />
            )}
          </NavLink>
        );
      }
    });
  };
  //  BRAND
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
