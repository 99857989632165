import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, message } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import React, { useEffect, useMemo, useState } from "react";
import {
  IExtraData,
  PaymentConfigPartnerBIDV,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";

import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";
import * as Sentry from "@sentry/browser";
import { ResponseBase } from "types/general";
import { useDispatch } from "react-redux";
import { setLoadingApp } from "store/appSlice";

interface IProps {
  listTID: PaymentConfigPartnerBIDV[];
  handleGetDetailPaymentParner: () => Promise<void>;
  setListTID: React.Dispatch<React.SetStateAction<PaymentConfigPartnerBIDV[]>>;
}

const SetUpID = ({
  listTID,
  setListTID,
  handleGetDetailPaymentParner,
}: IProps) => {
  const dispatch = useDispatch();

  const [itemEdit, seItemEdit] = useState<PaymentConfigPartnerBIDV | undefined>(
    undefined
  );
  const [cloneListTID, setCloneListTID] = useState<PaymentConfigPartnerBIDV[]>(
    []
  );
  const [isEdit, setIsEdit] = useState(false);
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);

  const { AddPartnerConfig, PartnerUpdateConfig, DelPaymentConfig } =
    OrganizationAdminService;

  const handleEditRow = (index: number) => {
    setIsEdit(true);
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, isEdit: true };

    seItemEdit(currenIndex);
    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const onChangeValue = (
    index: number,
    key: string,
    values: string,
    type: "nested" | "plane"
  ) => {
    let currenIndex = cloneListTID[index];
    let extraData: IExtraData | undefined = undefined;
    if (type === "nested") {
      extraData = {
        ...currenIndex.extraData,
        [key]: values,
      };
      currenIndex = { ...currenIndex, extraData };
    } else {
      currenIndex = { ...currenIndex, [key]: values };
    }

    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const handleRemoveRow = (index: number) => {
    const itemRemove = cloneListTID[index];
    const newArr = [
      ...cloneListTID.slice(0, index),
      ...cloneListTID.slice(index + 1),
    ];

    handleDeleteRowInServer(itemRemove.configCode);

    setCloneListTID(newArr);
    setListTID(newArr);
  };

  const handleAddRow = () => {
    setCloneListTID([
      ...cloneListTID,
      {
        isEdit: true,
        configName: "",
        extraData: {
          clientId: "",
          clientName: "",
          clientSecret: "",
          configName: "",
          serviceId: "",
        },
      },
    ]);
  };

  const handleValidate = () => {
    let err = false;
    cloneListTID
      .map((e) => {
        let extraData: IExtraData | undefined = undefined;
        if (Object.keys(e).length) {
          extraData = {
            ...e.extraData,
          };
        }
        return { ...extraData, isEdit: false };
      })
      .forEach((e: any) => {
        Object.keys(e).forEach((el: any) => {
          if (e[el] === "") {
            err = true;
          }
        });
      });
    return err;
  };

  const handleConfirm = () => {
    const listData = cloneListTID.map((e) => ({ ...e, isEdit: false }));
    // console.log(listData, 'listData')


    if (!handleValidate()) {
      setIsEdit(false);
      !itemEdit
        ? handlePostDataToServer(listData[listData.length - 1])
        : handlePostDataToServer(listData.find((e) => e._id === itemEdit._id));
    } else {
      message.error("Yêu cầu nhập đầy đủ các trường");
      Sentry.captureMessage("Yêu cầu nhập đầy đủ các trường");
    }
  };

  const handleDeleteRowInServer = async (_id: string) => {
    try {
      dispatch(setLoadingApp(true));
      const response = await DelPaymentConfig(orgCode, schoolCode, _id);
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        await handleGetDetailPaymentParner();
        message.success("Xoá thành công config");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    } finally {
      seItemEdit(undefined);
      dispatch(setLoadingApp(false));
      setIsEdit(false);
    }
  };

  // post Set config cho BIDV
  const handleAddConfigBidv = async (valuePost: IExtraData) => {
    const response = await AddPartnerConfig(
      valuePost,
      orgCode,
      schoolCode,
      ID.toLocaleLowerCase()
    );
    return response;
  };

  // patch Update BIDV Config
  const handleUpdateConfigBidv = async (valuePost: IExtraData, _id: string) => {
    const response = await PartnerUpdateConfig(
      valuePost,
      orgCode,
      schoolCode,
      _id,
      ID.toLocaleLowerCase()
    );
    return response;
  };

  const handlePostDataToServer = async (input: PaymentConfigPartnerBIDV) => {
    try {
      dispatch(setLoadingApp(true));
      const { configName, extraData } = input;

      const valuePost = {
        configName,
        ...extraData,
      };
      let response: ResponseBase<any, boolean> | undefined = undefined;

      if (!itemEdit) {
        response = await handleAddConfigBidv(valuePost);
      } else {
        response = await handleUpdateConfigBidv(valuePost, input.configCode);
      }

      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        await handleGetDetailPaymentParner();
        message.success(
          `${!itemEdit ? "Set" : "Cập nhật"} config cho BIDV thành công`
        );
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    } finally {
      seItemEdit(undefined);
      dispatch(setLoadingApp(false));
      setIsEdit(false);
      setCloneListTID([...cloneListTID.map((e) => ({ ...e, isEdit: false }))]);
    }
  };

  useEffect(() => {
    setCloneListTID(listTID);
    // eslint-disable-next-line
  }, [listTID]);

  const handleCancel = () => {
    setIsEdit(false);
    setCloneListTID([...listTID]);
  };

  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <table className="my-table w-90 max-w-1078">
        <thead>
          <tr>
            <th style={{ width: "15%" }}>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Tên tài khoản <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                clientId <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                clientSecret <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                clientName <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                serviceId <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Hành động
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {cloneListTID.map((e, i) => (
            <tr key={i} className="tr-tid">
              {e.isEdit ? (
                <RenderRowInput
                  item={e}
                  onChangeValue={onChangeValue}
                  index={i}
                />
              ) : (
                <RenderRow
                  item={e}
                  handleRemoveRow={handleRemoveRow}
                  index={i}
                  handleEditRow={handleEditRow}
                  isEdit={isEdit}
                />
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Box className="w-90 max-w-1078">
        {!isEdit && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SetUpID;
