import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, Modal } from "antd";
import MainButton from "components/button";
import IconWanning from "components/icons/IconWanning";
import { HSeparator } from "components/separator/Separator";
import React from "react";
import { globalStyles } from "theme/styles";
import Cloes from "views/admin/OrganizationManagement/Icon/Cloes";

interface p {
  text: string;
  isOpen: boolean;
  onClose: () => void;
  handleRejectRefund: () => Promise<void>;
  handleApproveRefund: () => Promise<void>;
}

const ModalConfirm = ({
  text,
  isOpen,
  onClose,
  handleRejectRefund,
  handleApproveRefund,
}: p) => {
  const handleConfirmClick = () => {
    if (text === "phê duyệt") {
      handleApproveRefund();
    } else {
      handleRejectRefund();
    }
    // Close the modal
    onClose();
  };
  return (
    <Modal
      title="Thông báo"
      open={isOpen}
      onCancel={onClose}
      footer={<></>}
      closeIcon={
        <>
          <Cloes />
        </>
      }
    >
      <HSeparator />
      <Box px="16px" py="16px">
        <Flex alignContent="center" justifyContent="center" mt="4">
          <IconWanning />
        </Flex>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="14px"
          mt="4"
          textAlign={"center"}
        >
          Bạn muốn {text} giao dịch ?
        </Text>

        <Flex
          flexDirection={"row"}
          alignContent="center"
          justifyContent="center"
          mt="4"
        >
          <Button
            className={"custom-button button-dash"}
            // styles={{ color: "#FFA6A6" }}
            style={{
              width: "95px",
            }}
            onClick={onClose}
          >
            Hủy
          </Button>
          <Box ml="2">
            <MainButton
              classButton={text !== "phê duyệt" && "color-FFA6A6 custom-button"}
              htmlType="submit"
              text={"Xác nhận"}
              onClick={handleConfirmClick}
            />
          </Box>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ModalConfirm;
