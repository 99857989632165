import { Box, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { globalStyles } from "theme/styles";
import { formatMoney } from "util/general";
import moment from "moment";
import Table from "components/table";

interface IProps {
  billList: any[];
  totalRow: number;
  loading: boolean;
  pageSize: number;
  currentPage: number;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnSelectProps: (dataIndex: any) => any;
}

const View = ({
  loading,
  billList,
  pageSize,
  totalRow,
  currentPage,
  getColumnDateProps,
  onChangeCurrentPage,
  getColumnSelectProps,
  getColumnSearchProps,
}: IProps) => {
  const columns: ColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "approvedAt",
      key: "approvedAt",
      width: "6.5%",
      render: (value) => (
        <Box pr="22px">
          {value ? moment(value).format("HH:mm:ss DD/MM/YYYY") : ""}
        </Box>
      ),
      ...getColumnDateProps("approvedAt", "eq", "date"),
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "orderId",
      key: "orderId",
      width: "10%",
      ...getColumnSearchProps("orderId", "contains", "text"),
    },
    // {
    //   title: "Số phiếu thu",
    //   dataIndex: "so_phieu_bao",
    //   key: "so_phieu_bao",
    //   width: "8%",
    //   ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
    // },
    {
      title: "Người tạo",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "8%",
      ...getColumnSearchProps("createdBy", "contains", "text"),
    },

    {
      title: "Người duyệt",
      dataIndex: "approvedBy",
      key: "approvedBy",
      width: "8%",
      ...getColumnSearchProps("approvedBy", "contains", "text"),
    },
    {
      title: "Tên sinh viên",
      dataIndex: "ten_sv",
      key: "ten_sv",
      width: "7%",
      ...getColumnSearchProps("ten_sv", "contains", "text"),
    },
    {
      title: "Mã sinh viên",
      dataIndex: "ma_sv",
      key: "ma_sv",
      width: "7%",
      ...getColumnSearchProps("ma_sv", "contains", "text"),
    },
    {
      title: "Số phiếu thu",
      dataIndex: "so_phieu_bao",
      key: "so_phieu_bao",
      width: "7%",
      ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
    },
    {
      title: "Giá trị (VND)",
      dataIndex: "amount",
      key: "amount",
      width: "6%",
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
      align: "right",
      ...getColumnSearchProps("amount", "eq", "text"),
    },
    {
      title: "Lý do",
      dataIndex: "reason",
      key: "reason",
      width: "7%",
      ...getColumnSearchProps("reason", "eq", "text"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (value) => (
        <div>
          {value === 1 ? (
            <Text color="#5FAD67">Thành công</Text>
          ) : (
            <Text color="#FF2323">Thất bại</Text>
          )}
        </div>
      ),
      ...getColumnSelectProps("status"),
    },
  ];

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text color={globalStyles.colors.text[202027]} fontWeight="600">
          Danh sách hoàn tiền
        </Text>
      </Flex>

      <Table
        columns={columns}
        dataSource={billList}
        text={"giao dịch"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"2400px"}
      />
    </Box>
  );
};

export default View;
