import { Box, Text } from "@chakra-ui/react";
import { ColumnsType } from "antd/es/table";
import Table from "components/table";
import React from "react";
import { formatMoney } from "util/general";
import { IBILL } from "..";

interface P {
  dataSource: IBILL[];
  loading: boolean;
  rowSelection: {
    onChange: (_: React.Key[], selectedRows: IBILL[]) => void;
    getCheckboxProps: (record: IBILL) => {
      disabled: boolean;
    };
  };
}

const ListReceipts = ({ dataSource, loading, rowSelection }: P) => {
  const columns: ColumnsType<IBILL> = [
    {
      title: "Mã đơn hàng",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Nội dung",
      dataIndex: "noi_dung",
      key: "noi_dung",
      render: (text) => {
        return <Box dangerouslySetInnerHTML={{ __html: text }} />;
      },
    },
    {
      title: "Giá trị (VND)",
      dataIndex: "amount",
      key: "amount",
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
    },
  ];

  return (
    <>
      <Text color="#202027" fontSize="15px" fontWeight="500" my="4">
        Phiếu thu đã thanh toán
      </Text>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        isPagination={false}
        rowSelection={rowSelection}
        width="80vw"
        type="radio"
      />
    </>
  );
};

export default ListReceipts;
