import {
  Box,
  Collapse as CollapseChakra,
  HStack,
  Flex,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";

interface P {
  textColor: string;
  route: RoutesType;
  BtnColor: string;
  backgroundBtn: string;
  onActiveRoute: (routeName: string) => boolean;
}

const Collapse = ({
  route,
  BtnColor,
  textColor,
  backgroundBtn,
  onActiveRoute,
}: P) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { activeRoute } = appState;
  let location = useLocation();
  const [isOpen, onToggle] = useState(false);
  const [active, setActive] = useState("");

  useEffect(() => {
    const path = route.children.find((e) => location.pathname.includes(e.path));

    if (path) {
      setActive(path.path);
    } else {
      onToggle(false);
      setActive("");
    }

    // setActive();
  }, [location.pathname, setActive, route.children]);

  return (
    <Box
      backgroundColor={isOpen ? "#E8F3EF" : "transparent"}
      borderRadius={"6px"}
    >
      <Box>
        <HStack
          spacing={active ? "22px" : "26px"}
          ps="0px"
          onClick={() => onToggle(!isOpen)}
        >
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="center"
            backgroundColor={
              active || activeRoute === route.path.toLocaleLowerCase()
                ? BtnColor
                : "transparent"
            }
            p={2.5}
            borderRadius={isOpen ? 0 : "6px"}
            borderTopRadius={"6px"}
            // borderLeft={"3px solid #1B8E5F"}
          >
            <Box me="18px">
              <i
                className={`fa-solid ${route.icon}`}
                style={{
                  color:
                    active || activeRoute === route.path.toLocaleLowerCase()
                      ? backgroundBtn
                      : textColor,
                }}
              />
            </Box>
            <Text
              me="auto"
              fontSize={"16px"}
              color={
                active || activeRoute === route.path.toLocaleLowerCase()
                  ? backgroundBtn
                  : textColor
              }
              fontWeight={isOpen ? 500 : 400}
            >
              {route.name}
            </Text>
            <TriangleUpIcon
              transition={"0.3s ease-in-out"}
              className={
                active || activeRoute === route.path.toLocaleLowerCase()
                  ? "triangle"
                  : ""
              }
              p="0"
              m="0"
              display={!isOpen ? "none" : ""}
              color="red.500"
            />
            <TriangleDownIcon
              transition={"0.3s ease-in-out"}
              p="0"
              m="0"
              className={
                active || activeRoute === route.path.toLocaleLowerCase()
                  ? "triangle"
                  : ""
              }
              display={isOpen ? "none" : ""}
              // backgroundColor={"#fff"}
              color="red.500"
            />
          </Flex>
        </HStack>
      </Box>

      <CollapseChakra in={isOpen} animateOpacity>
        {route.children.map((e, i) => (
          <NavLink key={i} to={e.layout + e.path}>
            <Box>
              <HStack
                spacing={onActiveRoute(e.path.toLowerCase()) ? "18px" : "22px"}
                py="5px"
                ps="0px"
              >
                <Flex
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor={"transparent"}
                  p={2}
                  px={2.5}
                  // borderRadius={"6px"}
                  // px="4"
                >
                  <Box me="18px" visibility="hidden">
                    <i
                      className={`fa-solid ${e.icon} ${
                        onActiveRoute(e.path.toLowerCase())
                          ? "active-color"
                          : "inactive-color"
                      }`}
                    />
                  </Box>
                  <Text
                    me="auto"
                    fontSize={"16px"}
                    color={
                      onActiveRoute(e.path.toLowerCase())
                        ? backgroundBtn
                        : textColor
                    }
                    fontWeight={onActiveRoute(e.path.toLowerCase()) ? 500 : 400}
                  >
                    {e.name}
                  </Text>
                </Flex>
              </HStack>
            </Box>
          </NavLink>
        ))}
      </CollapseChakra>
    </Box>
  );
};

export default Collapse;
