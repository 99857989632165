import { message } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import OsaService from "service/OSA/Osa";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import * as Sentry from "@sentry/browser";

interface p {
  date: number;
  id: string;
  fileName?: string;
}

const UseDownloadFIleHook = ({ date, id, fileName }: p) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const handleDownloadExcel = async () => {
    try {
      const res = await OsaService.RECONCILE_DOWNLOAD(
        authInfor.orgCode,
        authInfor.schoolCode,
        id
      );
      if (res.status) {
        let fileName1 = `edubill_vnpay_reconcile_${moment(+date).format(
          "DDMMYYYY"
        )}.xlsx`;

        // create element
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = urlDownload;
        link.setAttribute("download", fileName ? fileName : fileName1);
        link.click();
        message.success("Tải báo cáo thành công");
      } else {
        message.error(`Không thể xuất báo cáo. Vui lòng thử lại`);
        Sentry.captureMessage("Không thể xuất báo cáo. Vui lòng thử lại");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { handleDownloadExcel };
};

export default UseDownloadFIleHook;
