import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { globalStyles } from "theme/styles";
import { InputOrganizationCreate } from "service/Auth";
import FormLoginAccount from "views/admin/AddNewOrganization/Components/LoginAccount/FormLoginAccount";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRoute,
  setBrandText,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { validResponse } from "util/API";
import AuthService from "service/Auth/AuthService";
import { message } from "antd";
import { CODE_SUCCESS, OSA } from "util/Constant";
import { handleThrowError } from "util/general";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import * as Sentry from "@sentry/browser";

const UserInforOSA = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [organizationCreate, setOrganizationCreate] = useState<
    InputOrganizationCreate | undefined
  >(undefined);

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const newData = {
        ...values,
        orgCode,
        status: values.status === "Hoạt động" ? 1 : 0,
      };

      const { status, ...rest } = newData;

      const res = !ID
        ? await AuthService.AuthCreateOrganization(authInfor?.orgCode, rest)
        : await AuthService.UpdateOsa(orgCode, ID, newData);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setOrganizationCreate(values);

        message.success(
          !organizationCreate
            ? "Tạo mới tài khoản tổ chức thành công"
            : "Chỉnh sửa tài khoản tổ chức thành công"
        );
        !ID && history.push(`${OSA}/user-management`);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
          // message.error(`${res.data.data?.message}`);
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };
  const onHandleGetDetailAccount = async () => {
    try {
      const query = `_id[eq]=${ID}`;
      dispatch(setLoadingApp(true));
      const res = await AuthService.AuthGetOrganization(query, orgCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        res.data.data?.records[0] &&
          setOrganizationCreate(res.data.data.records[0]);
      } else {
        message.error("Có lỗi xảy ra !");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    orgCode && ID && onHandleGetDetailAccount();
    dispatch(setActiveRoute("/user-management"));
    dispatch(
      setBrandText({
        name: "Quản lý người dùng tổ chức",
        link: `${OSA}/user-management`,
      })
    );

    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBrandText());
    };
    // eslint-disable-next-line
  }, [orgCode]);

  return (
    <Box
      // mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <Flex
        alignItems={"center"}
        cursor="pointer"
        onClick={() => history.push(`${OSA}/user-management`)}
        w={"fit-content"}
      >
        <ChevronLeftIcon color={globalStyles.colors.permission.OSA} />
        <Text
          color={globalStyles.colors.permission.OSA}
          fontSize="15px"
          fontWeight={600}
          textDecorationLine="underline"
        >
          Quay lại
        </Text>
      </Flex>

      <Text my="3" fontWeight={500} fontSize="15px" color="#202027">
        Thông tin chi tiết
      </Text>

      <Text
        my="3"
        fontWeight={300}
        fontSize="13px"
        fontStyle={"italic"}
        color="#000"
        opacity={"0.5"}
      >
        Đây là các thông tin cá nhân của nhân sự Edubills. Các trường đánh dấu
        sao là trường bắt buộc nhập.
      </Text>
      <FormLoginAccount
        onFinish={onFinish}
        organizationCreate={organizationCreate}
      />
    </Box>
  );
};

export default UserInforOSA;
