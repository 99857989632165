import React, { useMemo } from "react";
import { Table as TableAndt, Pagination } from "antd";
import { ColumnsType } from "antd/es/table";
import { EnumPermission } from "util/Constant";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { CaretRightOutlined } from "@ant-design/icons";
import { Flex, Text } from "@chakra-ui/react";
import { globalStyles } from "theme/styles";

interface IProps {
  dataSource: any[];
  columns: ColumnsType<any>;
  type?: "radio" | "checkbox";
  loading: boolean;
  isPagination?: boolean;
  totalRow?: number;
  currentPage?: number;
  pageSize?: number;
  text?: string;
  width?: string;
  onChangeCurrentPage?: (e: any) => void;
  rowSelection?: {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => void;
  };
}

const Table = (props: IProps) => {
  const {
    type = "checkbox",
    text,
    width,
    columns,
    loading,
    pageSize,
    totalRow,
    dataSource,
    currentPage,
    isPagination = true,
    rowSelection,
    onChangeCurrentPage,
  } = props;

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { appPermission } = appState;

  const backgroundBtnPagination = useMemo(() => {
    switch (appPermission) {
      case EnumPermission.ADMIN:
        return "pagination-admin";
      case EnumPermission.CSDT:
        return "pagination-csdt";
      case EnumPermission.OSA:
        return "pagination-osa";

      default:
        return "pagination-admin";
    }
  }, [appPermission]);

  return (
    <>
      <TableAndt
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ y: "60vh", x: width ? width : "140vw" }}
        rowSelection={
          rowSelection
            ? {
                type: type ?? "checkbox",
                ...rowSelection,
              }
            : undefined
        }
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretRightOutlined
                onClick={(e) => onExpand(record, e)}
                rotate={90}
                className={`${record.expaned === "1" ? "" : "hidden"}`}
              />
            ) : (
              <CaretRightOutlined
                onClick={(e) => onExpand(record, e)}
                rotate={0}
                className={`${record.expaned === "1" ? "" : "hidden"}`}
              />
            ),

          // rowExpandable: (record) => record.status === '1',
        }}
      />

      {isPagination && (
        <Flex flexDirection="row" mt="10">
          <Text color={globalStyles.colors.text[202027]}>
            Tổng:{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {totalRow}
            </span>{" "}
            {text}
          </Text>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Pagination
              className={backgroundBtnPagination}
              current={currentPage}
              showSizeChanger={false}
              total={totalRow}
              pageSize={pageSize}
              onChange={onChangeCurrentPage}
            ></Pagination>
          </div>
        </Flex>
      )}
    </>
  );
};

export default Table;
