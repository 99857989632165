import { Box, Button, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import Table from "components/table";
import { useHistory } from "react-router-dom";
import { globalStyles } from "theme/styles";
import { ADMIN } from "util/Constant";

interface IProps {
  traningFacilities: any[];
  totalRow: number;
  loading: boolean;

  pageSize: number;
  currentPage: number;
  handleSearch: (args: any) => Promise<void>;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  setVisibleModal: (value: boolean) => void;
}

const View = ({
  loading,
  traningFacilities,
  pageSize,
  totalRow,
  currentPage,
  setVisibleModal,
  onChangeCurrentPage,
  getColumnSearchProps,
}: IProps) => {
  const history = useHistory();

  const handleNavigate = (id: string, orgCode: string) => {
    history.push(`${ADMIN}/${orgCode}/school-information/${id}`);
  };

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "4%",
      align: "center",
    },
    {
      title: "Tên cơ sở",
      dataIndex: "schoolName",
      key: "schoolName",
      ...getColumnSearchProps("schoolName", "contains", "text"),
      render: (value, record) => (
        <span
          onClick={() => handleNavigate(record?.schoolCode, record?.orgCode)}
          style={{
            color: "#045993",
            cursor: "pointer",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Mã cơ sở",
      dataIndex: "schoolCode",
      key: "schoolCode",
      ...getColumnSearchProps("schoolCode", "contains", "text"),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone", "contains", "text"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "contains", "text"),
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
      ...getColumnSearchProps("fax", "contains", "text"),
    },
    {
      title: "Chi nhánh",
      dataIndex: "branch",
      key: "branch",
      ...getColumnSearchProps("branch", "contains", "text"),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address", "contains", "text"),
    },
  ];

  return (
    <Box background={globalStyles.colors.bg_color[200]} borderRadius="6px">
      <Flex py="3" justifyContent="space-between" align="center">
        <Text fontWeight={600} color={"#202027"}>
          Danh sách cơ sở đào tạo
        </Text>
        <Button
          colorScheme="whatsapp"
          backgroundColor={"#5FAD67"}
          borderRadius="6px"
          onClick={() => setVisibleModal(true)}
        >
          Thêm mới
        </Button>
      </Flex>
      <Table
        columns={columns}
        dataSource={traningFacilities}
        text={"cơ sở đào tạo"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"2400px"}
      />
    </Box>
  );
};

export default View;
