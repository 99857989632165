import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { message, PopconfirmProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthService from "service/Auth/AuthService";
import { setActiveRoute, setBrandText, setLoadingApp } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import FormAddUser from "../UserManager/components/FormAddUser";
import * as Sentry from "@sentry/browser";
import { IUserAdmin } from "service/Auth";

const UserInfor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ID = useMemo(() => window.location.hash.split("/")[3], []);

  const [isDisabled, setIsDisabled] = useState(false);
  const [userInfor, setUsetInfor] = useState<IUserAdmin | undefined>(undefined);

  const onFinish = async (values: any, isRemove?: boolean) => {
    try {
      const { username, ...rest } = values;

      dispatch(setLoadingApp(true));
      const res = !ID
        ? await AuthService.CreateAdmin({
            ...values,
            roles: [values.roles],
          })
        : await AuthService.AuthAdminUpdate({
            ...rest,
            _id: ID,
            roles: !isRemove ? [values.roles] : undefined,
          });
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setUsetInfor(res.data.data);
        !isRemove &&
          message.success(
            !ID
              ? "Tạo mới người dùng thành công"
              : "Chỉnh sửa người dùng thành công"
          );

          if(isRemove) {
            if(values.status === 0) {
              message.success("Vô hiệu hoá tài khoản thành công")
            }else {
              message.success("Kích hoạt tài khoản thành công")
            }
          }

        setIsDisabled(false);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        } else {
          message.error("Có lỗi xảy ra");
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const onHandleGetDetailAccount = async () => {
    try {
      const pageSize = 99999;
      dispatch(setLoadingApp(true));
      let query = `?limit=${pageSize}&_id[eq]=${ID}`;

      // FIXME: change url to fix call API error
      const URL = "/admin";
      const res = await NetWorkService.Get({
        url: URL + query,
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setUsetInfor(respons.data.records[0]);
      } else {
        setUsetInfor(undefined);
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const confirm = (e?: string) => {
    onFinish({ status: e ? 1 : 0 }, true);
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
  };

  useEffect(() => {
    ID && onHandleGetDetailAccount();
    !ID && setIsDisabled(true);
    dispatch(setActiveRoute("/user-management"));
    dispatch(
      setBrandText({
        name: "Quản lý người dùng EduBills",
        link: `${ADMIN}/user-management`,
      })
    );

    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBrandText());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex
        alignItems={"center"}
        cursor="pointer"
        onClick={() => history.push(`${ADMIN}/user-management`)}
        w={"fit-content"}
      >
        <ChevronLeftIcon color={"#045993"} />
        <Text
          color={"#045993"}
          fontSize="15px"
          fontWeight={600}
          textDecorationLine="underline"
        >
          Quay lại
        </Text>
      </Flex>

      <Text my="3" fontWeight={500} fontSize="15px" color="#202027">
        Thông tin chi tiết
      </Text>

      <Text
        my="3"
        fontWeight={300}
        fontSize="13px"
        fontStyle={"italic"}
        color="#000"
        opacity={"0.5"}
      >
        Đây là các thông tin cá nhân của nhân sự Edubills. Các trường đánh dấu
        sao là trường bắt buộc nhập.
      </Text>

      <FormAddUser
        onFinish={onFinish}
        initData={userInfor}
        isDisabled={isDisabled}
        setIsDisabled={setIsDisabled}
        cancel={cancel}
        confirm={confirm}
      />
    </Box>
  );
};

export default UserInfor;
