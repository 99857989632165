import { Box, Text } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import FormUpdateBranch from "./Components/FormUpdateBranch";
import OsaService from "service/OSA/Osa";
import { RootState } from "store";
import { validResponse } from "util/API";
import { CODE_SUCCESS, OSA } from "util/Constant";
import { FormInstance, message } from "antd";
import { IBranch } from "service/OSA/Osa.d";
import { handleThrowError } from "util/general";
import * as Sentry from "@sentry/browser";

const BranchDetal = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;
  const dispatch = useDispatch();
  const branchCode = useMemo(() => window.location.hash.split("/")[3], []);
  const [isEdit, setIsEdit] = useState(false);
  const formRef = React.useRef<FormInstance>(null);

  const [branch, setBranch] = useState<IBranch | undefined>();

  const getDetailBranch = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await OsaService.DETAIL_BRANCH(authInfor.orgCode, branchCode);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setBranch(res.data.data);
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data?.message}`);
          Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const res = await OsaService.UPDATE_BRANCH(
        authInfor.orgCode,
        branchCode,
        values
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        message.success("Cập nhật thành công");
        setBranch(res.data.data);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(res.data.data?.errors);
          Sentry.captureException(res.data.data?.errors);
          // message.error(`${res.data.data?.message}`);
        }
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
      setIsEdit(false);
    }
  };

  useEffect(() => {
    dispatch(setActiveRoute(`/branch-management`));
    if (branch) {
      dispatch(
        setBrandText({
          name: "Quản lý chi nhánh",
          link: `${OSA}/branch-management`,
        })
      );

      const list = [
        {
          name: branch.branchName,
          link: `${OSA}/branch-detail/${branch._id}`,
        },
      ];
      dispatch(setBreadcrumb(list));
    }

    return () => {
      dispatch(setActiveRoute(""));
      // localStorage.clear();
    };
  }, [dispatch, branch]);

  useEffect(() => {
    authInfor?.orgCode && getDetailBranch();
    // eslint-disable-next-line
  }, [authInfor]);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Text
        color={globalStyles.colors.text[202027]}
        pb="20px"
        fontWeight="600"
        fontSize="15px"
      >
        Chi tiết chi nhánh
      </Text>
      <FormUpdateBranch
        onFinish={onFinish}
        branch={branch}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        formRef={formRef}
      />
      <HSeparator mb="20px" mt={5} />
    </Box>
  );
};

export default BranchDetal;
