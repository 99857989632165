import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FormInstance, InputRef } from "antd";
import Table from "components/table";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { IPlainOptionsMap } from "types/general";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import ModalAddNewOrganization from "./Components/ModalAddNewOrganization";
import * as Sentry from "@sentry/browser";

const OrganizationManagement = () => {
  const history = useHistory();
  const formRef = useRef<FormInstance>(null);

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [isVisibleModalAdd, setIsVisibleModalAdd] = useState(false);
  const [orgList, setOrgList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const searchInput = useRef<InputRef>(null);

  const handleNavigate = (id: string) => {
    history.push(`/admin/organization-detail/${id}`);
  };
  const defaultChecked = "";
  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Hoạt động", value: "1", type: "eq" },
    { name: "Không hoạt động", value: "0", type: "eq" },
  ];

  const { handleSetQuery, getColumnSearchProps, getColumnSelectProps } =
    FilterAntdHook({
      searchInput,
      plainOptionsMap,
      searchedColumn,
      setCurrentPage,
      setSearchedColumn,
      defaultChecked,
    });

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      const URL = "/organization";
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setOrgList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setOrgList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  const columnDefs = [
    {
      title: "STT",
      key: "stt",
      dataIndex: "stt",
      width: "4%",
    },
    {
      title: "Tên tổ chức",
      dataIndex: "orgName",
      key: "orgName",
      ...getColumnSearchProps("orgName", "contains", "text"),
      render: (value: any, record: any) => (
        <span
          onClick={() => handleNavigate(record.orgCode)}
          style={{
            cursor: "pointer",
            color: "#045993",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Mã tổ chức",
      dataIndex: "orgCode",
      key: "orgCode",
      width: "10%",
      ...getColumnSearchProps("orgCode", "contains", "text"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "contains", "text"),
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: "10%",
      ...getColumnSearchProps("phone", "contains", "text"),
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address", "contains", "text"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: "5%",
      ...getColumnSelectProps("status"),
      render: (value: any) => {
        return (
          <div>
            <Box>
              {value === 1 ? (
                <Text fontWeight={600} color={"#5FAD67"}>
                  Hoạt động
                </Text>
              ) : (
                <Text fontWeight={600} color={"#F3C262"}>
                  Không hoạt động
                </Text>
              )}
            </Box>
          </div>
        );
      },
    },
  ];

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text style={{ color: "#202027", fontWeight: "bold" }}>
          {"Danh sách tổ chức"}
        </Text>
        <Button
          colorScheme="whatsapp"
          backgroundColor={"#5FAD67"}
          borderRadius="6px"
          onClick={() => setIsVisibleModalAdd(true)}
        >
          Thêm mới
        </Button>
      </Flex>
      <Table
        columns={columnDefs}
        dataSource={orgList}
        text={"tổ chức"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
      />
      <ModalAddNewOrganization
        isOpen={isVisibleModalAdd}
        onClose={() => setIsVisibleModalAdd(false)}
        formRef={formRef}
      />
    </Box>
  );
};

export default OrganizationManagement;
