import { Box, Flex } from "@chakra-ui/react";
import { Form, FormInstance, Input, Select } from "antd";
import MainButton from "components/button";
import ButtonFooter from "components/ButtonFooter";
import React from "react";
import { InputSchoolCreate } from "service/Auth";
import { PATTERN_PHONE } from "util/Constant";
const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
  formRef: React.MutableRefObject<FormInstance<any>>;
  schoolCreate: InputSchoolCreate | undefined;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
}

const FormLoginAccountShool = ({
  onFinish,
  formRef,
  schoolCreate,
  setIsEdit,
  isEdit,
}: IProps) => {
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Box w="80%" maxW="755px" mt="3">
        <Form.Item
          name="username"
          label="Tên tài khoản"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng nhập tên tài khoản!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Tên tài khoản"
            disabled={!!schoolCreate}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Họ và Tên"
          name="fullName"
          rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Họ và tên"
            disabled={schoolCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Vui lòng nhập Email!" },
            { type: "email", message: "Vui lòng nhập đúng định dạng email!" },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Email"
            disabled={schoolCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              pattern: PATTERN_PHONE,
              message: "Vui lòng nhập đúng định dạng số điện thoại!",
            },
            {
              required: true,
              message: "Vui lòng nhập số điện thoại!",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Số điện thoại"
            disabled={schoolCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Địa chỉ"
          name="address"
          rules={[{ required: true, message: "Vui lòng nhập địa chỉ!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Địa chỉ"
            disabled={schoolCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Quyền"
          name="roles"
          rules={[{ required: true, message: "Vui lòng chọn Quyền!" }]}
        >
          <Select
            className="w-full custom-input"
            disabled={schoolCreate ? !isEdit : false}
          >
            <Option value="APPROVE_UPDATE_REFUND_VNP">Quản lý</Option>
            <Option value="APPROVE_VIEW">Nhân viên</Option>
          </Select>
        </Form.Item>
        {schoolCreate && (
          <Form.Item
            labelAlign="left"
            label="Trạng thái"
            name="status"
            rules={[{ required: false, message: "Vui lòng nhập trạng thái!" }]}
          >
            <Select
              className="w-full custom-input"
              disabled={schoolCreate ? !isEdit : false}
            >
              <Option value="1">Hoạt động</Option>
              <Option value="0">Không hoạt động</Option>
            </Select>
          </Form.Item>
        )}

        {schoolCreate ? (
          <ButtonFooter
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            text2={"Cập nhật"}
            formRef={formRef}
          />
        ) : (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <MainButton htmlType="submit" text="Xác nhận" />
          </Flex>
        )}
      </Box>
    </Form>
  );
};

export default FormLoginAccountShool;
