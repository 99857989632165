import { Box, Flex } from "@chakra-ui/react";
import { DatePicker, Select, Input, Switch } from "antd";
import dayjs from "dayjs";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  ClientCode,
  IidSHB,
  ITransactionNavigation,
  ItypeFee,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";
import { STATUS_ACTIVE, STATUS_INACTIVE } from "util/Constant";

interface P {
  index: number;
  prioritys: number[];
  listTID: IidSHB[];
  typeFee: ItypeFee[];
  item: ITransactionNavigation;
  paymentMethod: PaymentMethodDetail;
  onChangeValue: (
    index: number,
    name: string,
    values: string | string[] | number
  ) => void;
  isDefaultItem: boolean;
  listClientCode: ClientCode[];
}

const { Option } = Select;

const RenderRowInput = ({
  item,
  index,
  listTID,
  typeFee,
  prioritys,
  isDefaultItem,
  paymentMethod,
  onChangeValue,
  listClientCode,
}: P) => {
  const { RangePicker } = DatePicker;
  const [hiddenSign, setHiddenSign] = useState(false);
  const [hiddenSignTransaction, setHiddenSignTransaction] = useState(false);

  const isDateInRange = useMemo(
    () =>
      dayjs(item.effectiveToDate).format("DD/MM/YYYY") >=
        dayjs().format("DD/MM/YYYY") &&
      dayjs(item.effectiveFromDate).format("DD/MM/YYYY") <=
        dayjs().format("DD/MM/YYYY"),
    [item]
  );
  const checkActiveStatus = useMemo(
    () =>
      dayjs(item.effectiveToDate).isAfter(dayjs().add(-1, "day")) &&
      item.status === STATUS_ACTIVE,
    [item]
  );
  const [isActive, setIsActive] = useState(checkActiveStatus);

  useEffect(() => {
    onChangeValue(index, "status", isActive ? STATUS_ACTIVE : STATUS_INACTIVE);
    // eslint-disable-next-line
  }, [isActive]);

  const isCheckedStar = useMemo(
    () => item.TID && item.TID.TIDStar && item.TID.TIDStar === 1,
    [item]
  );

  // get payment method

  useEffect(() => {
    onChangeValue(index, "status", isActive ? STATUS_ACTIVE : STATUS_INACTIVE);
    // eslint-disable-next-line
  }, [isActive]);

  const renderPaymentMethod = useCallback(() => {
    const allMethod = { _id: "all", name: "Tất cả" };
    if (paymentMethod) {
      const listFilter = paymentMethod.paymentMethod.filter(
        (item) => item.status === 1
      );
      const listRender = [allMethod, ...listFilter];
      return listRender.map((item) => (
        <Option value={item._id} key={item._id}>
          {item.name}
        </Option>
      ));
    }
    return [];
  }, [paymentMethod]);

  useEffect(() => {
    setHiddenSignTransaction(item.signTransaction !== "all");
    setHiddenSign(item.sign !== "all");
  }, [item]);

  const onChangeStatus = (checked: boolean) => {
    setIsActive(checked);
  };

  return (
    <>
      {/* piority */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e: any) => onChangeValue(index, "priority", e)}
            defaultValue={item.priority}
            disabled={isDefaultItem}
          >
            {prioritys.map((e) => (
              <Select.Option value={e} key={e}>{`${e}`}</Select.Option>
            ))}
          </Select>
        </Box>
      </td>
      {/* configuration */}
      <td>
        <Input
          className="custom-input"
          placeholder="Nhập tên cấu hình"
          onChange={(e) =>
            onChangeValue(index, "configuration", e.target.value)
          }
          defaultValue={
            item.configuration === "default" ? "Mặc định" : item.configuration
          }
          disabled={isDefaultItem}
        />
      </td>
      {/* clientCode */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) => onChangeValue(index, "clientCode", e)}
            defaultValue={
              item.clientCode === "all" ? "Tất cả" : item.clientCode
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            {listClientCode
              ?.filter((item) => item._id !== "all")
              ?.map((e, i) => (
                <Select.Option value={e._id} key={i}>
                  {e.name === "s5wXjKJzrZ0"
                    ? "EDUBILL"
                    : e.name === "cx615qhsFTL"
                    ? "EDUSOFT"
                    : e.name}
                </Select.Option>
              ))}
          </Select>
        </Box>
      </td>
      {/* groupStudent*/}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) => onChangeValue(index, "groupStudent", e)}
            defaultValue={
              item.groupStudent === "all" ? "Tất cả" : item.groupStudent
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
          </Select>
        </Box>
      </td>
      {/* transactionCount */}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Select
            className="w-full custom-input mw-100"
            onChange={(e) => {
              onChangeValue(index, "sign", e);
              setHiddenSign(e !== "all");
            }}
            defaultValue={item.sign === "all" ? "Tất cả" : item.sign || "all"}
            disabled={isDefaultItem || (isDefaultItem && !isCheckedStar)}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            <Select.Option value={">"}>{">"}</Select.Option>
            <Select.Option value={"<"}>{"<"}</Select.Option>
            <Select.Option value={"="}>{"="}</Select.Option>
            <Select.Option value={">="}>{">="}</Select.Option>
            <Select.Option value={"<="}>{"<="}</Select.Option>
          </Select>
          {hiddenSign && (
            <Input
              className="custom-input"
              placeholder="Nhập số giao dịch tích lũy"
              onChange={(e) =>
                onChangeValue(index, "transactionCount", e.target.value)
              }
              defaultValue={item.transactionCount}
              disabled={isDefaultItem || (isDefaultItem && !isCheckedStar)}
              type="number"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
            />
          )}
        </Flex>
      </td>
      {/* paymentMethod */}
      <td>
        <Select
          className="w-full custom-input"
          onChange={(e) => onChangeValue(index, "paymentMethod", e)}
          defaultValue={
            item.paymentMethod === "all" ? "Tất cả" : item.paymentMethod
          }
          disabled={isDefaultItem}
        >
          {renderPaymentMethod()}
        </Select>
      </td>
      {/* typeFee */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) => onChangeValue(index, "typeFee", e)}
            defaultValue={item.typeFee === "all" ? "Tất cả" : item.typeFee}
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            {typeFee?.map((e, i) => (
              <Select.Option value={e.typeFeeCode} key={i}>
                {e.typeFeeName}
              </Select.Option>
            ))}
          </Select>
        </Box>
      </td>
      {/* transactionValue */}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Select
            className="w-full custom-input mw-100"
            onChange={(e) => {
              onChangeValue(index, "signTransaction", e);
              setHiddenSignTransaction(e !== "all");
            }}
            defaultValue={
              item.signTransaction === "all" ? "Tất cả" : item.signTransaction
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            <Select.Option value={">"}>{">"}</Select.Option>
            <Select.Option value={"<"}>{"<"}</Select.Option>
            <Select.Option value={"="}>{"="}</Select.Option>
            <Select.Option value={">="}>{">="}</Select.Option>
            <Select.Option value={"<="}>{"<="}</Select.Option>
          </Select>
          {hiddenSignTransaction && (
            <Input
              className="custom-input"
              placeholder="Nhập giá trị giao dịch"
              onChange={(e) =>
                onChangeValue(index, "transactionValue", e.target.value)
              }
              defaultValue={item.transactionValue}
              disabled={isDefaultItem}
              type="number"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
            />
          )}
        </Flex>
      </td>
      {/* TID */}
      <td>
        <Box p="3" pl="0">
          {listTID.length >= 1 ? (
            <Select
              className="w-full custom-input"
              onChange={(e) => onChangeValue(index, "TIDId", e)}
              defaultValue={item.TID?._id}
            >
              {listTID.map((e, i) => (
                <Select.Option value={e._id} key={i}>
                  {e.TIDName}
                </Select.Option>
              ))}
            </Select>
          ) : (
            listTID[0]?.TIDName
          )}
        </Box>
      </td>

      {/* effectiveDate */}
      <td>
        <Box p="3" pl="0">
          <RangePicker
            className="w-full custom-input mw-250"
            placeholder={["Từ ngày", "Đến ngày"]}
            format={"YYYY/MM/DD"}
            onChange={(_, dateStrings: string[]) => {
              const isActiveSwitch =
                dateStrings.join("").length > 0 &&
                dayjs(dateStrings[1]).isAfter(dayjs().add(-1, "day"));
              setIsActive(isActiveSwitch);
              onChangeValue(index, "effectiveDate", dateStrings);
            }}
            defaultValue={[
              dayjs(item.effectiveFromDate),
              dayjs(item.effectiveToDate),
            ]}
            disabled={[isDefaultItem, isDefaultItem]}
          />
        </Box>
      </td>
      {/* status */}
      <td>
        <Switch
          checked={isDefaultItem || isActive}
          disabled={isDefaultItem || !isDateInRange}
          onChange={onChangeStatus}
        />
      </td>
    </>
  );
};

export default RenderRowInput;
