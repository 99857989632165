import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import React from "react";
import { globalStyles } from "theme/styles";
import FormEditUser from "./FormEditUser";

interface p {
  onClose: () => void;
  isOpen: boolean;
}

const ModalUserManagement = ({ onClose, isOpen }: p) => {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent borderRadius="6px" className="ModalContent">
        <ModalHeader
          className="modal-header"
          p="16px"
          fontSize="18px"
          fontWeight="500"
          color={globalStyles.colors.text["4A4A4A"]}
        >
          Quản lý người dùng Edubills
        </ModalHeader>

        <ModalCloseButton
          className="fill-color"
          color={globalStyles.colors.text.danger}
          fontSize="18px"
        />
        <HSeparator mb="20px" />
        <ModalBody>
          <FormEditUser onFinish={onFinish} onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserManagement;
