import { Box } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { globalStyles } from "theme/styles";
import TabsPanel from "components/TabsPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import FormLoginAccount from "./Components/LoginAccount/FormLoginAccount";
import { validResponse } from "util/API";
import { message } from "antd";
import AuthService from "service/Auth/AuthService";
import { RootState } from "store";
import { InputOrganizationCreate } from "service/Auth";
import { handleGetOrganztionDetail, handleThrowError } from "util/general";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import * as Sentry from "@sentry/browser";

const LoginAccount = () => {
  const dispatch = useDispatch();

  const orgCode = useMemo(() => window.location.hash.split("/")[3], []);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;

  const [organizationCreate, setOrganizationCreate] = useState<
    InputOrganizationCreate | undefined
  >(undefined);

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const orgCode = detailOrganization.orgCode;
      const newData = {
        ...values,
        orgCode: detailOrganization.orgCode,
        status: values.status === "Hoạt động" ? 1 : 0,
      };

      const { status, ...rest } = newData;

      const res = !organizationCreate
        ? await AuthService.AuthCreateOrganization(orgCode, rest)
        : await AuthService.UpdateOsa(orgCode, organizationCreate._id, newData);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        onHandleGetDetailAccount();

        message.success(
          !organizationCreate
            ? "Tạo mới tài khoản tổ chức thành công"
            : "Chỉnh sửa tài khoản tổ chức thành công"
        );
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
          // message.error(`${res.data.data?.message}`);
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const onHandleGetDetailAccount = async () => {
    try {
      const query = `orgCode[eq]=${orgCode}`;
      dispatch(setLoadingApp(true));
      const res = await AuthService.AuthGetCreateOrganization(query);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        res.data.data?.records[0] &&
          setOrganizationCreate(res.data.data.records[0]);
      } else {
        message.error("Có lỗi xảy ra !");
        Sentry.captureMessage("Có lỗi xảy ra !");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    !detailOrganization &&
      handleGetOrganztionDetail(
        dispatch,
        orgCode,
        OrganizationAdminService.OrganizationDetail
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detailOrganization) {
      const list = [
        {
          name: detailOrganization.orgName,
          link: `${ADMIN}/add-new-organization/${orgCode}`,
        },
        {
          name: "Tài khoản đăng nhập",
          link: `${ADMIN}/training-facilities/${orgCode}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );
      dispatch(setBreadcrumb(list));
    }
    onHandleGetDetailAccount();
    // eslint-disable-next-line
  }, [dispatch, detailOrganization, orgCode]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={2} />
      <Box mt="5">
        <FormLoginAccount
          onFinish={onFinish}
          organizationCreate={organizationCreate}
        />
      </Box>
    </Box>
  );
};

export default LoginAccount;
