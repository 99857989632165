import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, message } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import {
  ClientCode,
  ITransactionNavigation,
  ItypeFee,
  PaymentMethodDetail,
  PaymentPartnerDetail,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { CODE_SUCCESS, STATUS_ACTIVE, STATUS_INACTIVE } from "util/Constant";
import { handleThrowError } from "util/general";
import { ITID } from "../..";
import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";
import * as Sentry from "@sentry/browser";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";

interface IProps {
  listTID: ITID[];
  typeFee: ItypeFee[];
  transactionNavigation: ITransactionNavigation[];
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
  setTransactionNavigation: React.Dispatch<
    React.SetStateAction<ITransactionNavigation[]>
  >;
  listClientCode: ClientCode[];
}

const TransactionNavigation = ({
  listTID,
  typeFee,
  paymentPartnerDetail,
  transactionNavigation,
  setTransactionNavigation,
  handleGetDetailPaymentParner,
  listClientCode,
}: IProps) => {
  const [cloneTransactionNavigation, setCloneTransactionNavigation] = useState<
    ITransactionNavigation[]
  >([]);
  const [isEdit, setIsEdit] = useState(false);
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);
  const [indexEdit, setIndexEdit] = useState(0);
  const [prioritys, setPrioritys] = useState<Array<number>>([]);
  const [idRowEdit, setIdRowEdit] = useState("");

  const {
    PaymentVNPayPartnerUpdate,
    PaymentVNPayPartnerCreate,
    PaymentVNPayPartnerDelete,
  } = OrganizationAdminService;

  const handleEditRow = (index: number, _id: string) => {
    setIsEdit(true);
    let currenIndex = cloneTransactionNavigation[index];
    currenIndex = { ...currenIndex, isEdit: true };

    setIndexEdit(index);
    setIdRowEdit(_id);
    setCloneTransactionNavigation([
      ...cloneTransactionNavigation.slice(0, index),
      currenIndex,
      ...cloneTransactionNavigation.slice(index + 1),
    ]);
  };

  const onChangeValue = (
    index: number,
    name: string,
    values: string | string[] | number
  ) => {
    let arrClone = [...cloneTransactionNavigation];
    let currenIndex = arrClone[index];
    if (name === "effectiveDate" && Array.isArray(values)) {
      const effectiveFromDate = dayjs(values[0]).valueOf();
      const effectiveToDate = dayjs(values[1]).valueOf();
      currenIndex = {
        ...currenIndex,
        effectiveFromDate,
        effectiveToDate,
        status: dayjs(effectiveToDate).isAfter(dayjs().add(-1, "day"))
          ? STATUS_ACTIVE
          : STATUS_INACTIVE,
      };
    } else if (
      name === "transactionCount" ||
      name === "transactionValue" ||
      name === "priority"
    ) {
      if (name === "priority" && +values) {
        arrClone = handleSetPriority(+values, arrClone, currenIndex);
      }
      currenIndex = { ...currenIndex, [name]: Number(values) || undefined };
    } else {
      currenIndex = { ...currenIndex, [name]: values };
    }
    setCloneTransactionNavigation([
      ...arrClone.slice(0, index),
      currenIndex,
      ...arrClone.slice(index + 1),
    ]);
  };

  // hàm thay đổi priority các item trong mảng
  const handleSetPriority = (
    values: number,
    arrClone: ITransactionNavigation[],
    currenIndex: ITransactionNavigation
  ) => {
    const isduplicatePriority = arrClone.findIndex(
      (e) => e.priority === +values
    );

    if (
      isduplicatePriority >= 0 &&
      currenIndex.priority !== arrClone[isduplicatePriority].priority
    ) {
      const duplicatePriority = {
        ...arrClone[isduplicatePriority],
        priority: currenIndex.priority,
      };

      arrClone = [
        ...arrClone.slice(0, isduplicatePriority),
        duplicatePriority,
        ...arrClone.slice(isduplicatePriority + 1),
      ];
    }
    return arrClone;
  };

  const handleRemoveRow = (index: number) => {
    const rowData = cloneTransactionNavigation.find(
      (_, indx) => indx === index
    );
    const newTransactionNavigation = [
      ...cloneTransactionNavigation.slice(0, index),
      ...cloneTransactionNavigation.slice(index + 1),
    ].map((e, i) => ({ ...e, priority: i + 1, TIDId: e.TID._id }));

    setCloneTransactionNavigation(newTransactionNavigation);
    paymentPartnerDetail && onFinishRm(rowData._id);
  };

  const onFinishRm = async (id: string) => {
    try {
      const valuePost: any = {
        _idParner: paymentPartnerDetail._id,
        configType: "transactionNavigation",
        _idConfig: id,
      };

      let response = await PaymentVNPayPartnerDelete(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase(),
        valuePost
      );
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        message.success("Xoá thiết lập điều hướng thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const handleAddRow = () => {
    const newTransactionNavigation = [
      {
        clientCode: "",
        isEdit: true,
        typeFee: "",
        TIDId: listTID.length > 1 ? undefined : listTID[0]?._id,
        configuration: "",
        paymentMethod: "",
        transactionValue: 0,
        sign: "all", // fixed create new
        signTransaction: "all",
        transactionCount: 0,
        groupStudent: "",
        status: 1,
        priority: 1,
        effectiveFromDate: dayjs().valueOf(),
        effectiveToDate: dayjs().valueOf(),
      },
      ...cloneTransactionNavigation,
    ].map((e, i) => ({ ...e, priority: i + 1 }));

    setCloneTransactionNavigation([...newTransactionNavigation]);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setCloneTransactionNavigation([...transactionNavigation]);
  };

  const handleConfirm = () => {
    let err = false;
    let listData = cloneTransactionNavigation.map((e) => ({
      ...e,
      TIDId: e?.TIDId || e?.TID?._id,
      isEdit: false,
    }));

    let currenIndex = listData[indexEdit];
    listData = [
      ...listData.slice(0, indexEdit),
      currenIndex,
      ...listData.slice(indexEdit + 1),
    ].sort((a, b) => a.priority - b.priority);

    listData.forEach((e, index) => {
      if (
        index !== listData.length - 1 &&
        (e.clientCode === "" ||
          e.typeFee === "" ||
          e.paymentMethod === "" ||
          e.sign === "" ||
          e.signTransaction === "" ||
          e.groupStudent === "" ||
          isNaN(e.effectiveFromDate) ||
          isNaN(e.effectiveToDate) ||
          (!e.TID && !e.TIDId))
        // isNaN(e.transactionCount) ||
        // isNaN(e.transactionValue)
      ) {
        err = true;
        console.log(e);
      }
    });
    if (!err) {
      setTransactionNavigation(listData);
      setCloneTransactionNavigation(listData);
      setIsEdit(false);
      paymentPartnerDetail && onFinish(listData);
    } else {
      message.error("Yêu cầu nhập đầy đủ các trường");
      Sentry.captureMessage("Yêu cầu nhập đầy đủ các trường");
    }
  };

  const onFinish = async (arr: ITransactionNavigation[]) => {
    try {
      let lastData = { ...arr[arr.length - 1], default: 1 };

      arr = [...arr.slice(0, arr.length - 1), lastData];

      const valuePost = {
        _idParner: paymentPartnerDetail._id,
        configType: "transactionNavigation",
        transactionNavigation: arr.map((e) => {
          const { isEdit, ...rest } = e;
          delete e.effectiveDate;
          return {
            ...rest,
          };
        }),
      };

      const response = await PaymentVNPayPartnerUpdate(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase(),
        valuePost
      );
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        message.success("Chỉnh sửa điều hướng giao dịch thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;

  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodDetail | undefined
  >(undefined);

  const { PaymentMethodDetail } = OrganizationAdminService;

  const handleGetPaymentMethodDetail = async () => {
    try {
      const res = await PaymentMethodDetail(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setPaymentMethod(res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (schoolDetail) {
      handleGetPaymentMethodDetail();
    }
    // eslint-disable-next-line
  }, [schoolDetail]);

  useEffect(() => {
    setCloneTransactionNavigation(transactionNavigation);
    // eslint-disable-next-line
  }, [transactionNavigation]);

  useEffect(() => {
    if (listTID.length === 1) {
      setTransactionNavigation([
        ...transactionNavigation.map((e) => ({ ...e, TIDId: listTID[0]._id })),
      ]);
    }
    // eslint-disable-next-line
  }, [listTID]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < cloneTransactionNavigation.length - 1; i++) {
      arr.push(i + 1);
    }
    setPrioritys([...arr]);
    // eslint-disable-next-line
  }, [cloneTransactionNavigation]);

  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <Box overflowX={"scroll"}>
        <table className="my-table">
          <thead>
            <tr>
              {!isEdit && (
                <th className="mw-125">
                  <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                    Hành động
                  </Text>
                </th>
              )}
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  STT ưu tiên
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Cấu hình
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Client code
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text
                  color="#A0A6B1"
                  fontSize="15px"
                  fontWeight="500"
                  width={"150px"}
                >
                  Nhóm sinh viên
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Số GD tích lũy
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-335">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  PTTT
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Mã loại thu
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Giá trị GD
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  TID
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              {/* <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Thứ tự ưu tiên
                </Text>
              </th> */}
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Ngày hiệu lực
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Trạng thái
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {cloneTransactionNavigation.map((e, i) => (
              <tr key={i}>
                {e.isEdit ? (
                  <RenderRowInput
                    item={e}
                    index={i}
                    typeFee={typeFee}
                    listTID={listTID}
                    prioritys={prioritys}
                    paymentMethod={paymentMethod}
                    listClientCode={listClientCode}
                    isDefaultItem={i === cloneTransactionNavigation.length - 1}
                    onChangeValue={onChangeValue}
                  />
                ) : (
                  <RenderRow
                    item={e}
                    index={i}
                    isEdit={isEdit}
                    listTID={listTID}
                    typeFee={typeFee}
                    paymentMethod={paymentMethod}
                    handleEditRow={handleEditRow}
                    handleRemoveRow={handleRemoveRow}
                    isDefaultItem={i === cloneTransactionNavigation.length - 1}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box className="max-w-1078">
        {!isEdit && listTID.length !== 0 && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                // remove id edit
                setIdRowEdit("");
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default TransactionNavigation;
