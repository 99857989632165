import { Box, Flex, Text } from "@chakra-ui/react";
import { Switch } from "antd";
import IconEdit from "components/icons/IconEdit";
import IconRemove from "components/icons/IconRemove";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import {
  ITransactionNavigation,
  ItypeFee,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";
import { STATUS_ACTIVE } from "util/Constant";
import { changeValueFieldToAll } from "util/general";
import { ITID } from "../..";

interface P {
  index: number;
  listTID: ITID[];
  isEdit: boolean;
  typeFee: ItypeFee[];
  isDefaultItem: boolean;
  item: ITransactionNavigation;
  paymentMethod: PaymentMethodDetail;
  handleEditRow: (index: number, id: string) => void;
  handleRemoveRow: (index: number) => void;
}

const RenderRow = ({
  item,
  index,
  isEdit,
  typeFee,
  listTID,
  paymentMethod,
  isDefaultItem,
  handleEditRow,
  handleRemoveRow,
}: P) => {
  const checkActiveStatus = useMemo(
    () =>
      dayjs(item.effectiveToDate).isAfter(dayjs().add(-1, "day")) &&
      item.status === STATUS_ACTIVE,
    [item]
  );

  return (
    <>
      {!isEdit && (
        <td>
          <Flex p="5px" pl="0" justifyContent="start" alignItems="center">
            <Box
              cursor="pointer"
              onClick={() => handleEditRow(index, item._id)}
            >
              <IconEdit />
            </Box>
            <Box pl="2" onClick={() => handleRemoveRow(index)} cursor="pointer">
              {!isDefaultItem && <IconRemove />}
            </Box>
          </Flex>
        </td>
      )}
      {/*Thứ tự ưu tiên */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0" align={"center"}>
          {item.priority}
        </Text>
      </td>
      {/* configuration */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(item.configuration)}
        </Text>
      </td>
      {/* clientCode */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {item.clientCode === "s5wXjKJzrZ0" && !isDefaultItem
            ? "EDUBILL"
            : item.clientCode === "cx615qhsFTL" && !isDefaultItem
            ? "EDUSOFT"
            : "Tất cả"}
        </Text>
      </td>
      {/* groupStudent*/}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(item.groupStudent)}
        </Text>
      </td>
      {/* transactionCount*/}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {changeValueFieldToAll(item.sign)}
          </Text>
          {item.sign !== "all" && item.transactionCount !== 0 && (
            <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
              {item.transactionCount}
            </Text>
          )}
        </Flex>
      </td>
      {/* paymentMethod */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {!isDefaultItem
            ? paymentMethod?.paymentMethod
                .filter((item) => item?.status === 1)
                .find((e) => item?.paymentMethod === e?._id)?.name
            : "Tất cả"}
          {/* {changeValueFieldToAll(item.paymentMethod)} */}
        </Text>
      </td>
      {/* feeType */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {!isDefaultItem
            ? typeFee.find((e) => e.typeFeeCode === item.typeFee)
                ?.typeFeeName || changeValueFieldToAll(item.typeFee)
            : "Tất cả"}
        </Text>
      </td>
      {/* transactionValue */}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {changeValueFieldToAll(item.signTransaction)}
          </Text>
          {item.signTransaction !== "all" && item.transactionValue !== 0 && (
            <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
              {item.transactionValue}
            </Text>
          )}
        </Flex>
      </td>
      {/* TID */}
      <td>
        <Text>
          {listTID.find((e) => e._id === item.TIDId)?.TIDName
            ? listTID.find((e) => e._id === item?.TIDId)?.TIDName
            : item?.TID?._id
            ? listTID.find((e) => e._id === item.TID._id)?.TIDName
            : listTID[0]?.TIDName}
        </Text>
      </td>
      {/* effectiveDate */}
      <td>
        <Flex p="3" pl="0" gap={5}>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {dayjs(item.effectiveFromDate).format("DD/MM/YYYY")}
          </Text>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {dayjs(item.effectiveToDate).format("DD/MM/YYYY")}
          </Text>
        </Flex>
      </td>
      {/* status */}
      <td>
        <Switch checked={isDefaultItem || checkActiveStatus} disabled />
      </td>
    </>
  );
};

export default RenderRow;
