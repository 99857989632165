import { Box } from "@chakra-ui/react";
import { Input } from "antd";
import React from "react";
import { PaymentConfigPartnerONEPAY } from "service/OrganizationAdmin/Organization";

interface P {
  item: PaymentConfigPartnerONEPAY;
  index: number;
  onChangeValue: (
    index: number,
    key: string,
    values: string,
    type: "nested" | "plane"
  ) => void;
}

const RenderRowInput = ({ item, index, onChangeValue }: P) => {
  return (
    <>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập tên tk"
            onChange={(e) =>
              onChangeValue(index, "configName", e.target.value, "plane")
            }
            defaultValue={item.configName}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập Merchant ID"
            onChange={(e) =>
              onChangeValue(index, "merchantId", e.target.value, "nested")
            }
            defaultValue={item.extraData.merchantId}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập Access code"
            onChange={(e) =>
              onChangeValue(index, "accessCode", e.target.value, "nested")
            }
            defaultValue={item.extraData.accessCode}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập Hash Key"
            onChange={(e) =>
              onChangeValue(index, "hashKey", e.target.value, "nested")
            }
            defaultValue={item.extraData.hashKey}
          />
        </Box>
      </td>
      {/* <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập ghi chú"
            onChange={(e) => onChangeValue(index, "note", e.target.value)}
            defaultValue={item.note}
          />
        </Box>
      </td> */}
      <td>
        {/* <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập ghi chú"
            onChange={(e) => onChangeValue(index, "desc", e.target.value)}
            defaultValue={item.desc}
          />
        </Box> */}
      </td>
    </>
  );
};

export default RenderRowInput;
