import { Flex } from "@chakra-ui/react";
import { Radio, RadioChangeEvent } from "antd";
import React, { useEffect, useState } from "react";
import { IPlainOptionsMap } from "types/general";

interface IProps {
  defaultChecked: string;
  plainOptionsMap: IPlainOptionsMap[];
  dataIndex: string;
  handleSearch: (args: any) => Promise<void>;
}

const FilterStatus = ({
  defaultChecked,
  dataIndex,
  handleSearch,
  plainOptionsMap,
}: IProps) => {
  const [value, setValue] = useState(defaultChecked);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    handleSearch({
      value: e.target.value,
      key: dataIndex,
      type: plainOptionsMap[0].type,
      filterType: "text",
    });
  };

  useEffect(() => {
    setValue(defaultChecked);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Flex flexDirection={"column"}>
        <Radio.Group value={value} onChange={onChange}>
          <Radio value="">Chọn tất cả</Radio>
          <Flex flexDirection={"column"}>
            {plainOptionsMap.map((e) => (
              <Radio value={e.value} key={e.value}>
                {e.name}
              </Radio>
            ))}
          </Flex>
        </Radio.Group>
      </Flex>
    </>
  );
};

export default FilterStatus;
