/* eslint-disable */
// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image,
  Spacer,
  Text,
  Link,
} from "@chakra-ui/react";
import { Popover } from "antd";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import routes from "routes";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { EnumPermission, TOKEN } from "util/Constant";
import { setAppPermisson, setAuthInfor } from "store/appSlice";
import { deleteCookie } from "util/general";

export default function AdminNavbar(props: {
  secondary: boolean;
  message: string | boolean;
  brandText: { name: string; url: string };
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor, breadcrumb, appPermission } = appState;

  const { secondary, brandText } = props;

  const colorText = useMemo(() => {
    switch (appPermission) {
      case EnumPermission.ADMIN:
        return globalStyles.colors.permission.ADMIN;
      case EnumPermission.CSDT:
        return globalStyles.colors.permission.CSDT;
      case EnumPermission.OSA:
        return globalStyles.colors.permission.OSA;

      default:
        return globalStyles.colors.permission.ADMIN;
    }
  }, [appPermission]);

  const RenderContent = () => {
    return (
      <Link
        href="#/auth/login"
        ps="20px"
        pe={{ lg: "16px", "2xl": "20px" }}
        mt="60px"
        mb="40px"
        borderRadius="30px"
        cursor="pointer"
        onClick={() => {
          dispatch(setAppPermisson(EnumPermission.ADMIN));
          dispatch(setAuthInfor(undefined));
          deleteCookie(TOKEN);
        }}
      >
        <Text
          className="btn-logout"
          textAlign={"start"}
          display={"flex"}
          justifyContent="start"
          flexDirection={"row"}
          alignItems="start"
          align="start"
        >
          <Box mr="3" textAlign={"left"} className="text-left">
            <i className="fa-solid fa-right-from-bracket btn-logout"></i>
          </Box>{" "}
          Đăng xuất
        </Text>
      </Link>
    );
  };

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = globalStyles.colors.bg_color[100];
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  return (
    <Box
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      m="0"
      paddingInlineStart="10px"
      className="custom-nav"
    >
      <Flex w="100%" alignItems={{ xl: "center" }}>
        <Box pr="1" mt="0.4">
          <SidebarResponsive routes={routes} />
        </Box>
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            <BreadcrumbItem color={globalStyles.colors.text[202027]}>
              <BreadcrumbLink
                href={`#${
                  breadcrumb.length !== 0
                    ? brandText.url
                    : `${appPermission?.toLocaleLowerCase() + brandText.url}`
                }`}
                color={colorText}
                fontWeight="600"
                fontSize="15px"
              >
                {brandText.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
            {breadcrumb.map((e, i) => (
              <BreadcrumbItem color={globalStyles.colors.text[202027]} key={i}>
                <BreadcrumbLink
                  href={`#${e.link}`}
                  color={
                    breadcrumb.length - 1 !== i
                      ? colorText
                      : globalStyles.colors.text[202027]
                  }
                  fontWeight="600"
                  fontSize="15px"
                >
                  {e.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </Box>
        <Spacer />

        {/* <Box className="switch-custom" mr="2">
          <Switch
            className={!env && "bg-045993"}
            checkedChildren="GOLIVE"
            unCheckedChildren="UAT"
            defaultChecked
            onChange={() => setEnv(!env)}
          />
        </Box> */}
        <Popover content={<RenderContent />} trigger="click">
          <button className="not-button">
            <Box display={"flex"} cursor="pointer">
              <Image
                borderRadius="full"
                boxSize="43px"
                src={require("../../assets/img/avatars/avatar.png")}
                alt="Avvatar"
              />
              <Box display={"flex"} flexDirection="column">
                <Text pl="2" className="text-18">
                  {authInfor?.fullName}
                </Text>
                <Text pl="2" className="text-12">
                  {authInfor?.accountType}
                </Text>
              </Box>
            </Box>
          </button>
        </Popover>
      </Flex>
    </Box>
  );
}
