import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, message } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import React, { useEffect, useMemo, useState } from "react";
import { PaymentPartnerDetail } from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import { ITID } from "../..";
import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";
import * as Sentry from "@sentry/browser";

interface IProps {
  listTID: ITID[];
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
  setListTID: React.Dispatch<React.SetStateAction<ITID[]>>;
}

const SetUpID = ({
  listTID,
  setListTID,
  paymentPartnerDetail,
  handleGetDetailPaymentParner,
}: IProps) => {
  const [cloneListTID, setCloneListTID] = useState<ITID[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [idRowEdit, setIdRowEdit] = useState("");
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);

  const {
    PaymentVNPayPartnerUpdate,
    PaymentVNPayPartnerCreate,
    PaymentVNPayPartnerDelete,
  } = OrganizationAdminService;

  const handleEditRow = (index: number, _id: string) => {
    setIsEdit(true);
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, isEdit: true };
    setIdRowEdit(_id);
    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const onChangeValue = (index: number, key: string, values: string) => {
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, [key]: values };

    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const handleRemoveRow = (index: number) => {
    const rowData = cloneListTID.find((_, indx) => indx === index);
    const newArr = [
      ...cloneListTID.slice(0, index),
      ...cloneListTID.slice(index + 1),
    ];

    setCloneListTID(newArr);
    setListTID(newArr);
    paymentPartnerDetail && onFinishRm(rowData._id);
  };

  const onFinishRm = async (id: string) => {
    try {
      const valuePost: any = {
        _idParner: paymentPartnerDetail._id,
        configType: "TID",
        _idConfig: id,
      };

      let response = await PaymentVNPayPartnerDelete(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase(),
        valuePost
      );
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        message.success("Xoá TID thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const uuidv4 = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  };

  const handleAddRow = () => {
    setCloneListTID([
      ...cloneListTID,
      {
        TIDName: "",
        tmnCode: "",
        tmnHashSecret: "",
        bankAccountNumber: "",
        note: "",
        isEdit: true,
        _id: `${uuidv4()}`,
        TIDStar: 0,
      },
    ]);
  };
  const handleConfirm = () => {
    let err = false;
    const listData = cloneListTID.map((e) => ({ ...e, isEdit: false }));
    listData.forEach((e: any) => {
      Object.keys(e).forEach((el: any) => {
        if (e[el] === "" && el !== "note") {
          err = true;
        }
      });
    });

    if (!err) {
      setListTID(listData);
      setCloneListTID(listData);
      setIsEdit(false);
      let rowData;
      if (idRowEdit) {
        // update
        rowData = listData.find((e) => e._id === idRowEdit);
      } else {
        // create get last arr
        rowData = listData?.[listData.length - 1];
      }
      paymentPartnerDetail && onFinish(rowData, idRowEdit);
    } else {
      message.error("Yêu cầu nhập đầy đủ các trường");
      Sentry.captureMessage("Yêu cầu nhập đầy đủ các trường");
    }
  };

  const onFinish = async (rowEdit: ITID, idRowEdit: string) => {
    try {
      // todo create global config type
      const valuePost: any = {
        _idParner: paymentPartnerDetail._id,
        configType: "TID",
        TID: [rowEdit].map((e) => {
          const { isEdit, ...rest } = e;
          return { ...rest, TIDStar: e.TIDStar || 0 };
        }),
      };

      let response: any;
      if (idRowEdit) {
        // update
        valuePost["_idConfig"] = rowEdit._id;
        response = await PaymentVNPayPartnerUpdate(
          orgCode,
          schoolCode,
          ID.toLocaleUpperCase(),
          valuePost
        );
      } else {
        response = await PaymentVNPayPartnerCreate(
          orgCode,
          schoolCode,
          ID.toLocaleUpperCase(),
          valuePost
        );
      }

      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        idRowEdit
          ? message.success("Chỉnh sửa TID thành công")
          : message.success("Thêm mới TID thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    setCloneListTID(listTID);
    // eslint-disable-next-line
  }, [listTID]);

  const handleCancel = () => {
    setIsEdit(false);
    setCloneListTID([...listTID]);
  };

  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <table className="my-table w-90 max-w-1078">
        <thead>
          <tr>
            <th style={{ width: "15%" }}>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Tên tài khoản <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Vnp_TmnCode (TID Code){" "}
                <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Vnp_HashSecret <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Số tài khoản thụ hưởng{" "}
                <span style={{ color: "#FF2323" }}>*</span>
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Ghi chú
              </Text>
            </th>
            <th>
              <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                Hành động
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {cloneListTID.map((e, i) => (
            <tr key={i} className="tr-tid">
              {e.isEdit ? (
                <RenderRowInput
                  item={e}
                  onChangeValue={onChangeValue}
                  index={i}
                />
              ) : (
                <RenderRow
                  item={e}
                  handleRemoveRow={handleRemoveRow}
                  index={i}
                  handleEditRow={handleEditRow}
                  isEdit={isEdit}
                  paymentPartnerDetail={paymentPartnerDetail}
                  handleGetDetailPaymentParner={handleGetDetailPaymentParner}
                />
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Box className="w-90 max-w-1078">
        {!isEdit && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                // remove id edit
                setIdRowEdit("");
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SetUpID;
