import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, FormInstance, message } from "antd";
import MainButton from "components/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IBill } from "service/Bill/Bill";
import { RootState } from "store";
import { setActiveRoute, setLoadingApp, Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { IRefundHistory, IStudentInfor } from "types/general";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS, CSDT } from "util/Constant";
import { handleThrowError } from "util/general";
import FormRefund from "./Components/FormRefund";
import ListReceipts from "./Components/ListReceipts";
import Search from "./Components/Search";
import * as Sentry from "@sentry/browser";

export interface IBILL extends IBill {
  disable: boolean;
  bills?: any[];
}

const RefundManagement = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);

  const { authInfor } = appState;
  const color = globalStyles.colors.permission.CSDT;
  const formRef = React.useRef<FormInstance>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [studentCode, setStudentCode] = useState("");
  const [receipt, setReceipt] = useState<IBill[]>([]);
  const [bills, setBills] = useState<IBILL[]>([]);
  const [selectedRows, setSelectedRows] = useState<IBill[]>([]);
  const [refundHistorys, setRefundHistorys] = useState<IRefundHistory[]>([]);
  const [studentInfor, setStudentInfor] = useState<IStudentInfor | undefined>(
    undefined
  );

  const onFinish = async () => {
    try {
      formRef.current.validateFields();
      dispatch(setLoadingApp(true));

      const formValue = {
        orderId: formRef.current.getFieldValue("orderId"),
        reason: formRef.current.getFieldValue("reason"),
        transType: formRef.current.getFieldValue("transType"),
        amount: formRef.current.getFieldValue("amount"),
      };

      if (selectedRows.length === 0) {
        message.error("Không thể tạo mới giao dịch. Vui lòng chọn phiếu thu");
        Sentry.captureMessage(
          "Không thể tạo mới giao dịch. Vui lòng chọn phiếu thu"
        );
        return false;
      }

      if (formValue.amount > selectedRows[0].amount) {
        message.error("Giá trị hoàn không được lớn hơn giá trị của đơn hàng");
        return false;
      }

      if (!formValue.orderId || !formValue.reason || !formValue.amount) {
        return false;
      } else {
        const body = {
          user: studentInfor.HoTen,
          orderId: formValue.orderId,
          reason: formValue.reason,
          transType: formValue.transType,
          amount: formValue.amount,
        };
        const URL = `/organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/payment/refund`;
        // const URL = `/organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/refund-history`;

        const res = await NetWorkService.POST_URL_PAYMENT({
          url: URL,
          body,
        });
        if (res && validResponse(res) && res.code === CODE_SUCCESS) {
          message.success("Tạo thành công");
          handleGetRefundHistory();
          setSelectedRows([]);
        } else {
          if (res && validResponse(res)) {
            handleThrowError(res.data.data?.errors);
            Sentry.captureException(res.data.data?.errors);
          }
        }
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: IBILL[]) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: IBILL) => ({
      disabled: record.disable, // Column configuration not to be checked
    }),
  };

  const handleSearchInfor = async () => {
    handleSearchStudentInfor();
    handleSearchReceipts();
  };

  const handleSearchStudentInfor = async () => {
    try {
      if (!studentCode) {
        // message.error('')
        return false;
      }
      dispatch(setLoadingApp(true));
      setReceipt([]);
      let query = `?MaSV[eq]=${studentCode}`;
      const BILL = `/organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/students`;

      const res = await NetWorkService.Get({
        url: BILL + query,
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.records.length !== 0
      ) {
        const respons = res.data;
        setStudentInfor(respons.data.records[0]);
      } else {
        res &&
          validResponse(res) &&
          message.info("Không tìm thấy thông tin sinh viên");
        setStudentInfor(undefined);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleSearchReceipts = async () => {
    try {
      if (!studentCode) {
        // message.error('')
        return false;
      }
      dispatch(setLoadingApp(true));
      setReceipt([]);

      let query = `?limit=${99999}&createdAt[sort]=desc&status[eq]=1&ma_sv[eq]=${studentCode}`;
      const BILL = `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/payment-history`;
      // const BILL = `/organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/bill`;

      const res = await NetWorkService.Get({
        url: BILL + query,
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.records.length !== 0
      ) {
        const respons = res.data;
        setReceipt(
          respons.data.records.map((e: any) => ({ ...e, key: e._id }))
        );
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  // goi lay danh sach RefundHistory để check không cho tạo trùng phiếu thu
  const handleGetRefundHistory = async () => {
    try {
      dispatch(setLoadingApp(true));
      const pageSize = 999999;
      const BILL = `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/refund-history?limit=${pageSize}&status[eq]=2`;
      const res = await NetWorkService.Get({
        url: BILL,
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const respons = res.data;
        setRefundHistorys(respons.data.records);
      } else {
        setRefundHistorys([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    !studentCode && formRef?.current?.resetFields();
  }, [studentCode]);

  useEffect(() => {
    dispatch(setActiveRoute("/refund-history"));
    authInfor && handleGetRefundHistory();
    // eslint-disable-next-line
  }, [authInfor]);

  // check những phiếu thu đã được tạo sẽ không cho tích chọn => disable
  useEffect(() => {
    if (!!receipt.length) {
      const newBills = receipt.map((e) => {
        if (refundHistorys.find((el) => el.so_phieu_bao === e.so_phieu_bao)) {
          return { ...e, disable: true };
        } else {
          return { ...e, disable: false };
        }
      });
      setBills(newBills);
    }
  }, [refundHistorys, receipt]);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex
        alignItems={"center"}
        cursor="pointer"
        onClick={() => history.push(`${CSDT}/request-refund`)}
        width="fit-content"
      >
        <span>
          <ChevronLeftIcon color={color} className="ChevronLeftIcon" />
        </span>
        <span>
          <Text
            color={color}
            fontSize="15px"
            fontWeight={600}
            textDecorationLine="underline"
          >
            Quay lại
          </Text>
        </span>
      </Flex>
      <Search
        setStudentCode={setStudentCode}
        handleSearchInfor={handleSearchInfor}
      />
      <FormRefund
        formRef={formRef}
        initValue={studentInfor}
        selectedRows={selectedRows}
      />
      <ListReceipts
        dataSource={bills}
        loading={false}
        rowSelection={rowSelection}
      />

      <Flex
        flexDirection={"row"}
        alignContent="center"
        justifyContent="flex-end"
        mt="4"
      >
        <Box mr="2">
          <MainButton
            onClick={onFinish}
            text={"Tạo mới"}
            disabled={!studentInfor}
          />
        </Box>
        <Button
          className="custom-button color-FFA6A6"
          // styles={{ color: "#FFA6A6" }}
          disabled={selectedRows.length === 0}
          style={{
            width: "95px",
          }}
          onClick={() => history.push(`${CSDT}/request-refund`)}
        >
          Hủy
        </Button>
      </Flex>
    </Box>
  );
};

export default RefundManagement;
