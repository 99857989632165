import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveRoute, setBrandText, setBreadcrumb } from "store/appSlice";
import { globalStyles } from "theme/styles";
import ViewReport from "./ViewReport";

const Report = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      position="relative"
    >
      <ViewReport />
    </Box>
  );
};

export default Report;
