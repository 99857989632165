import { Box, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import Table from "components/table";
import moment from "moment";
import React from "react";
import { globalStyles } from "theme/styles";
import { IGroupSudent } from "types/general";

interface IProps {
  totalRow: number;
  loading: boolean;
  pageSize: number;
  currentPage: number;
  groupStudentList: IGroupSudent[];
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains" | "lte",
    filterType: "number" | "text" | "date",
    showTime?: boolean
  ) => any;
}

const View = ({
  loading,
  pageSize,
  totalRow,
  currentPage,
  groupStudentList,
  getColumnDateProps,
  onChangeCurrentPage,
  getColumnSearchProps,
}: IProps) => {
  const columns: ColumnsType<IGroupSudent> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "70px",
    },
    {
      title: "Tên nhóm",
      dataIndex: "groupStudent",
      key: "groupStudent",
      ...getColumnSearchProps(
        "groupStudent.groupStudentName",
        "contains",
        "text"
      ),
      render: (value, _) => {
        return (
          <span
            // onClick={() => handleNavigate(value?.orgCode)}
            style={{
              color: "#045993",
              cursor: "pointer",
            }}
          >
            {value[0]?.groupStudentName}
          </span>
        );
      },
    },
    {
      title: "Mã nhóm",
      dataIndex: "groupStudent",
      key: "groupStudent",
      ...getColumnSearchProps(
        "groupStudent.groupStudentCode",
        "contains",
        "text"
      ),
      render: (value, _) => {
        return <span>{value[0]?.groupStudentCode}</span>;
      },
    },
    {
      title: "Thời gian tạo",
      dataIndex: "createdAt",
      key: "createdAt",
      ...getColumnDateProps("createdAt", "contains", "date"),
      render: (value) => (
        <div>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</div>
      ),
    },
  ];

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text fontWeight={600} color={"#202027"}>
          Danh sách nhóm sinh viên
        </Text>
      </Flex>
      <Table
        columns={columns}
        dataSource={groupStudentList}
        text={"nhóm sinh viên"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"86vw"}
      />
    </Box>
  );
};

export default View;
