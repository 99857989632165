import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import {
  Form,
  Input,
  Select,
  FormInstance,
  message,
  Image,
  Switch,
} from "antd";
import ButtonFooter from "components/ButtonFooter";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SchoolDetail } from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { IBranch } from "service/OSA/Osa.d";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS, PATTERN_PHONE } from "util/Constant";
import { useDebounce } from "util/debounce";
import IconLogo from "./IconLogo";
import * as Sentry from "@sentry/browser";

const { Option } = Select;

interface IProps {
  isSearch: {
    isSearchCCCD: boolean;
    isSearchMaSV: boolean;
  };

  onFinish: (values: any) => void;
  setIsSearch: React.Dispatch<
    React.SetStateAction<{
      isSearchCCCD: boolean;
      isSearchMaSV: boolean;
    }>
  >;
}

const FormBasicInformation = ({ isSearch, onFinish, setIsSearch }: IProps) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const [isEdit, setIsEdit] = useState(false);
  const [linkImg, setLinkImg] = useState<string>("");
  const [initValue, setInitValue] = useState<SchoolDetail | undefined>(
    undefined
  );
  const dispatch = useDispatch();

  const formRef = React.useRef<FormInstance>(null);

  useEffect(() => {
    schoolDetail && setInitValue(schoolDetail);
    schoolDetail && !branch && getDetailBranch();
    // eslint-disable-next-line
  }, [schoolDetail]);

  const handleSetValue = () => {
    formRef?.current?.setFieldsValue({
      schoolName: initValue.schoolName,
      schoolCode: initValue.schoolCode,
      email: initValue.email,
      branch: initValue.branch,
      address: initValue.address,
      status: initValue.status ? "Hoạt động" : "Không hoạt động",
      fax: initValue.fax,
      idSchool: initValue?.idSchool ? initValue?.idSchool : "",
      apiKey: initValue?.apiKey ? initValue?.apiKey : "",
      phone: initValue.phone,
      schoolUrl: initValue.schoolUrl,
      apiKey_edusoft: initValue.apiKey_edusoft,
      avatarUrl: initValue.avatarUrl,
      isSearchCCCD: initValue?.isSearchCCCD,
      isSearchMaSV: initValue?.isSearchMaSV,
    });
  };

  useEffect(() => {
    if (initValue) {
      handleSetValue();
      setLinkImg(initValue?.avatarUrl);
    }
    // eslint-disable-next-line
  }, [initValue]);

  useEffect(() => {
    if (!isEdit && initValue) {
      handleSetValue();
    }
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [schoolDetail]);

  const [branch, setBranch] = useState<IBranch[] | undefined>(undefined);

  const getDetailBranch = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await OrganizationAdminService.DETAIL_BRANCH_LIST(
        schoolDetail.orgCode
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setBranch(res.data.data.records);
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data?.message}`);
          Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleSetValueLinkImg = useDebounce((value: string) => {
    setLinkImg(value);
  }, 1000);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Flex maxW="1100px">
        <Grid templateColumns="repeat(5, 1fr)" gap={5}>
          {initValue && (
            <GridItem colSpan={3}>
              <Flex justifyContent="space-between">
                <Text my="3" fontWeight={500} fontSize="15px" color="#202027">
                  Thông tin chung
                </Text>
                <Box
                  my="3"
                  fontWeight={500}
                  fontSize="15px"
                  color="#fff"
                  borderRadius={4}
                  bg={schoolDetail?.golive ? "#5FAD67" : "#045993"}
                  px={"10px"}
                  py="5px"
                >
                  {schoolDetail?.golive ? "GOLIVE" : "UAT"}
                </Box>
              </Flex>

              <Text
                my="3"
                fontWeight={300}
                fontSize="13px"
                fontStyle={"italic"}
                color="#000"
                opacity={"0.5"}
              >
                Đây là thông tin chung của cơ sở đào tạo sử dụng cổng thanh toán
                AQ Bill Gateway. Các trường đánh dấu sao là các trường bắt buộc
                nhập
              </Text>

              <Form.Item
                name="schoolName"
                label="Tên cơ sở"
                labelAlign="left"
                rules={[
                  { required: true, message: "Vui lòng nhập tên cơ sở!" },
                ]}
              >
                <Input
                  className="custom-input "
                  placeholder="Tên cơ sở"
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="Mã cơ sở"
                name="schoolCode"
                rules={[{ required: true, message: "Vui lòng nhập mã cơ sở!" }]}
              >
                <Input
                  className="custom-input "
                  placeholder="Mã cơ sở"
                  disabled={initValue?.schoolCode ? true : false}
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="ID trường"
                name="idSchool"
                rules={[
                  { required: true, message: "Vui lòng nhập mã id trường!" },
                ]}
              >
                <Input
                  className="custom-input "
                  placeholder="ID trường"
                  disabled={!isEdit}
                  // disabled={initValue?.idSchool ? true : false}
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Vui lòng nhập Email!" },
                  {
                    type: "email",
                    message: "Vui lòng nhập đúng định dạng email!",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Email"
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    pattern: PATTERN_PHONE,
                    message: "Vui lòng nhập đúng định dạng số điện thoại!",
                  },
                  {
                    required: true,
                    message: "Vui lòng nhập số điện thoại!",
                  },
                ]}
              >
                <Input
                  className="custom-input"
                  placeholder="Số điện thoại"
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="Fax"
                name="fax"
                rules={[{ required: false, message: "Vui lòng nhập fax!" }]}
              >
                <Input
                  className="custom-input"
                  placeholder="Fax"
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="Chi nhánh"
                name="branch"
                rules={[
                  { required: false, message: "Vui lòng nhập chi nhánh!" },
                ]}
              >
                <Select
                  className="w-full custom-input"
                  disabled={!isEdit}
                  placeholder="Chọn chi nhánh"
                >
                  {branch?.map((e) => (
                    <Option value={e.branchName} key={e._id}>
                      {e.branchName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                labelAlign="left"
                label="Địa chỉ"
                name="address"
                rules={[{ required: false, message: "Vui lòng nhập địa chỉ!" }]}
              >
                <Input
                  className="custom-input"
                  placeholder="Địa chỉ"
                  disabled={!isEdit}
                />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label="Trạng thái"
                name="status"
                rules={[
                  { required: false, message: "Vui lòng nhập trạng thái!" },
                ]}
              >
                <Select className="w-full custom-input" disabled={!isEdit}>
                  <Option value="1">Hoạt động</Option>
                  <Option value="0">Không hoạt động</Option>
                </Select>
              </Form.Item>
              <Form.Item labelAlign="left" label="API Key" name="apiKey">
                <Input.Password
                  className="custom-input"
                  placeholder="API Key"
                  disabled={!isEdit}
                />
              </Form.Item>
              <Form.Item labelAlign="left" label="API domain" name="schoolUrl">
                <Input.Password
                  className="custom-input"
                  placeholder="API domain"
                  disabled={!isEdit}
                />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label="Secret key"
                name="apiKey_edusoft"
              >
                <Input.Password
                  className="custom-input"
                  placeholder="Secret key"
                  disabled={!isEdit}
                />
              </Form.Item>

              {/* <Box display={"flex"} pl="">
                <Box pr={"10"}>
                  <Form.Item
                    name="isSearchCCCD"
                    label=""
                    valuePropName="checked"
                    initialValue={initValue?.isSearchCCCD}
                  >
                    <span style={{ paddingRight: "8px" }}>Tìm kiếm CCCD:</span>
                    <Switch
                      disabled={!isEdit}
                      onChange={(e) =>
                        setIsSearch({ ...isSearch, isSearchCCCD: e })
                      }
                      defaultChecked={initValue?.isSearchCCCD}
                    />
                  </Form.Item>
                </Box>

                <Form.Item
                  name="isSearchMaSV"
                  label=""
                  valuePropName="checked"
                  // initialValue={initValue?.isSearchMaSV}
                >
                  <span style={{ paddingRight: "8px" }}>Tìm kiếm Mã SV:</span>
                  <Switch
                    disabled={!isEdit}
                    onChange={(e) =>
                      setIsSearch({ ...isSearch, isSearchMaSV: e })
                    }
                    defaultChecked={initValue?.isSearchMaSV}
                    // defaultChecked={true}
                  />
                </Form.Item>
              </Box> */}

              <ButtonFooter
                formRef={formRef}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                text2="Cập nhật"
              />
            </GridItem>
          )}
          <GridItem colSpan={2}>
            <Text my="3" fontWeight={500} fontSize="15px" color="#202027">
              Logo cơ sở đào tạo
            </Text>
            {!linkImg ? <IconLogo /> : <Image src={linkImg} />}
            <Form.Item
              labelAlign="left"
              name="avatarUrl"
              label="Link ảnh"
              className="mt-3"
              rules={[{ required: true, message: "Vui lòng nhập link ảnh!" }]}
            >
              <Input
                onChange={(e) => handleSetValueLinkImg(e.target.value)}
                className="custom-input"
                placeholder="link ảnh"
                disabled={!isEdit}
                // style={{ width: "140px" }}
              />
            </Form.Item>
          </GridItem>
        </Grid>
      </Flex>
    </Form>
  );
};

export default memo(FormBasicInformation);
