import { Box, Text } from "@chakra-ui/react";
import { Form, FormInstance, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TransactionType } from "service/OrganizationAdmin/Organization";
import { formatMoney } from "util/general";
import "./style.css";
const { TextArea } = Input;

interface Props {
  transaction: TransactionType | undefined;
}

const ViewDetailTransaction = ({ transaction }: Props) => {
  const [isDisabled] = useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const handleSetData = () => {
    formRef?.current?.setFieldsValue({
      ...transaction,
      createDate: moment(transaction?.createdAt).format("HH:mm:ss DD/MM/YYYY"),
      amount: formatMoney(transaction?.amount),
      status:
        transaction.status === 1
          ? "Thành công"
          : transaction.status === 2
          ? "Đang chờ xử lý"
          : "Thất bại",
    });
  };

  useEffect(() => {
    transaction && handleSetData();
    // eslint-disable-next-line
  }, [transaction]);

  return (
    <Form
      name="basic"
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
      className="custom-disable-input"
    >
      <Box maxW={"755px"}>
        <Form.Item labelAlign="left" label="Thời gian" name="createDate">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã giao dịch" name="TransactionNo">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã đơn hàng" name="orderId">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Tên cơ sở" name="schoolName">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã cơ sở" name="schoolCode">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã tổ chức" name="orgCode">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã sinh viên" name="ma_sv">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Số phiếu thu" name="so_phieu_bao">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Giá trị (VNĐ)" name="amount">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Đối tác thanh toán"
          name="paymentChannel"
        >
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="PT thanh toán" name="paymentMethod">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Nội dung" name="noi_dung">
          <TextArea className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Trạng thái" name="status">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
      </Box>
    </Form>
  );
};

export default ViewDetailTransaction;
