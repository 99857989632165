import { Box } from "@chakra-ui/react";
import { Form, FormInstance, Input, Select } from "antd";
import ButtonFooter from "components/ButtonFooter";
import React, { useState } from "react";
import { PATTERN_PHONE } from "util/Constant";

interface IProps {
  onFinish: (values: any) => void;
}

const FormStudent = ({ onFinish }: IProps) => {
  const { Option } = Select;

  const formRef = React.useRef<FormInstance>(null);
  const [isEdit, setIsEdit] = useState(false);
  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
      // wrapperCol={{ span: 16 }}
    >
      <Box maxW="755px">
        <Form.Item
          name="orgName"
          label="Tên sinh viên"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng nhập tên sinh viên!" }]}
        >
          <Input
            className="custom-input "
            placeholder="tên sinh viên"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="ID sinh viên"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập id sinh viên!" }]}
        >
          <Input
            className="custom-input "
            placeholder="ID sinh viên"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã sinh viên"
          name="Mã sinh viên"
          rules={[{ required: true, message: "Vui lòng nhập mã sinh viên!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Mã sinh viên"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="CCCD"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập CCCD!" }]}
        >
          <Input
            className="custom-input "
            placeholder="CCCD"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Niên khóa"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập niên khóa!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Niên khóa"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Khoa"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập khoa!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Khoa"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Ngành"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập ngành!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Ngành"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã lớp"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập mã lớp!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Mã lớp"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              pattern: PATTERN_PHONE,
              message: "Vui lòng nhập đúng định dạng số điện thoại!",
            },
            {
              required: true,
              message: "Vui lòng nhập số điện thoại!",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Số điện thoại"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Email"
          name="orgCode"
          rules={[{ required: true, message: "Vui lòng nhập email!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Email"
            disabled={!isEdit}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Nhóm"
          name="status"
          rules={[{ required: false, message: "Vui lòng nhập nhóm!" }]}
        >
          <Select
            placeholder="Nhóm"
            className="w-full custom-input"
            disabled={!isEdit}
          >
            <Option value="1">Hoạt động</Option>
            <Option value="0">Không hoạt động</Option>
          </Select>
        </Form.Item>

        <ButtonFooter
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          text={"Xác nhận"}
          text2="Cập nhật"
          formRef={formRef}
        />
      </Box>
    </Form>
  );
};

export default FormStudent;
