import { Box, Flex, Text } from "@chakra-ui/react";
import { Modal, Spin } from "antd";

import { HSeparator } from "components/separator/Separator";
import React from "react";
import { globalStyles } from "theme/styles";
import Cloes from "views/admin/OrganizationManagement/Icon/Cloes";

interface p {
  onClose: () => void;
  isOpen: boolean;
  hasStripe?: boolean;
  value?: number;
}

const ModalReconciliationProgress = ({
  onClose,
  isOpen,
  hasStripe,
  value,
}: p) => {
  return (
    <Modal
      title="Đối soát giao dịch"
      open={isOpen}
      onCancel={onClose}
      footer={<></>}
      closeIcon={
        <>
          <Cloes />
        </>
      }
    >
      <HSeparator />
      <Box px="16px" py="16px">
        <Flex justifyContent="center" flexDirection="column">
          <Text
            color={globalStyles.colors.text[202027]}
            fontWeight={500}
            fontSize="18px"
            textAlign={"center"}
          >
            Hệ thống đang thực hiện đối soát.
          </Text>
          <Text
            color={globalStyles.colors.text[202027]}
            fontWeight={400}
            fontSize="15px"
            textAlign={"center"}
            my="3"
          >
            Vui lòng đợi trong ít phút
          </Text>
          <Flex mx="auto" w="300px" justifyContent={"center"}>
            {/* <Progress
              backgroundColor={"#e0e0e0"}
              borderRadius={"2px"}
              colorScheme="green"
              hasStripe={hasStripe}
              value={80}
              mx="2"
              w={"100%"}
              mb="3"
            /> */}
            <Spin />
          </Flex>

          {/* <MainButton text="Tải báo cáo" icon={<IconDownload />} /> */}
        </Flex>
      </Box>
    </Modal>
  );
};

export default ModalReconciliationProgress;
