import { Box, Flex, Text } from "@chakra-ui/react";
import { ColumnsType } from "antd/es/table";
import HeaderFilter from "components/headerFilter";
import IconReload from "components/icons/IconReload";
import Table from "components/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { ADMIN, CSDT, EnumPermission } from "util/Constant";

interface IProps {
  billList: any[];
  totalRow: number;
  pageSize: number;
  currentPage: number;
  loading: boolean;
  onChangeCurrentPage: (e: any) => void;
  handleSearch: (args: any) => Promise<void>;
  handleRetryBrick: (
    orgCode: string,
    schoolCode: string,
    id: string
  ) => Promise<void>;
  getColumnSelectProps: (dataIndex: any) => any;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains" | "lte",
    filterType: "number" | "text" | "date",
    showTime?: boolean
  ) => any;
}

const TableManager = ({
  loading,
  billList,
  pageSize,
  totalRow,
  currentPage,
  handleSearch,
  handleRetryBrick,
  getColumnDateProps,
  onChangeCurrentPage,
  getColumnSearchProps,
  getColumnSelectProps,
}: IProps) => {
  const history = useHistory();

  const { appPermission } = useSelector<RootState, Type_Initstate>(
    (state) => state.app
  );

  const initColumns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "3%",
      align: "center",
    },
    {
      title: "Mã sinh viên",
      dataIndex: "ma_sv",
      key: "ma_sv",
      ...getColumnSearchProps("ma_sv", "contains", "text"),
      render: (value, _) => (
        <Text
          color={"#045993"}
          textDecoration={"underline"}
          cursor="pointer"
          onClick={() => {
            history.push(
              `${
                appPermission === EnumPermission.ADMIN ? ADMIN : CSDT
              }/instant-payment-notification-detail/${_._id}`
            );
          }}
        >
          {value}
        </Text>
      ),
    },
    {
      title: "Mã cơ sở",
      dataIndex: "schoolCode",
      key: "schoolCode",
      ...getColumnSearchProps("schoolCode", "contains", "text"),
      render: (value) => (
        <div
          style={{
            color: "#045993",
          }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Mã phiếu thu",
      dataIndex: "so_phieu_bao",
      key: "so_phieu_bao",
      ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
      render: (value, _) => (
        <Text
          color={appPermission === EnumPermission.CSDT ? "#045993" : ""}
          textDecoration={
            appPermission === EnumPermission.CSDT ? "underline" : ""
          }
          cursor={appPermission === EnumPermission.CSDT ? "pointer" : ""}
          onClick={() => {
            history.push(
              `${CSDT}/instant-payment-notification-detail/${_._id}`
            );
          }}
        >
          {value}
        </Text>
      ),
    },
    {
      title: "Tên cơ sở",
      dataIndex: "schoolName",
      key: "schoolName",
      width: "15%",
      // ...getColumnSearchProps("ten_truong", "contains", "text"),
      render: (value) => (
        <Box className="font-[700] text-ellipsis">{value}</Box>
      ),
    },

    {
      title: "Tên sinh viên",
      dataIndex: "studentName",
      key: "studentName",
      ...getColumnSearchProps("studentName", "contains", "text"),
    },
    // {
    //   title: "Mã phiếu thu",
    //   dataIndex: "so_phieu_bao",
    //   key: "so_phieu_bao",
    //   ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
    // },
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      ...getColumnDateProps("createdAt", "contains", "date"),
      render: (value) => (
        <div>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</div>
      ),
    },
    {
      title: "Số lần",
      dataIndex: "count",
      key: "count",
      ...getColumnSearchProps("count", "contains", "text"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: "200",
      ...getColumnSelectProps("status"),
      render: (value, _) => {
        return (
          <div>
            <Box>
              {value === 1 ? (
                <Text fontWeight={600} color={"#5FAD67"}>
                  Thành công
                </Text>
              ) : value === 2 ? (
                <Text fontWeight={600} color={"#F3C262"}>
                  Đang chờ xử lý
                </Text>
              ) : (
                <Text fontWeight={600} color={"#FF2323"}>
                  Thất bại
                </Text>
              )}
            </Box>
          </div>
        );
      },
    },
    {
      title: "Lí do",
      dataIndex: "message",
      key: "message",
      // ...getColumnSearchProps("listTask", "contains", "text"),
      render: (value, _) => {
        return (
          <Flex justifyContent={"space-between"}>
            <Box>{value}</Box>
            {_?.status === 0 && (
              <Box
                cursor={"pointer"}
                onClick={() => {
                  handleRetryBrick(_.orgCode, _.schoolCode, _._id);
                }}
              >
                <IconReload />
              </Box>
            )}
          </Flex>
        );
      },
    },
  ];

  const [columns, setColums] = useState<ColumnsType<any>>(initColumns);

  useEffect(() => {
    appPermission !== EnumPermission.ADMIN
      ? setColums([...columns.slice(0, 1), ...columns.slice(3)])
      : setColums(initColumns);
    // eslint-disable-next-line
  }, [appPermission]);

  return (
    <Box>
      <Text
        color={globalStyles.colors.text[202027]}
        fontSize="15px"
        fontWeight="600"
        mb={3}
        // my="3"
      >
        Danh sách gạch nợ
      </Text>

      {appPermission === EnumPermission.ADMIN && (
        <HeaderFilter dataIndex="schoolCode" handleSearch={handleSearch} />
      )}
      <Table
        columns={columns}
        dataSource={billList}
        text={"lần gạch nợ"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"2400px"}
      />
    </Box>
  );
};

export default TableManager;
