import { Box } from "@chakra-ui/react";
import { Form, FormInstance, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IBill } from "service/Bill/Bill";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { EnumPermission } from "util/Constant";
import { formatMoney } from "util/general";
import "./style.css";

interface Props {
  bill: IBill | undefined;
}

const ViewDetailTransaction = ({ bill }: Props) => {
  const { appPermission } = useSelector<RootState, Type_Initstate>(
    (state) => state.app
  );
  const [isDisabled] = useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const handleSetData = () => {
    formRef?.current?.setFieldsValue({
      ...bill,
      createDate: moment(bill?.createdAt).format("HH:mm:ss DD/MM/YYYY"),
      date_line: moment(bill?.date_line).format("HH:mm:ss DD/MM/YYYY"),
      ngay_tao: moment(bill?.ngay_tao).format("HH:mm:ss DD/MM/YYYY"),
      phai_thu: formatMoney(bill?.phai_thu),
      mien_giam: formatMoney(bill?.mien_giam),
      tong_thu: formatMoney(bill?.tong_thu),
      ma_loai_thu: bill?.ma_loai_thu?.typeFeeName,
      status:
        bill?.trang_thai === 1
          ? "Đã thu"
          : bill?.trang_thai === 2
          ? "Đang thanh toán"
          : "Chưa thu",
    });
  };

  useEffect(() => {
    bill && handleSetData();
    // eslint-disable-next-line
  }, [bill]);

  return (
    <Form
      name="basic"
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
      className="custom-disable-input"
    >
      <Box maxW={"755px"}>
        <Form.Item labelAlign="left" label="Mã sinh viên" name="ma_sv">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Mã cơ sở" name="schoolCode">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tên cơ sở đào tạo"
          name="ten_truong"
        >
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        {appPermission === EnumPermission.ADMIN && (
          <Form.Item labelAlign="left" label="Học kỳ" name="hoc_ky_chu">
            <Input className="custom-input" disabled={!isDisabled} />
          </Form.Item>
        )}
        <Form.Item labelAlign="left" label="Số phiếu thu" name="so_phieu_bao">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Loại thu" name="ma_loai_thu">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Tổng thu (VND)" name="tong_thu">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Miễn giảm (VND)" name="mien_giam">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Phải thu (VND)" name="phai_thu">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Ngày tạo" name="ngay_tao">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Cập nhật" name="createdAt">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
        <Form.Item labelAlign="left" label="Nội dung" name="noi_dung">
          {/* <TextArea className="custom-input" disabled={!isDisabled} /> */}
          <Box
            border={'1px solid #d9d9d9'}
            borderRadius="6px"
            padding={'4px 11px'}
            cursor="not-allowed"
            dangerouslySetInnerHTML={{ __html: bill?.noi_dung }}
          ></Box>
        </Form.Item>
        <Form.Item labelAlign="left" label="Trạng thái" name="status">
          <Input className="custom-input" disabled={!isDisabled} />
        </Form.Item>
      </Box>
    </Form>
  );
};

export default ViewDetailTransaction;
