import React from "react";

const IconEdit = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="icon"
        d="M13.6573 1.51463L16.4857 4.34306C16.8763 4.73358 16.8763 5.36675 16.4857 5.75727L7.70935 14.5336L1.69894 16.3014L3.46671 10.291L12.2431 1.51463C12.6336 1.12411 13.2668 1.12411 13.6573 1.51463Z"
        stroke="#045993"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconEdit;
