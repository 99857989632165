import { Flex } from "@chakra-ui/react";
import { Form, FormInstance, message, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SchoolDetail } from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { setLoadingApp } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import * as Sentry from "@sentry/browser";

interface IProps {
  placeholder?: string;
  dataIndex: string;
  handleSearch: (args: any) => Promise<void>;
}

const { Option } = Select;

const HeaderFilter = ({ placeholder, dataIndex, handleSearch }: IProps) => {
  const dispatch = useDispatch();
  const formRef = React.useRef<FormInstance>(null);
  const [schools, setSchools] = useState<SchoolDetail[]>([]);
  // const [] = useState('');
  // const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  // const { appPermission, authInfor } = appState;

  const onFinish = (value: any) => {
    handleSearch({
      value: value[`${dataIndex}`],
      key: dataIndex,
      type: "eq",
      filterType: "text",
    });
  };

  const handleGetList = async () => {
    try {
      dispatch(setLoadingApp(true));
      // OsaService
      const res = await OrganizationAdminService.GET_ALL_SCHOOL();
      // appPermission === EnumPermission.ADMIN
      // ? await OrganizationAdminService.GET_ALL_SCHOOL()
      // : await OsaService.GET_ALL_SCHOOL_BELONGING_ORG(authInfor.orgCode);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const response = res.data;
        setSchools(response.data.records);
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data?.message}`);
          Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    handleGetList();
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Flex
        flexDirection={"row"}
        alignContent="center"
        justifyContent="start"
        mt="4"
      >
        <Form.Item
          name={dataIndex}
          labelAlign="left"
          className="text-bold-1"
          rules={[{ required: true, message: "Vui lòng nhập tên cơ sở!" }]}
        >
          <Select
            showSearch
            placeholder={placeholder ? placeholder : "Nhập tên cơ sở đào tạo"}
            className="custom-input rouder-8 w-200"
            onChange={(e) => {
              if (e === "ALL") {
                handleSearch({
                  value: "",
                  key: dataIndex,
                  type: "eq",
                  filterType: "text",
                });
              } else {
                handleSearch({
                  value: e,
                  key: dataIndex,
                  type: "eq",
                  filterType: "text",
                });
              }
            }}
            filterOption={(input, option) => {
              return `${option?.children ?? ""}`
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            style={{
              width: "400px",
              borderRadius: "8px",
            }}
          >
            <Option value={"ALL"}>Tất cả</Option>
            {schools.map((e) => (
              <Option value={e.schoolCode} key={e._id}>
                {e.schoolName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* <Box mx="2">
          <MainButton
            htmlType="submit"
            text={"Tìm kiếm"}
            classButton="rouder-8"
          />
        </Box>

        <Button
          danger
          className="custom-button rouder-8"
          onClick={() => {
            handleClear();
          }}
          style={{
            width: "95px",
          }}
        >
          Bỏ lọc
        </Button> */}
      </Flex>
    </Form>
  );
};

export default memo(HeaderFilter);
