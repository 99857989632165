import { Box, Button, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import Table from "components/table";
import { useHistory } from "react-router-dom";
import { globalStyles } from "theme/styles";
import { OSA } from "util/Constant";

interface IProps {
  orgUserList: any[];
  totalRow: number;
  loading: boolean;

  pageSize: number;
  currentPage: number;
  handleSearch: (args: any) => Promise<void>;
  onChangeCurrentPage: (e: any) => void;
  getColumnSelectProps: (dataIndex: any) => any;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
}

const View = ({
  loading,
  orgUserList,
  pageSize,
  totalRow,
  currentPage,
  onChangeCurrentPage,
  getColumnSearchProps,
  getColumnSelectProps,
}: IProps) => {
  const history = useHistory();

  const handleNavigate = (orgCode: string, id: string) => {
    history.push(`${OSA}/${orgCode}/update-user-infor/${id}`);
  };

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "4%",
      align: "center",
    },
    {
      title: "Họ và tên",
      dataIndex: "fullName",
      key: "fullName",
      ...getColumnSearchProps("fullName", "contains", "text"),
      render: (text, value) => {
        return (
          <Text
            onClick={() => handleNavigate(value.orgCode, value._id)}
            cursor="pointer"
            style={{
              color: "#045993",
            }}
          >
            {text}
          </Text>
        );
      },
    },
    {
      title: "Mã nhân sự",
      dataIndex: "personnelCode",
      key: "personnelCode",
      ...getColumnSearchProps("personnelCode", "contains", "text"),
    },
    {
      title: "Tên tài khoản",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username", "contains", "text"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "contains", "text"),
    },
    {
      title: "SĐT",
      dataIndex: "phone",
      key: "phone",
      width: "120px",
      ...getColumnSearchProps("phone", "contains", "text"),
    },
    {
      title: "Quyền",
      dataIndex: "roles",
      key: "roles",
      ...getColumnSelectProps("roles"),
      render: (value: string[]) => (
        <>
          {value
            .map((e) =>
              e === "APPROVE_UPDATE_REFUND_VNP" ? "Quản lý" : "Nhân viên"
            )
            .join(", ")}
        </>
      ),
    },
  ];

  return (
    <Box
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" justifyContent="space-between" align="center">
        <Text fontWeight={600} color={"#202027"}>
          Danh sách người dùng
        </Text>
        <Button
          colorScheme="whatsapp"
          backgroundColor={"#5FAD67"}
          borderRadius="6px"
          onClick={() => history.push(`${OSA}/add-user`)}
        >
          Thêm mới
        </Button>
      </Flex>
      <Table
        columns={columns}
        dataSource={orgUserList}
        text={"nguời dùng"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"2400px"}
      />
    </Box>
  );
};

export default View;
