import { Box, SimpleGrid } from "@chakra-ui/react";
import { DatePicker, Form, FormInstance, Input, Select, Switch } from "antd";
import { useCallback } from "react";
import {
  ItypeFee,
  PartnerType,
  PaymentIntermediaries,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";

import { dateFormat, REGEX_NUMBER, STATUS_ACTIVE } from "util/Constant";

const { RangePicker } = DatePicker;

const { Option } = Select;

interface IProps {
  form: FormInstance;
  isDisableForm: boolean;
  signValue: string;
  setSignValue: (value: string) => void;
  enableEffectiveDate: boolean;
  setEnableEffectiveDate: (value: boolean) => void;
  paymentMethod: PaymentMethodDetail;
  paymentIntermediaries: PaymentIntermediaries;
  typeFee: ItypeFee[];
}

export const valueCompareOperator = {
  none: "none",
  any: "any",
  gt: ">",
  lt: "<",
  gte: ">=",
  lte: "<=",
  eq: "=",
};

export const COMPAIR_OPERATOR = [
  { lable: "none", value: valueCompareOperator.none },
  { lable: ">", value: valueCompareOperator.gt },
  { lable: "<", value: valueCompareOperator.lt },
  { lable: ">=", value: valueCompareOperator.gte },
  { lable: "<=", value: valueCompareOperator.lte },
  { lable: "=", value: valueCompareOperator.eq },
];

export const COMPAIR_OPERATOR2 = [
  { lable: "any", value: valueCompareOperator.any },
  { lable: ">", value: valueCompareOperator.gt },
  { lable: "<", value: valueCompareOperator.lt },
  { lable: ">=", value: valueCompareOperator.gte },
  { lable: "<=", value: valueCompareOperator.lte },
  { lable: "=", value: valueCompareOperator.eq },
];

const ChargeConditions = (props: IProps) => {
  const {
    form,
    isDisableForm,
    signValue,
    setSignValue,
    enableEffectiveDate,
    setEnableEffectiveDate,
    paymentMethod,
    paymentIntermediaries,
    typeFee,
  } = props;

  const renderPaymentMethod = useCallback(() => {
    const allMethod = { _id: "all", name: "Tất cả" };
    if (paymentMethod) {
      const listFilter = paymentMethod.paymentMethod.filter(
        (item) => item.status === 1
      );
      const listRender =
        listFilter.length >= 2 ? [allMethod, ...listFilter] : listFilter;
      return listRender.map((item) => (
        <Option value={item._id}>{item.name}</Option>
      ));
    }
    return [];
  }, [paymentMethod]);

  const renderPaymentIntermediaries = useCallback(() => {
    const allMethod = { partnerCode: "all", partnerName: "Tất cả" };
    if (paymentIntermediaries) {
      const listFilter = paymentIntermediaries.partners.filter(
        (item) => item.status === 1
      );
      const listRender =
        listFilter.length >= 2 ? [allMethod, ...listFilter] : listFilter;
      return listRender.map((item) => (
        <Option value={item.partnerCode}>{item.partnerName}</Option>
      ));
    }
    return [];
  }, [paymentIntermediaries]);

  const handleChangeSign = (value: string) => {
    setSignValue(value);
    form.setFields([
      {
        name: "amount",
        value:
          value === valueCompareOperator.none
            ? ""
            : form.getFieldValue("amount"),
        errors: [],
      },
    ]);
  };

  const handleChangeSwitch = (checked: boolean) => {
    setEnableEffectiveDate(checked);
    form.setFields([
      {
        name: "effectiveDate",
        value: "",
        errors: [],
      },
    ]);
  };

  return (
    <Box>
      <Form.Item
        labelAlign="left"
        label="Nhóm sinh viên"
        name="groupStudent"
        rules={[{ required: true, message: "Vui lòng chọn nhóm sinh viên!" }]}
      >
        <Select className="w-full custom-input">
          <Option value="all">Tất cả</Option>
        </Select>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Mã loại thu"
        name="typeFee"
        rules={[{ required: true, message: "Vui lòng chọn mã loại thu!" }]}
      >
        <Select className="w-full custom-input">
          <Select.Option value={"any"}>{"Tất cả"}</Select.Option>
          {typeFee?.map((e, i) => (
            <Select.Option value={e.typeFeeCode} key={i}>
              {e.typeFeeName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Trung gian thanh toán"
        name="paymentIntermediaries"
        validateFirst={true}
        rules={[
          {
            validator: () => {
              if (
                paymentIntermediaries &&
                paymentIntermediaries.partners.findIndex(
                  (item: PartnerType) => item.status === STATUS_ACTIVE
                ) < 0
              ) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            message: "Vui lòng thiết lập TGTT tại tab Đối tác TGTT!",
          },
          { required: true, message: "Vui lòng chọn trung gian thanh toán!" },
        ]}
      >
        <Select className="w-full custom-input">
          {renderPaymentIntermediaries()}
        </Select>
      </Form.Item>
      <Form.Item
        labelAlign="left"
        label="Phương thức thanh toán"
        name="paymentMethod"
        rules={[
          {
            required: true,
            message: "Vui lòng chọn phương thức thanh toán thanh toán!",
          },
        ]}
      >
        <Select className="w-full custom-input">{renderPaymentMethod()}</Select>
      </Form.Item>

      <Form.Item labelAlign="left" label="Số tiền">
        <SimpleGrid columns={2} spacing={4}>
          <Box>
            <Form.Item name="sign">
              <Select
                className="w-full custom-input"
                options={COMPAIR_OPERATOR}
                onChange={handleChangeSign}
              />
            </Form.Item>
          </Box>
          <Box>
            <Form.Item
              name="amount"
              rules={[
                { pattern: REGEX_NUMBER, message: "Vui lòng chỉ nhập số!" },
                {
                  validator: (_, value) => {
                    if (signValue !== valueCompareOperator.none && !value) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                  message: "Vui lòng nhập số tiền!",
                },
              ]}
            >
              <Input
                placeholder="Nhập số tiền (VND) ..."
                className="custom-input"
                disabled={
                  signValue === valueCompareOperator.none || isDisableForm
                }
              />
            </Form.Item>
          </Box>
        </SimpleGrid>
      </Form.Item>

      <Form.Item labelAlign="left" label="Thời gian hiệu lực">
        <Switch
          onChange={handleChangeSwitch}
          disabled={isDisableForm}
          defaultChecked={form.getFieldValue("effectiveDate").length === 2}
        />
      </Form.Item>
      <Form.Item
        name="effectiveDate"
        rules={[
          {
            validator: (_, value) => {
              if (enableEffectiveDate && !value) {
                return Promise.reject();
              }
              return Promise.resolve();
            },
            message: "Vui lòng chọn thời gian hiệu lực!",
          },
        ]}
      >
        <RangePicker
          format={dateFormat}
          className="custom-input w-full"
          disabled={!enableEffectiveDate || isDisableForm}
          // defaultValue={[]}
        />
      </Form.Item>
    </Box>
  );
};

export default ChargeConditions;
