import React from "react";

const IconReload = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Return">
        <path
          id="Union"
          d="M9.25 4.5C9.66421 4.5 10 4.16421 10 3.75C10 3.33579 9.66421 3 9.25 3V4.5ZM5.02374 13.259C5.31776 13.5507 5.79263 13.5489 6.0844 13.2549C6.37616 12.9609 6.37434 12.486 6.08033 12.1942L5.02374 13.259ZM7.87596 5.58397C7.6462 5.92862 7.73933 6.39427 8.08397 6.62404C8.42862 6.8538 8.89427 6.76067 9.12404 6.41603L7.87596 5.58397ZM10 3.75L10.624 4.16603C10.8538 3.82138 10.7607 3.35573 10.416 3.12596L10 3.75ZM8.16603 1.62596C7.82138 1.3962 7.35573 1.48933 7.12596 1.83397C6.8962 2.17862 6.98933 2.64427 7.33397 2.87404L8.16603 1.62596ZM9.25 13.4766C8.83579 13.4766 8.5 13.8124 8.5 14.2266C8.5 14.6408 8.83579 14.9766 9.25 14.9766V13.4766ZM13.4763 4.71762C13.1822 4.42585 12.7074 4.42768 12.4156 4.72169C12.1238 5.0157 12.1257 5.49057 12.4197 5.78234L13.4763 4.71762ZM10.624 12.416C10.8538 12.0714 10.7607 11.6057 10.416 11.376C10.0714 11.1462 9.60573 11.2393 9.37596 11.584L10.624 12.416ZM8.5 14.25L7.87596 13.834C7.6462 14.1786 7.73933 14.6443 8.08397 14.874L8.5 14.25ZM10.334 16.374C10.6786 16.6038 11.1443 16.5107 11.374 16.166C11.6038 15.8214 11.5107 15.3557 11.166 15.126L10.334 16.374ZM9.25 3C5.93629 3 3.25 5.68629 3.25 9H4.75C4.75 6.51472 6.76472 4.5 9.25 4.5V3ZM3.25 9C3.25 10.665 3.92917 12.1728 5.02374 13.259L6.08033 12.1942C5.25792 11.3781 4.75 10.2491 4.75 9H3.25ZM9.12404 6.41603L10.624 4.16603L9.37596 3.33397L7.87596 5.58397L9.12404 6.41603ZM10.416 3.12596L8.16603 1.62596L7.33397 2.87404L9.58397 4.37404L10.416 3.12596ZM9.25 14.9766C12.5637 14.9766 15.25 12.2903 15.25 8.97659H13.75C13.75 11.4619 11.7353 13.4766 9.25 13.4766V14.9766ZM15.25 8.97659C15.25 7.31159 14.5708 5.80383 13.4763 4.71762L12.4197 5.78234C13.2421 6.59847 13.75 7.72751 13.75 8.97659H15.25ZM9.37596 11.584L7.87596 13.834L9.12404 14.666L10.624 12.416L9.37596 11.584ZM8.08397 14.874L10.334 16.374L11.166 15.126L8.91603 13.626L8.08397 14.874Z"
          fill="#045993"
        />
      </g>
    </svg>
  );
};

export default IconReload;
