import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, message } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import React, { useEffect, useMemo, useState } from "react";
import {
  IidSHB,
  PaymentPartnerDetail,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";
import * as Sentry from "@sentry/browser";
import { omit } from "lodash";

// hash code
const PUBLIC_KEY =
  "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA2jxJIfxutsTtmUTpYOjNfnMiV2Q6ODvbS0rVfp11CNLqgnymu1zyzcgdO2BbeYjMviGrgoGw6UqSCjDSdQwFWd2agC4y8nZZ7zutHCbLXHY5G/ae5HjFuF6lPWAFe5OBTk9u5qPBSb2Oi6TwtShUzaySiG2Ka0uzxxit/LCM6k8U4h1Qaw09qMzJmQd9ahOzrZuoMKOq6+PHCtwtb7zH/flusGGVUSDDduC2rTasHQvvFyHfuZXuXUBaSxm5iCWrgOpqIssHRXzWT1+OabeRxWqNn88jvLXKbRrozlZDlw0lEWdcPK/whzrIX7aulcfl8QLW6I53lEabw0 //GPKlk5Twk4owav9SHuxf/uV6mziv79Otet4Jc/jYlW+x+CP67Tbvf6qr9frILXSPprIgy30wOQM7t450+VCbMExJ9mdCrL9KF6PsmmOAt45FiHFt+tQUr2TiggFgjGCuQuxqztb7cG9HAcEsTQx4Whz7utk+xRtehz8Pnh5X9uRkTSxZ3ZkpkVuAuFczC3n+rZBFsN3i7eb4Z8s5cVRF27bTF0Sy2rNm0x1uhHpo863r+ikBcOfA7tAMA+gx8qRtq8wmEab6AiHcdjXj9rI/wcNRBwj79q4Et2x7PWmrIYhebR51Pv+vW7kSFQ86aMmkMfttqRtvQ4US/u9C2cdUgcPmzcUCAwEAAQ==";

interface IProps {
  listTID: IidSHB[];
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
  setListTID: React.Dispatch<React.SetStateAction<IidSHB[]>>;
}

const SetUpID = ({
  listTID,
  setListTID,
  paymentPartnerDetail,
  handleGetDetailPaymentParner,
}: IProps) => {
  const [cloneListTID, setCloneListTID] = useState<IidSHB[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [idRowEdit, setIdRowEdit] = useState("");
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);

  const {
    PaymentPartnerUpdate,
    PaymentPartnerCreate,
    PaymentVNPayPartnerDelete,
  } = OrganizationAdminService;

  const handleEditRow = (index: number, _id: string) => {
    setIsEdit(true);
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, isEdit: true };
    setIdRowEdit(_id);
    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const onChangeValue = (index: number, key: string, values: string) => {
    let currenIndex = cloneListTID[index];
    currenIndex = { ...currenIndex, [key]: values };

    setCloneListTID([
      ...cloneListTID.slice(0, index),
      currenIndex,
      ...cloneListTID.slice(index + 1),
    ]);
  };

  const handleRemoveRow = (index: number) => {
    const rowData = cloneListTID.find((_, indx) => indx === index);
    const newArr = [
      ...cloneListTID.slice(0, index),
      ...cloneListTID.slice(index + 1),
    ];

    setCloneListTID(newArr);
    setListTID(newArr);
    paymentPartnerDetail && onFinishRm(rowData._id);
  };

  const onFinishRm = async (id: string) => {
    try {
      const valuePost: any = {
        _idParner: paymentPartnerDetail._id,
        configType: "TID",
        _idConfig: id,
      };

      let response = await PaymentVNPayPartnerDelete(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase(),
        valuePost
      );
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        message.success("Xoá TID thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const handleAddRow = () => {
    setCloneListTID([
      ...cloneListTID,
      {
        accountNo: "",
        clientId: "",
        clientSecret: "",
        consumerKey: "",
        consumerSecret: "",
        prefixAccount: "",
        publicKey: PUBLIC_KEY,
        saleTypeCd: "",
        taxNo: "",
        TIDName: "",
        isEdit: true,
        ip: "",
        password: "",
        username: "",
      },
    ]);
  };
  const handleConfirm = () => {
    let err = false;
    const listData = cloneListTID.map((e) => ({ ...e, isEdit: false }));
    listData.forEach((e: any) => {
      Object.keys(e).forEach((el: any) => {
        if (e[el] === "") {
          err = true;
        }
      });
    });

    if (!err) {
      setListTID(listData);
      setCloneListTID(listData);
      setIsEdit(false);
      let rowData;
      if (idRowEdit) {
        // update
        rowData = listData.find((e) => e._id === idRowEdit);
      } else {
        // create get last arr
        rowData = listData?.[listData.length - 1];
      }
      // console.log(paymentPartnerDetail)
      // paymentPartnerDetail &&
      onFinish(omit(rowData, ["TIDStar", "_id"]), idRowEdit);
    } else {
      message.error("Yêu cầu nhập đầy đủ các trường");
      Sentry.captureMessage("Yêu cầu nhập đầy đủ các trường");
    }
  };

  const onFinish = async (rowEdit: IidSHB, idRowEdit: string) => {
    try {
      // todo create global config type
      const valuePost: any = {
        // _idParner: paymentPartnerDetail._id,
        // configType: "TID",
        TID: [rowEdit].map((e) => {
          const { isEdit, ...rest } = e;
          return { ...rest };
        }),
      };

      let response: any;
      if (idRowEdit) {
        // update
        valuePost["_idConfig"] = rowEdit._id ?? paymentPartnerDetail._id;
        response = await PaymentPartnerUpdate(
          orgCode,
          schoolCode,
          ID.toLocaleUpperCase(),
          valuePost
        );
      } else {
        response = await PaymentPartnerCreate(
          orgCode,
          schoolCode,
          ID.toLocaleUpperCase(),
          valuePost
        );
      }

      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        handleGetDetailPaymentParner();
        idRowEdit
          ? message.success("Chỉnh sửa TID thành công")
          : message.success("Thêm mới TID thành công");
      } else {
        if (response && validResponse(response)) {
          // !idRowEdit && handleRemoveRow(cloneListTID.length - 1);

          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    setCloneListTID(listTID);
    // eslint-disable-next-line
  }, [listTID]);

  const handleCancel = () => {
    setIsEdit(false);
    setCloneListTID([...listTID]);
  };

  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <Box overflowX={"scroll"}>
        <table className="my-table w-90 max-w-1078">
          <thead>
            <tr>
              <th style={{ width: "15%" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Tên tài khoản <span style={{ color: "#FF2323" }}>*</span>
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  ClientID
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Username
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Password
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Ip
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  ClientSecret
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  taxNO
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  AccoutNo
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  SaleTypeCD
                </Text>
              </th>
              <th style={{ minWidth: "71px" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Tiền tố
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Consumer Key
                </Text>
              </th>
              <th>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Consumer Secret
                </Text>
              </th>
              <th style={{ minWidth: "100px" }}>
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Hành động
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {cloneListTID.map((e, i) => (
              <tr key={i} className="tr-tid">
                {e.isEdit ? (
                  <RenderRowInput
                    item={e}
                    onChangeValue={onChangeValue}
                    index={i}
                  />
                ) : (
                  <RenderRow
                    item={e}
                    index={i}
                    isEdit={isEdit}
                    paymentPartnerDetail={paymentPartnerDetail}
                    handleEditRow={handleEditRow}
                    handleRemoveRow={handleRemoveRow}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box className="w-90 max-w-1078">
        {!isEdit && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                // remove id edit
                setIdRowEdit("");
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SetUpID;
