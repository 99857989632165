import { Box } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { globalStyles } from "theme/styles";
import OrganizationInformation from "./Components/OrganizationInformation";
import TabsPanel from "components/TabsPanel";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumb, Type_Initstate, setBrandText } from "store/appSlice";
import { ADMIN } from "util/Constant";
import { RootState } from "store";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { handleGetOrganztionDetail } from "util/general";

const AddNewOrganization = () => {
  const dispatch = useDispatch();
  const orgCode = useMemo(() => window.location.hash.split("/")[3], []);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;

  useEffect(() => {
    !detailOrganization &&
      handleGetOrganztionDetail(
        dispatch,
        orgCode,
        OrganizationAdminService.OrganizationDetail
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detailOrganization) {
      const list = {
        name: detailOrganization.orgName,
        link: `${ADMIN}/add-new-organization/${orgCode}`,
      };
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );
      dispatch(setBreadcrumb([list]));
    }
  }, [dispatch, orgCode, detailOrganization]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={1} />
      <Box>
        <OrganizationInformation />
      </Box>
    </Box>
  );
};

export default AddNewOrganization;
