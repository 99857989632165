import { Box } from "@chakra-ui/react";
import { Pagination, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { EnumPermission } from "util/Constant";

interface IProps {
  billList: any[];
  totalRow: number;
  pageSize: number;
  currentPage: number;
  loading: boolean;
  onChangeCurrentPage: (e: any) => void;

  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
}

const TableBillType = ({
  loading,
  billList,
  pageSize,
  totalRow,
  currentPage,
  onChangeCurrentPage,
  getColumnSearchProps,
}: IProps) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { appPermission } = appState;

  const backgroundBtnPagination = useMemo(() => {
    switch (appPermission) {
      case EnumPermission.ADMIN:
        return "pagination-admin";
      case EnumPermission.CSDT:
        return "pagination-csdt";
      case EnumPermission.OSA:
        return "pagination-osa";

      default:
        return "pagination-admin";
    }
  }, [appPermission]);

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "4%",
      align: "center",
    },
    {
      title: "Tên loại thu",
      dataIndex: "typeFeeName",
      key: "typeFeeName",
      ...getColumnSearchProps("typeFeeName", "contains", "text"),
    },
    {
      title: "Mã loại thu",
      dataIndex: "typeFeeCode",
      key: "typeFeeCode",
      ...getColumnSearchProps("typeFeeCode", "contains", "text"),
    },
  ];

  return (
    <Box>
      <Table
        columns={columns}
        dataSource={billList}
        pagination={false}
        loading={loading}
      />
      <div style={{ display: "flex", marginTop: 10 }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            className={backgroundBtnPagination}
            current={currentPage}
            showSizeChanger={false}
            total={totalRow}
            pageSize={pageSize}
            onChange={onChangeCurrentPage}
          ></Pagination>
        </div>
      </div>
    </Box>
  );
};

export default TableBillType;
