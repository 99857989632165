import React from "react";

const IconWanning = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2679 3C19.0377 1.66667 20.9623 1.66667 21.7321 3L35.5885 27C36.3583 28.3333 35.396 30 33.8564 30H6.14359C4.60399 30 3.64174 28.3333 4.41154 27L18.2679 3Z"
        stroke="#5FAD67"
        strokeWidth="2"
      />
      <rect x="19" y="11" width="1.6" height="12" fill="#5FAD67" />
      <circle cx="19.8" cy="25.8" r="0.8" fill="#5FAD67" />
    </svg>
  );
};

export default IconWanning;
