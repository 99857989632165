import { Box } from "@chakra-ui/react";
import { InputRef } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthInfor } from "service/Auth";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import useUploadHook from "util/hook/useUploadHook";
import View from "./View";
import * as Sentry from "@sentry/browser";

const StudentManagement = () => {
  const [authInfor, setAuthInfor] = useState<AuthInfor | undefined>(undefined);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);

  useEffect(() => {
    setAuthInfor(appState.authInfor);
  }, [appState.authInfor]);

  const [studentList, setStudentList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const dispatch = useDispatch();

  const {
    handleSearch,
    handleSetQuery,
    getColumnSearchProps,
    getColumnSelectProps1,
  } = FilterAntdHook({
    searchInput,
    searchedColumn,
    setCurrentPage,
    setSearchedColumn,
  });

  const { handleFileSelect, isLoading } = useUploadHook({
    url: `/student/upload/excel`,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      const URL = `organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/students`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setStudentList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setStudentList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <View
        loading={loading}
        pageSize={pageSize}
        totalRow={totalRow}
        currentPage={currentPage}
        studentList={studentList}
        isLoading={isLoading}
        handleSearch={handleSearch}
        onChangeCurrentPage={onChangeCurrentPage}
        getColumnSearchProps={getColumnSearchProps}
        getColumnSelectProps1={getColumnSelectProps1}
        handleFileSelect={handleFileSelect}
      />
    </Box>
  );
};

export default StudentManagement;
