import { Box } from "@chakra-ui/react";
import { InputRef } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import View from "./components/View";
import * as Sentry from "@sentry/browser";
import { IPlainOptionsMap } from "types/general";
import { EnumRoles } from "util/Constant";
import { IUserAdmin } from "service/Auth";

export default function UserManager() {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [userList, setUserList] = useState<IUserAdmin[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);

  const defaultChecked = "";
  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Nhân viên", value: EnumRoles.STAFF, type: "eq" },
    { name: "Quản lý", value: EnumRoles.ADMIN, type: "eq" },
  ];

  const {
    handleSearch,
    handleSetQuery,
    getColumnSearchProps,
    getColumnSelectProps,
  } = FilterAntdHook({
    searchInput,
    defaultChecked,
    searchedColumn,
    plainOptionsMap,
    setCurrentPage,
    setSearchedColumn,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const onHandleClose = () => {
    setVisible(false);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;

      // FIXME: change url to fix call API error
      const URL = "/admin";
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setUserList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setUserList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <View
        loading={loading}
        visible={visible}
        pageSize={pageSize}
        totalRow={totalRow}
        userList={userList}
        currentPage={currentPage}
        handleSearch={handleSearch}
        onHandleClose={onHandleClose}
        onChangeCurrentPage={onChangeCurrentPage}
        getColumnSearchProps={getColumnSearchProps}
        getColumnSelectProps={getColumnSelectProps}
      />
    </Box>
  );
}
