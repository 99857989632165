import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { globalStyles } from "theme/styles";
import { InputSchoolCreate } from "service/Auth";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRoute,
  setBrandText,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { NetWorkService, validResponse } from "util/API";
import { FormInstance, message } from "antd";
import { CODE_SUCCESS, CSDT, EnumRoles } from "util/Constant";
import { handleThrowError } from "util/general";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import FormLoginAccountShool from "views/admin/CSDTDetail/Components/FormLoginAccountShool/FormLoginAccountShool";
import AuthService from "service/Auth/AuthService";
import * as Sentry from "@sentry/browser";

const UserInforCSDT = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  // const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;
  const formRef = React.useRef<FormInstance>(null);
  const { CreateSsa, UpdateSsa } = AuthService;

  const [isEdit, setIsEdit] = useState(false);
  const [schoolCreate, setSchoolCreate] = useState<
    InputSchoolCreate | undefined
  >(undefined);

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const schoolCode = authInfor?.schoolCode;
      const orgCode = authInfor?.orgCode;

      const data = {
        ...values,
        roles: [values.roles],
        orgCode,
        idSchool: authInfor?.orgCode,
        schoolCode: authInfor?.schoolCode,
        status:
          values.status === "Hoạt động"
            ? 1
            : values.status === "Không hoạt động"
            ? 0
            : +values.status,
      };

      if (values.roles === "Nhân viên") {
        data["roles"] = [EnumRoles.STAFF];
      } else if (values.roles === "Quản lý") {
        data["roles"] = [EnumRoles.ADMIN];
      }

      const { status, ...rest } = data;
      const { username, ...updatedata } = data;

      const res = !ID
        ? await CreateSsa(orgCode, schoolCode, rest)
        : await UpdateSsa(orgCode, schoolCode, ID, updatedata);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setSchoolCreate({ ...schoolCreate, ...data });
        message.success(
          !ID ? "Tạo tài khoản thành công" : "Cập nhật tài khoản thành công"
        );
        !ID && history.push(`${CSDT}/user-management`);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
          // message.error(`${res.data.data?.message}`);
        }
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
      setIsEdit(false);
    }
  };

  const handleGetDetail = async () => {
    try {
      dispatch(setLoadingApp(true));
      const URL = `/admin/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}?_id[eq]=${ID}`;
      const res = await NetWorkService.Get({
        url: URL,
      });
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const response = res.data;
        setSchoolCreate(response.data.records[0]);
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data?.message}`);
          Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleSetValue = () => {
    formRef?.current?.setFieldsValue({
      username: schoolCreate.username,
      fullName: schoolCreate.fullName,
      email: schoolCreate.email,
      phone: schoolCreate.phone,
      address: schoolCreate.address,
      roles:
        schoolCreate.roles[0] === "APPROVE_UPDATE_REFUND_VNP"
          ? "Quản lý"
          : "Nhân viên",
      status: schoolCreate.status ? "Hoạt động" : "Không hoạt động",
    });
  };

  useEffect(() => {
    schoolCreate && handleSetValue();
    // eslint-disable-next-line
  }, [schoolCreate]);

  useEffect(() => {
    !isEdit && schoolCreate && handleSetValue();
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    ID && authInfor && handleGetDetail();
    dispatch(setActiveRoute("/user-management"));
    dispatch(
      setBrandText({
        name: "Quản lý người dùng",
        link: `${CSDT}/user-management`,
      })
    );

    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBrandText());
    };
    // eslint-disable-next-line
  }, [authInfor]);

  return (
    <Box
      // mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <Flex
        alignItems={"center"}
        cursor="pointer"
        onClick={() => history.push(`${CSDT}/user-management`)}
        w={"fit-content"}
      >
        <ChevronLeftIcon color={globalStyles.colors.permission.CSDT} />
        <Text
          color={globalStyles.colors.permission.CSDT}
          fontSize="15px"
          fontWeight={600}
          textDecorationLine="underline"
        >
          Quay lại
        </Text>
      </Flex>

      <Text my="3" fontWeight={500} fontSize="15px" color="#202027">
        Thông tin chi tiết
      </Text>

      <Text
        my="3"
        fontWeight={300}
        fontSize="13px"
        fontStyle={"italic"}
        color="#000"
        opacity={"0.5"}
      >
        Đây là các thông tin cá nhân của nhân sự Edubills. Các trường đánh dấu
        sao là trường bắt buộc nhập.
      </Text>
      <FormLoginAccountShool
        onFinish={onFinish}
        formRef={formRef}
        schoolCreate={schoolCreate}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
      />
    </Box>
  );
};

export default UserInforCSDT;
