import { InputRef, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { IRefundHistory } from "types/general";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import View from "./View";
import * as Sentry from "@sentry/browser";

const RequestRefund = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const defaultChecked = "";
  const searchInput = useRef<InputRef>(null);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [refundHistorys, setRefundHistorys] = useState<IRefundHistory[]>([]);
  const [selectedRows, setSelectedRows] = useState<IRefundHistory[]>([]);
  const [isVisible, setIsvisible] = useState(false);

  const { getColumnDateProps, getColumnSearchProps, handleSetQuery } =
    FilterAntdHook({
      searchedColumn,
      setSearchedColumn,
      setCurrentPage,
      searchInput,
      defaultChecked,
    });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc&status[eq]=2`;

      const BILL = `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/refund-history`;

      const res = await NetWorkService.Get({
        url: BILL + handleSetQuery(query),
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const respons = res.data;
        setRefundHistorys(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setRefundHistorys([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  //phê duyệt hoàn tiền
  const handleApproveRefund = async () => {
    try {
      setLoading(true);
      const approveUrl = `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/refund-history/approve`;
      const res = await NetWorkService.Put({
        url: approveUrl,
        body: { _id: selectedRows[0]._id },
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        message.success("Phê duyệt thành công");
        getData();
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      setLoading(false);
      // setIsvisible(false);
    }
  };

  //Từ chối hoàn tiền
  const handleRejectRefund = async () => {
    try {
      setLoading(true);
      const rejectUrl = `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/refund-history/reject`;
      const res = await NetWorkService.Put({
        url: rejectUrl,
        body: { _id: selectedRows[0]._id },
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        message.success("Yêu cầu đã bị từ chối");
        setTimeout(() => {
          getData();
        }, 1000);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      setLoading(false);
      setIsvisible(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: IRefundHistory[]) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <View
      loading={loading}
      pageSize={pageSize}
      totalRow={totalRow}
      isVisible={isVisible}
      billList={refundHistorys}
      currentPage={currentPage}
      rowSelection={rowSelection}
      selectedRows={selectedRows}
      setIsvisible={setIsvisible}
      getColumnDateProps={getColumnDateProps}
      handleRejectRefund={handleRejectRefund}
      onChangeCurrentPage={onChangeCurrentPage}
      handleApproveRefund={handleApproveRefund}
      getColumnSearchProps={getColumnSearchProps}
    />
  );
};

export default RequestRefund;
