import { Box } from "@chakra-ui/react";
import { FormInstance, message, Modal } from "antd";
import { HSeparator } from "components/separator/Separator";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import OsaService from "service/OSA/Osa";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import Cloes from "views/admin/OrganizationManagement/Icon/Cloes";
import FormAddNewBranch from "./FormAddNewBranch";
import * as Sentry from "@sentry/browser";

interface p {
  isOpen: boolean;
  formRef: React.MutableRefObject<FormInstance<any>>;
  onClose: () => void;
  setReCall?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewBranch = ({ onClose, setReCall, isOpen, formRef }: p) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const dispatch = useDispatch();
  const { authInfor } = appState;
  const { CREATE_BRANCH } = OsaService;

  const onFinish = async (values: any) => {
    try {
      localStorage.clear();
      dispatch(setLoadingApp(true));
      const res = await CREATE_BRANCH(authInfor.orgCode, values);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        message.success("Tạo thành công chi nhánh");
        onClose();
        setReCall(true);
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data.message}`);
          Sentry.captureMessage(res.data.data.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  return (
    <Modal
      title="Thêm mới tổ chức"
      open={isOpen}
      onCancel={onClose}
      footer={<></>}
      closeIcon={
        <>
          <Cloes />
        </>
      }
    >
      <HSeparator />
      <Box px="16px" py="16px">
        <FormAddNewBranch
          onFinish={onFinish}
          onClose={onClose}
          formRef={formRef}
        />
      </Box>
    </Modal>
  );
};

export default AddNewBranch;
