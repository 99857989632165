import React from "react";

interface P {
  onClick?: () => void;
}

const Cloes = ({ onClick }: P) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{
        cursor: "pointer",
      }}
    >
      <path
        d="M9.76242 7.99999L15.1374 2.63749C15.3728 2.40211 15.505 2.08286 15.505 1.74999C15.505 1.41711 15.3728 1.09787 15.1374 0.862488C14.902 0.627108 14.5828 0.494873 14.2499 0.494873C13.917 0.494873 13.5978 0.627108 13.3624 0.862488L7.99992 6.23749L2.63742 0.862488C2.40204 0.627108 2.0828 0.494873 1.74992 0.494873C1.41705 0.494873 1.0978 0.627108 0.862424 0.862488C0.627044 1.09787 0.494809 1.41711 0.494809 1.74999C0.494809 2.08286 0.627044 2.40211 0.862424 2.63749L6.23742 7.99999L0.862424 13.3625C0.745263 13.4787 0.652271 13.6169 0.58881 13.7693C0.525349 13.9216 0.492676 14.085 0.492676 14.25C0.492676 14.415 0.525349 14.5784 0.58881 14.7307C0.652271 14.883 0.745263 15.0213 0.862424 15.1375C0.978627 15.2546 1.11688 15.3476 1.2692 15.4111C1.42153 15.4746 1.58491 15.5072 1.74992 15.5072C1.91494 15.5072 2.07832 15.4746 2.23064 15.4111C2.38297 15.3476 2.52122 15.2546 2.63742 15.1375L7.99992 9.76249L13.3624 15.1375C13.4786 15.2546 13.6169 15.3476 13.7692 15.4111C13.9215 15.4746 14.0849 15.5072 14.2499 15.5072C14.4149 15.5072 14.5783 15.4746 14.7306 15.4111C14.883 15.3476 15.0212 15.2546 15.1374 15.1375C15.2546 15.0213 15.3476 14.883 15.411 14.7307C15.4745 14.5784 15.5072 14.415 15.5072 14.25C15.5072 14.085 15.4745 13.9216 15.411 13.7693C15.3476 13.6169 15.2546 13.4787 15.1374 13.3625L9.76242 7.99999Z"
        fill="#FF4B4B"
      />
    </svg>
  );
};

export default Cloes;
