import { Box, Flex, Text } from "@chakra-ui/react";
import { Switch } from "antd";
import IconEdit from "components/icons/IconEdit";
import IconRemove from "components/icons/IconRemove";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { PaymentConfigNavigation } from "service/OrganizationAdmin/Organization";
import { ENABLE, PaymentNavigateRule } from "util/Constant";
import { changeValueFieldToAll, formatMoney } from "util/general";

interface P {
  index: number;
  isEdit: boolean;
  isDefaultItem: boolean;
  item: PaymentConfigNavigation;
  handleEditRow: (index: number) => void;
  handleRemoveRow: (index: number) => void;
}

const RenderRow = ({
  item,
  index,
  isEdit,
  isDefaultItem,
  handleEditRow,
  handleRemoveRow,
}: P) => {
  const checkActiveStatus = useMemo(
    () =>
      dayjs(item.periodTo).isAfter(dayjs().add(-1, "day")) &&
      item.status === ENABLE,
    [item]
  );

  const handleGetKeyRender = (key: string, isGetValue?: boolean) => {
    if (item.navigateRules.length < 1) {
      return "Tất cả";
    }
    const valueName = item.navigateRules.find((e) => e.key === key);
    return valueName
      ? `${!isGetValue ? valueName.valueName : valueName.valueCode}`
      : "Tất cả";
  };

  const handleGetRule = (key: string) => {
    if (item.navigateRules.length < 1) {
      return "Tất cả";
    }
    const valueName = item.navigateRules.find((e) => e.key === key);

    return valueName ? valueName.rule : "Tất cả";
  };

  const handleConvertComma = (key: string) => {
    switch (key) {
      case "eq":
        return PaymentNavigateRule.eq;
      case "gt":
        return PaymentNavigateRule.gt;
      case "gte":
        return PaymentNavigateRule.gte;
      case "lt":
        return PaymentNavigateRule.lt;
      case "lte":
        return PaymentNavigateRule.lte;

      default:
        return "Tất cả";
    }
  };

  return (
    <>
      {!isEdit && (
        <td>
          <Flex p="5px" pl="0" justifyContent="start" alignItems="center">
            <Box cursor="pointer" onClick={() => handleEditRow(index)}>
              <IconEdit />
            </Box>
            <Box pl="2" onClick={() => handleRemoveRow(index)} cursor="pointer">
              {!isDefaultItem && <IconRemove />}
            </Box>
          </Flex>
        </td>
      )}
      {/*Thứ tự ưu tiên */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0" align={"center"}>
          {item.priority}
        </Text>
      </td>
      {/* configuration */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(item.navRuleName)}
        </Text>
      </td>
      {/* clientCode */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(handleGetKeyRender("clientCode")) ===
            "s5wXjKJzrZ0" && !isDefaultItem
            ? "EDUBILL"
            : changeValueFieldToAll(handleGetKeyRender("clientCode")) ===
                "cx615qhsFTL" && !isDefaultItem
            ? "EDUSOFT"
            : "Tất cả"}
        </Text>
      </td>
      {/* groupStudent*/}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(handleGetKeyRender("groupStudent"))}
        </Text>
      </td>
      {/* transactionCount*/}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {handleConvertComma(handleGetRule("accumulationTrans")) !==
              "Tất cả" &&
              handleConvertComma(handleGetRule("accumulationTrans"))}
          </Text>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {changeValueFieldToAll(
              !isNaN(+handleGetKeyRender("accumulationTrans", true))
                ? formatMoney(+handleGetKeyRender("accumulationTrans", true))
                : "Tất cả"
            )}
          </Text>
        </Flex>
      </td>
      {/* paymentMethod */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(handleGetKeyRender("paymentMethod"))}
        </Text>
      </td>
      {/* feeType */}
      <td>
        <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
          {changeValueFieldToAll(handleGetKeyRender("typeFee"))}
        </Text>
      </td>
      {/* transactionValue */}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {handleConvertComma(handleGetRule("transAmount")) !== "Tất cả" &&
              handleConvertComma(handleGetRule("transAmount"))}
          </Text>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {changeValueFieldToAll(
              !isNaN(+handleGetKeyRender("transAmount", true))
                ? formatMoney(+handleGetKeyRender("transAmount", true))
                : "Tất cả"
            )}
          </Text>
        </Flex>
      </td>
      {/* configCode */}
      <td>
        <Text>{item?.configCode ? item?.config?.configName : "Tất cả"}</Text>
      </td>
      {/* effectiveDate */}
      <td>
        <Flex p="3" pl="0" gap={5}>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {dayjs(item.periodFrom).format("DD/MM/YYYY")}
          </Text>
          <Text fontSize="15px" fontWeight="400" p="5px" pl="0">
            {dayjs(item.periodTo).format("DD/MM/YYYY")}
          </Text>
        </Flex>
      </td>
      {/* status */}
      <td>
        <Switch checked={isDefaultItem || checkActiveStatus} disabled />
      </td>
    </>
  );
};

export default RenderRow;
