import { Flex, Text } from "@chakra-ui/react";
import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  IPartners,
  PaymentMethod,
} from "service/OrganizationAdmin/Organization";
import { REGEX_NUMBER } from "util/Constant";
import { formatMoney } from "util/general";
import { COMPAIR_OPERATOR2 } from "../ManagementFees/ItemPannes/ChargeConditions";

const { Option } = Select;

interface P {
  isEdit: boolean;
  item: PaymentMethod;
  status: {
    _id: string;
    status: number;
  }[];
  setSignValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  signValue: {
    [key: string]: string;
  };
  listTgtt: IPartners[];
}

const ColRule = ({
  item,
  isEdit,
  status,
  listTgtt,
  signValue,
  setSignValue,
}: P) => {
  const [tgtts, setTgtts] = useState<IPartners[]>([]);

  useEffect(() => {
    if (item._id === "ATM") {
      setTgtts(
        listTgtt.filter((e) => e.status > 0 && e.partnerCode !== "BIDV")
      );
    } else {
      setTgtts(listTgtt.filter((e) => e.status > 0));
    }
    // eslint-disable-next-line
  }, [item]);

  return (
    <>
      <td>
        <>
          {isEdit ? (
            <Flex p="3" pl="0" alignItems="center">
              {status.find((e) => e._id === item._id).status === 1 && (
                <Form.Item
                  name={`${item._id}_priorityChoose`}
                  className="m-0"
                  rules={[{ required: true, message: "Vui lòng chọn!" }]}
                  initialValue={"VNPAY"}
                >
                  <Select className="custom-input h-34">
                    {tgtts.map((e) => {
                      if (e.status > 0) {
                        return (
                          <Option value={e.partnerCode}>{e.partnerName}</Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              )}
            </Flex>
          ) : (
            <Text color="#202027" fontSize="15px" fontWeight="400">
              {item?.priorityChoose}
            </Text>
          )}
        </>
      </td>
      <td>
        {isEdit ? (
          <Flex p="3" pl="0" alignItems="center">
            {status.find((e) => e._id === item._id).status === 1 && (
              <Flex alignContent="center">
                <Form.Item
                  name={`${item._id}_sign`}
                  className="mb-0 mr-2"
                  initialValue={signValue[`${item._id}_sign`]}
                >
                  <Select
                    onChange={(e) =>
                      setSignValue({
                        ...signValue,
                        [`${item._id}_sign`]: e,
                      })
                    }
                    className={`custom-input ${
                      !signValue[`${item._id}_sign`] ||
                      signValue[`${item._id}_sign`] === "any"
                        ? "w-full-1"
                        : "w-150"
                    }`}
                    options={COMPAIR_OPERATOR2}
                  />
                </Form.Item>

                {signValue[`${item._id}_sign`] &&
                signValue[`${item._id}_sign`] !== "any" ? (
                  <Form.Item
                    className="mb-0"
                    initialValue={item?.amount}
                    name={`${item._id}_amount`}
                    rules={[
                      {
                        pattern: REGEX_NUMBER,
                        message: "Vui lòng chỉ nhập số!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nhập số tiền (VND)"
                      className="custom-input"
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </Flex>
            )}
          </Flex>
        ) : (
          <Text color="#202027" fontSize="15px" fontWeight="400">
            {signValue && signValue[`${item._id}_sign`]}{" "}
            {item?.amount && formatMoney(+item?.amount)}
          </Text>
        )}
      </td>
    </>
  );
};

export default ColRule;
