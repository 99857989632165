import { Box } from "@chakra-ui/react";
import { FormInstance, message, Modal } from "antd";
import { HSeparator } from "components/separator/Separator";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { setLoadingApp } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import Cloes from "views/admin/OrganizationManagement/Icon/Cloes";
import FormAddTrainingFacilities from "./FormAddNewOrganization";
import * as Sentry from "@sentry/browser";

interface p {
  onClose: () => void;
  isOpen: boolean;
  id: string;
}

const ModalAddTrainingFacilities = ({ onClose, isOpen, id }: p) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = React.useRef<FormInstance>(null);

  const { CreateSchool } = OrganizationAdminService;

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const res = await CreateSchool(id, values);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        history.replace({
          pathname: `/admin/${res.data.data.orgCode}/add-school-information/${res.data.data.schoolCode}`,
          state: values,
        });
        onClose();
      } else {
        const msgErr =
          res && validResponse(res) ? res.data.data.message : "Có lỗi xảy ra !";
        message.error(msgErr);
        Sentry.captureMessage(msgErr);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
      // onClose();
    }
  };

  return (
    <Modal
      title="Thêm mới Cơ sở đào tạo"
      open={isOpen}
      onCancel={onClose}
      footer={<></>}
      closeIcon={
        <>
          <Cloes />
        </>
      }
    >
      <HSeparator />
      <Box px="16px" py="16px">
        {isOpen && (
          <FormAddTrainingFacilities
            onFinish={onFinish}
            onClose={onClose}
            formRef={formRef}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ModalAddTrainingFacilities;
