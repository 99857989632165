import { Box, Flex, Text } from "@chakra-ui/react";
import { InputRef } from "antd";
import Table from "components/table";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import * as Sentry from "@sentry/browser";

const ManagementTrainingFacilities = () => {
  // const history = useHistory();
  // const formRef = useRef<FormInstance>(null);

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [trainingFacilitiesList, setTrainingFacilitiesList] = useState<any[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const searchInput = useRef<InputRef>(null);

  const handleNavigate = (id: string) => {
    // history.push(`${OSA}/branch-detail/${id}`);
  };

  const { handleSetQuery, getColumnSearchProps } = FilterAntdHook({
    searchInput,
    searchedColumn,
    setCurrentPage,
    setSearchedColumn,
  });

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      const URL = `/organization/${authInfor?.orgCode}/school`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setTrainingFacilitiesList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setTrainingFacilitiesList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  const columnDefs = [
    {
      title: "STT",
      key: "stt",
      dataIndex: "stt",
      width: "70px",
    },
    {
      title: "Tên cơ sở",
      dataIndex: "orgName",
      key: "orgName",
      width: "350px",
      ...getColumnSearchProps("orgName", "contains", "text"),
      render: (value: any, record: any) => (
        <span
          onClick={() => handleNavigate(record._id)}
          style={{
            cursor: "pointer",
            color: "#045993",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Mã cơ sở",
      dataIndex: "schoolCode",
      key: "schoolCode",
      ...getColumnSearchProps("schoolCode", "contains", "text"),
      width: "250px",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "contains", "text"),
      width: "300px",
    },
    {
      title: "Fax",
      dataIndex: "fax",
      key: "fax",
      ...getColumnSearchProps("fax", "contains", "text"),
      width: "150px",
    },
    {
      title: "Chi nhánh",
      dataIndex: "branch",
      key: "branch",
      ...getColumnSearchProps("branch", "contains", "text"),
      width: "220px",
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address", "contains", "text"),
      // width: "350px",
      // with: "400px",
    },
  ];

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text style={{ color: "#202027", fontWeight: "bold" }}>
          {"Danh sách cơ sở đào tạo"}
        </Text>
      </Flex>
      <Table
        columns={columnDefs}
        dataSource={trainingFacilitiesList}
        text={"cơ sở đào tạo"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"1800px"}
      />
    </Box>
  );
};

export default ManagementTrainingFacilities;
