import { Box } from "@chakra-ui/react";
import { InputRef } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import View from "./View";
import * as Sentry from "@sentry/browser";

interface IProps {
  setVisibleModal?: () => void;
}

const TrainingFacilitiesCpn = ({ setVisibleModal }: IProps) => {
  const ID = useMemo(() => window.location.hash.split("/")[3], []);

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [traningFacilities, setTraningFacilities] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);

  const { handleSearch, handleSetQuery, getColumnSearchProps } = FilterAntdHook(
    {
      searchInput,
      searchedColumn,
      setCurrentPage,
      setSearchedColumn,
    }
  );

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      const URL = `/organization/${ID}/school`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setTraningFacilities(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setTraningFacilities([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <View
        traningFacilities={traningFacilities}
        currentPage={currentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        loading={loading}
        handleSearch={handleSearch}
        onChangeCurrentPage={onChangeCurrentPage}
        getColumnSearchProps={getColumnSearchProps}
        setVisibleModal={setVisibleModal}
      />
    </Box>
  );
};

export default TrainingFacilitiesCpn;
