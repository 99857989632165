import { Box, Flex, Text } from "@chakra-ui/react";
import { Button } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import React from "react";
import {
  ClientCode,
  IidSHB,
  ITransactionNavigation,
  ItypeFee,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";
import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";

interface IProps {
  isEdit: boolean;
  listTID: IidSHB[];
  prioritys: number[];
  typeFee: ItypeFee[];
  listClientCode: ClientCode[];
  paymentMethod: PaymentMethodDetail;
  cloneTransactionNavigation: ITransactionNavigation[];
  handleEditRow: (index: number, _id: string) => void;
  handleRemoveRow: (index: number) => void;
  handleAddRow: () => void;
  handleCancel: () => void;
  handleConfirm: () => void;
  setIdRowEdit: (value: React.SetStateAction<string>) => void;
  setIsEdit: (value: React.SetStateAction<boolean>) => void;
  onChangeValue: (
    index: number,
    name: string,
    values: string | string[] | number
  ) => void;
}

const View = ({
  isEdit,
  listTID,
  typeFee,
  prioritys,
  paymentMethod,
  listClientCode,
  cloneTransactionNavigation,
  onChangeValue,
  handleEditRow,
  handleRemoveRow,
  handleAddRow,
  handleCancel,
  setIdRowEdit,
  setIsEdit,
  handleConfirm,
}: IProps) => {
  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <Box overflowX={"scroll"}>
        <table className="my-table">
          <thead>
            <tr>
              {!isEdit && (
                <th className="mw-125">
                  <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                    Hành động
                  </Text>
                </th>
              )}
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  STT ưu tiên
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Cấu hình
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Client code
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text
                  color="#A0A6B1"
                  fontSize="15px"
                  fontWeight="500"
                  width={"150px"}
                >
                  Nhóm sinh viên
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Số GD tích lũy
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-335">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  PTTT
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Mã loại thu
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Giá trị GD
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  TID
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              {/* <th className="mw-125">
						<Text color="#A0A6B1" fontSize="15px" fontWeight="500">
							Thứ tự ưu tiên
						</Text>
					</th> */}
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Ngày hiệu lực
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Trạng thái
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {cloneTransactionNavigation.map((e, i) => (
              <tr key={i}>
                {e.isEdit ? (
                  <RenderRowInput
                    item={e}
                    index={i}
                    typeFee={typeFee}
                    listTID={listTID}
                    prioritys={prioritys}
                    paymentMethod={paymentMethod}
                    listClientCode={listClientCode}
                    isDefaultItem={i === cloneTransactionNavigation.length - 1}
                    onChangeValue={onChangeValue}
                  />
                ) : (
                  <RenderRow
                    item={e}
                    index={i}
                    isEdit={isEdit}
                    listTID={listTID}
                    typeFee={typeFee}
                    paymentMethod={paymentMethod}
                    handleEditRow={handleEditRow}
                    handleRemoveRow={handleRemoveRow}
                    isDefaultItem={i === cloneTransactionNavigation.length - 1}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box className="max-w-1078">
        {!isEdit && listTID.length !== 0 && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                // remove id edit
                setIdRowEdit("");
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default View;
