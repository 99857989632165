import { Box, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { globalStyles } from "theme/styles";
import { formatMoney } from "util/general";
import moment from "moment";
import Table from "components/table";
import { useHistory } from "react-router-dom";
import { CSDT } from "util/Constant";

interface IProps {
  billList: any[];
  totalRow: number;
  loading: boolean;
  isLoading: boolean;
  pageSize: number;
  currentPage: number;
  handleSearch: (args: any) => Promise<void>;
  handleFileSelect: (event: any) => void;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnSelectProps: (dataIndex: any) => any;
}

const View = ({
  loading,
  billList,
  pageSize,
  totalRow,
  isLoading,
  currentPage,
  handleSearch,
  handleFileSelect,
  getColumnDateProps,
  onChangeCurrentPage,
  getColumnSelectProps,
  getColumnSearchProps,
}: IProps) => {
  const history = useHistory();
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "4%",
    },
    {
      title: "Mã sinh viên",
      dataIndex: "ma_sv",
      key: "ma_sv",
      width: "6.5%",
      ...getColumnSearchProps("ma_sv", "contains", "text"),
      render: (value, _) => (
        <Text
          color={"#045993"}
          cursor="pointer"
          textDecoration={"underline"}
          onClick={() => {
            history.push(`${CSDT}/receipt/${_._id}`);
          }}
        >
          {value}
        </Text>
      ),
    },
    {
      title: "Học kỳ",
      dataIndex: "hoc_ky_chu",
      key: "hoc_ky_chu",
      width: "10%",
      ...getColumnSearchProps("hoc_ky_chu", "contains", "text"),
    },
    {
      title: "Số phiếu thu",
      dataIndex: "so_phieu_bao",
      key: "so_phieu_bao",
      width: "8%",
      ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
    },
    {
      title: "Nội dung",
      dataIndex: "noi_dung",
      key: "noi_dung",
      width: "15%",
      render: (value) => (
        <Box
          className="font-[700]"
          dangerouslySetInnerHTML={{ __html: value }}
        ></Box>
      ),
      ...getColumnSearchProps("noi_dung", "contains", "text"),
    },

    {
      title: "Loại thu",
      dataIndex: "",
      key: "ma_loai_thu",
      width: "5%",
      ...getColumnSearchProps("ma_loai_thu.typeFeeName", "contains", "text"),
      render: (value) => {
        return <span>{value?.ma_loai_thu?.typeFeeName}</span>;
      },
    },
    {
      title: "Tổng thu (VND)",
      dataIndex: "tong_thu",
      key: "tong_thu",
      width: "7%",
      ...getColumnSearchProps("tong_thu", "eq", "text"),
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
      align: "right",
    },
    {
      title: "Miễn giảm (VND)",
      dataIndex: "mien_giam",
      key: "mien_giam",
      width: "7%",
      ...getColumnSearchProps("mien_giam", "eq", "text"),
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
      align: "right",
    },
    {
      title: "Phải thu (VND)",
      dataIndex: "phai_thu",
      key: "phai_thu",
      width: "7%",
      ...getColumnSearchProps("phai_thu", "eq", "text"),
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
      align: "right",
    },
    {
      title: "Cập nhật",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "6%",
      render: (value) => (
        <Box pr="20px">{value ? moment(value).format("DD/MM/YYYY") : ""}</Box>
      ),
      align: "right",
      ...getColumnDateProps("createdAt", "eq", "date"),
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngay_tao",
      key: "ngay_tao",
      width: "6%",
      render: (value) => (
        <Box pr="20px">{value ? moment(value).format("DD/MM/YYYY") : ""}</Box>
      ),
      align: "right",
      ...getColumnDateProps("ngay_tao", "eq", "date"),
    },
    {
      title: "Trạng thái",
      dataIndex: "trang_thai",
      key: "trang_thai",
      width: "5%",
      render: (value) => (
        <div>
          {value === 0
            ? "Chưa thu"
            : value === 1
            ? "Đã thu"
            : value
            ? "Đang thanh toán"
            : ""}
        </div>
      ),
      ...getColumnSelectProps("trang_thai"),
    },
    {
      title: "Hạn thanh toán",
      dataIndex: "date_line",
      key: "date_line",
      width: "7%",
      align: "right",
      render: (value) => (
        <Box pr="22px">{value ? moment(value).format("DD/MM/YYYY") : ""}</Box>
      ),
      ...getColumnDateProps("date_line", "eq", "date"),
    },
  ];

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text color={globalStyles.colors.text[202027]} fontWeight="600">
          Danh sách phiếu thu
        </Text>
        {/* {appPermission === EnumPermission.CSDT && (
          <>
            <Button
              colorScheme="whatsapp"
              backgroundColor={"#5FAD67"}
              borderRadius="6px"
              onClick={handleClick}
              isLoading={isLoading}
            >
              Thêm mới
            </Button>
            <input
              type="file"
              onChange={handleFileSelect}
              ref={hiddenFileInput}
              id="hiddenFileInput"
              style={{ display: "none" }}
            />
          </>
        )} */}
      </Flex>

      <Table
        columns={columns}
        dataSource={billList}
        text={"phiếu thu"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"2500px"}
      />
    </Box>
  );
};

export default View;
