import { Box } from "@chakra-ui/react";
import { InputRef } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { RootState } from "store";
import { setBrandText, setBreadcrumb, Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import { handleGetDetail } from "util/general";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import TableBillType from "./Components/TableBillType";
import TabsPanel from "./Components/TabsPanel";
import * as Sentry from "@sentry/browser";

const BillType = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);

  const [billList, setBillList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);

  const { GetDetailShool } = OrganizationAdminService;

  const { getColumnSearchProps } = FilterAntdHook({
    searchInput,
    searchedColumn,
    setCurrentPage,
    setSearchedColumn,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;
      if (searchedColumn && Object.keys(searchedColumn).length > 0) {
        for (const key in searchedColumn) {
          const type = searchedColumn[key].type;
          const filter = searchedColumn[key].filter;
          const filterType = searchedColumn[key].filterType;

          switch (filterType) {
            case "text" || "number":
              query += `&${key}[${type}]=${filter}`;
              break;
            case "mutil":
              const option1 = searchedColumn[key].option1;
              const option2 = searchedColumn[key].option2;
              query += `&${key}[${type}]=${option1}&${key}[${type}]=${option2}`;
              break;
            case "date":
              const startTime = searchedColumn[key].startDate;
              const endTime = searchedColumn[key].endDate;
              query += `&${key}[gte]=${startTime}&${key}[lte]=${endTime}`;
              break;
            default:
              break;
          }
        }
      }
      const URL = `/organization/${orgCode}/school/${ID}/typeFee`;
      const res = await NetWorkService.Get({ url: URL + query });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setBillList(formatData(respons.data[0].typeFee));
        setTotalRow(respons.data[0].typeFee.length || 0);
      } else {
        setTotalRow(0);
        setBillList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetDetail(dispatch, GetDetailShool, orgCode, ID);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (schoolDetail) {
      const list = [
        {
          name: schoolDetail.orgName,
          link: `${ADMIN}/organization-detail/${schoolDetail.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${schoolDetail.orgCode}`,
        },
        {
          name: schoolDetail.schoolName,
          link: `${ADMIN}/${schoolDetail.orgCode}/school-information/${schoolDetail.schoolCode}`,
        },
        {
          name: "Danh mục loại thu",
          link: `${ADMIN}/${schoolDetail.orgCode}/bill-type/${schoolDetail.schoolCode}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );
      dispatch(setBreadcrumb(list));
    }
  }, [dispatch, schoolDetail]);

  useEffect(() => {
    if (searchedColumn) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={2} orgCode={orgCode} ID={ID} />
      <Box>
        <TableBillType
          billList={billList}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRow={totalRow}
          loading={loading}
          onChangeCurrentPage={onChangeCurrentPage}
          getColumnSearchProps={getColumnSearchProps}
        />
      </Box>
    </Box>
  );
};

export default BillType;
