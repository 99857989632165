import { message, Tabs } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FeeConfig,
  ItypeFee,
  PaymentIntermediaries,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import ItemPannes from "./ItemPannes/ItemPannes";
import * as Sentry from "@sentry/browser";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const ManagementFeesTabs = () => {
  const dispatch = useDispatch();
  const [listConfigFee, setListConfigFee] = useState<FeeConfig[]>([]);
  const [activeKey, setActiveKey] = useState("");
  const [items, setItems] = useState([]);

  const newTabIndex = useRef(0);

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const {
    GET_LIST_FEE,
    PaymentMethodDetail,
    GetPaymentIntermediaries,
    GetSchoolTypeFee,
  } = OrganizationAdminService;

  const [refreshListConfig, setRefreshListConfig] = useState(false);

  const orgCode = useMemo(
    () => (schoolDetail ? schoolDetail.orgCode : ""),
    [schoolDetail]
  );
  const schoolCode = useMemo(
    () => (schoolDetail ? schoolDetail.schoolCode : ""),
    [schoolDetail]
  );

  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodDetail | undefined
  >(undefined);

  const [paymentIntermediaries, setPaymentIntermediaries] = useState<
    PaymentIntermediaries | undefined
  >(undefined);

  const [typeFee, setTypeFee] = useState<ItypeFee[]>([]);

  const handleGetPaymentMethodDetail = async () => {
    try {
      const res = await PaymentMethodDetail(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setPaymentMethod(res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleGetPaymentIntermediaries = async () => {
    try {
      const res = await GetPaymentIntermediaries(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setPaymentIntermediaries(res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleGetSchoolTypeFee = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetSchoolTypeFee(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        res.data.data[0] && setTypeFee(res.data.data[0].typeFee);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    if (schoolDetail) {
      handleGetPaymentMethodDetail();
      handleGetPaymentIntermediaries();
      handleGetSchoolTypeFee();
    }
    // eslint-disable-next-line
  }, [schoolDetail]);

  const handleGetDetailConfigFee = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GET_LIST_FEE(
        schoolDetail.orgCode,
        schoolDetail.schoolCode
      );
      if (res.status) {
        setListConfigFee(res.data.data);
        if (refreshListConfig) {
          setRefreshListConfig(false);
        }
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    if (schoolDetail || refreshListConfig) {
      handleGetDetailConfigFee();
    }
    // eslint-disable-next-line
  }, [schoolDetail, refreshListConfig]);

  useEffect(() => {
    const initList =
      listConfigFee.length > 0
        ? listConfigFee.map((item, index) => {
            return {
              label: item.feeName || `Cấu hình ${index + 1}`,
              children: (
                <ItemPannes
                  detailConfig={item}
                  setRefreshListConfig={setRefreshListConfig}
                  paymentMethod={paymentMethod}
                  paymentIntermediaries={paymentIntermediaries}
                  typeFee={typeFee}
                />
              ),
              key: item._id,
              closable: false,
            };
          })
        : [
            {
              label: "Cấu hình 1",
              children: (
                <ItemPannes
                  setRefreshListConfig={setRefreshListConfig}
                  feeName={`Cấu hình 1`}
                  paymentMethod={paymentMethod}
                  paymentIntermediaries={paymentIntermediaries}
                  typeFee={typeFee}
                />
              ),
              key: "1",
              closable: false,
            },
          ];
    setActiveKey(initList[0].key);
    setItems([...initList]);
    // eslint-disable-next-line
  }, [listConfigFee]);

  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = `newTab${newTabIndex.current++}`;
    const newPanes = [...items];
    newPanes.push({
      label: `Cấu hình ${newPanes.length + 1}`,
      children: (
        <ItemPannes
          setRefreshListConfig={setRefreshListConfig}
          feeName={`Cấu hình ${newPanes.length + 1}`}
          paymentMethod={paymentMethod}
          paymentIntermediaries={paymentIntermediaries}
          typeFee={typeFee}
        />
      ),
      key: newActiveKey,
      closable: true,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      add();
    } else {
      remove(targetKey);
    }
  };

  return (
    <div>
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
      />
    </div>
  );
};

export default ManagementFeesTabs;
