import { message } from "antd";
import axios from "axios";
import { useState } from "react";
import { TOKEN } from "util/Constant";
import { readValueCookie } from "util/general";
import * as Sentry from "@sentry/browser";

interface P {
  url: string;
  setValue?: any;
}

const useUploadHook = ({ url, setValue }: P) => {
  const GATEWAY_RESOURCE = process.env.REACT_APP_BASE_API_GATEWAY_RESOURCE;
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (excelFile: any) => {
    try {
      // console.log(url);
      setIsLoading(true);
      setValue && setValue(null);
      const token = readValueCookie(TOKEN);

      const formData = new FormData();
      formData.append("file", excelFile, excelFile.name);

      axios
        .post(`${GATEWAY_RESOURCE}${url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response?.data) {
            message.success("Upload thành công");
            setValue && setValue(response?.data);
            // getData();
          } else {
            message.error("Có lỗi xảy ra");
            Sentry.captureMessage("Có lỗi xảy ra");
          }
          // Xử lý phản hồi từ server sau khi upload thành công
        })
        .catch((error) => {
          console.log(error);

          message.error(error.response.data?.message);
          Sentry.captureException(error);
          // Xử lý lỗi nếu có
        });
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = (event: any) => {
    try {
      let fileTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv",
      ];
      let selectedFile = event.target.files[0];
      if (selectedFile) {
        if (selectedFile && fileTypes.includes(selectedFile.type)) {
          handleUpload(event.target.files[0]);
        } else {
          message.error("Please select only excel file types");
        }
      } else {
        message.error("Please select your file");
        console.log("Please select your file");
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };
  return { isLoading, handleFileSelect };
};

export default useUploadHook;
