import { Box, Flex, Text } from "@chakra-ui/react";
import { DatePicker, Form, FormInstance, message, Select } from "antd";
import MainButton from "components/button";
import React, { useEffect, useState } from "react";
import { CODE_SUCCESS, dateFormat } from "util/Constant";
import IconUpload from "components/icons/IconUpload";
import IconDownload from "components/icons/IconDownload";
import IconSelected from "components/icons/IconSelected";
import { HSeparator } from "components/separator/Separator";
import useUploadHook from "util/hook/useUploadHook";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import * as Sentry from "@sentry/browser";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import moment from "moment";
import {
  IPartners,
  IReconcileCompare,
} from "service/OrganizationAdmin/Organization";
import { validResponse } from "util/API";
import { handleThrowError } from "util/general";
import { PaymentConfigPartnerACB } from "service/OrganizationAdmin/Organization";

const { Option } = Select;

interface P {
  listTgtt: IPartners[];
  isReconciliation: boolean;
  handleGetReconcileHistory: () => Promise<void>;
  setDate: React.Dispatch<React.SetStateAction<number>>;
  setIsModalReconciliationSuccess: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsModalReconciliationProgress: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsReconciliation: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalReconciliationFailed: React.Dispatch<React.SetStateAction<boolean>>;
  setReconciliation: React.Dispatch<React.SetStateAction<IReconcileCompare>>;
}

type ResponUpload = {
  orgCode: string;
  schoolCode: string;
  bucketName: string;
  path: string;
  size: number;
  mimetype: string;
  protect: string;
  status: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  totalTransaction: 11;
};

const Reconciliation = ({
  listTgtt,
  isReconciliation,
  setDate,
  setReconciliation,
  setIsReconciliation,
  handleGetReconcileHistory,
  setIsModalReconciliationFailed,
  setIsModalReconciliationSuccess,
  setIsModalReconciliationProgress,
}: P) => {
  const dispatch = useDispatch();
  const { ReconcileCompare, PaymentConfigPartner, ReconcileCompareNotVnpay } =
    OrganizationAdminService;
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const formRef = React.useRef<FormInstance>(null);
  const { authInfor } = appState;
  const [paymentPartner, setPaymentPartner] = useState("");
  const [configCode, setConfigCode] = useState("");
  const [listConfigCode, setConfigCodes] = useState<PaymentConfigPartnerACB[]>(
    []
  );
  const [resUpload, setResUpload] = useState<ResponUpload | undefined>(
    undefined
  );

  const { handleFileSelect, isLoading } = useUploadHook({
    setValue: setResUpload,
    url: `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/reconcile/upload`,
  });

  const GetListAccount = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await PaymentConfigPartner(
        authInfor?.orgCode,
        authInfor?.schoolCode,
        paymentPartner
      );
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setConfigCodes(
          res.data.data.map((e: any) => ({
            ...e,
          }))
        );
        formRef?.current?.setFieldsValue({
          configCode: res.data.data?.[0]?.configCode,
        });
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureException("Có lỗi xảy ra");
      }
    } catch (error) {
      console.log(error);
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    if (paymentPartner && paymentPartner !== "VNPAY") {
      GetListAccount();
    }
  }, [paymentPartner]);
  useEffect(() => {
    console.log(listTgtt);
    setPaymentPartner(listTgtt.filter((e) => e.status)?.[0]?.partnerCode);
    formRef?.current?.setFieldsValue({
      partnerCode: listTgtt.filter((e) => e.status)?.[0]?.partnerCode,
    });
  }, [listTgtt]);
  const handleReconcileVNPAY = async (values: any) => {
    try {
      const dataPost = {
        fileId: resUpload._id,
        date: new Date(values.date).getTime(),
      };
      setIsModalReconciliationProgress(true);
      setDate(new Date(values.date).getTime());
      const res: any = await ReconcileCompare(
        authInfor?.orgCode,
        authInfor?.schoolCode,
        dataPost
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setReconciliation(res.data.data);
        setIsModalReconciliationSuccess(true);
        handleGetReconcileHistory();
      } else {
        setIsModalReconciliationFailed(true);
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReconcileNotVNPAY = async (values: any) => {
    try {
      const dataPost = {
        date: moment(new Date(values.date)).format("YYYY/MM/DD"),
        paymentPartner: paymentPartner.toLocaleLowerCase(),
        configCode,
      };
      setIsModalReconciliationProgress(true);

      const res: any = await ReconcileCompareNotVnpay(
        authInfor?.orgCode,
        authInfor?.schoolCode,
        dataPost
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        setReconciliation(res.data.data);
        setIsModalReconciliationSuccess(true);
        handleGetReconcileHistory();
      } else {
        setIsModalReconciliationFailed(true);
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleReconcile = async (values: any) => {
    try {
      setIsReconciliation(true);
      if (resUpload && paymentPartner === "VNPAY") {
        handleReconcileVNPAY(values);
      } else {
        handleReconcileNotVNPAY(values);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
      setIsModalReconciliationProgress(false);
    }
  };

  useEffect(() => {
    dispatch(setLoadingApp(isLoading));
  }, [isLoading, dispatch]);

  return (
    <Form
      onFinish={(e) => authInfor && handleReconcile(e)}
      ref={formRef}
      layout="vertical"
    >
      <Box>
        <Box maxW="400px">
          <Form.Item
            labelAlign="left"
            label="Đối tác thanh toán"
            className="text-bold"
            name="partnerCode"
            // initialValue={listTgtt.filter((e) => e.status)?.[0]?.partnerCode}
            rules={[{ required: true, message: "Vui lòng chọn đối tác!" }]}
          >
            <Select
              className="w-full custom-input"
              onChange={setPaymentPartner}
              placeholder="Vui lòng chọn đối tác"
            >
              {listTgtt
                .filter((e) => e.status)
                .map((e) => (
                  <Option value={e.partnerCode} key={e.partnerCode}>
                    {e.partnerName}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Box>

        <Flex justifyContent={"space-between"} maxW="400px">
          <Form.Item
            name="date"
            label="Chọn ngày"
            rules={[{ required: true, message: "Vui lòng Chọn ngày đối soát" }]}
          >
            <DatePicker
              format={dateFormat}
              className="custom-input w-full"
              placeholder={"Chọn ngày"}
              style={{ maxWidth: "400px" }}
              disabledDate={(current: any) => {
                return (
                  new Date(
                    moment(current.toString()).endOf("day").format()
                  ).getTime() > new Date().getTime()
                );
              }}
            />
          </Form.Item>

          {paymentPartner !== "VNPAY" && (
            <Box maxW="400px" ml="2">
              <Form.Item
                labelAlign="left"
                label="Chọn tài khoản"
                className="text-bold"
                name="configCode"
                // initialValue={initValue?.status}
                rules={[
                  { required: true, message: "Vui lòng chọn tài khoản!" },
                ]}
              >
                <Select
                  className="w-full custom-input"
                  placeholder="Vui lòng chọn tài khoản"
                  onChange={setConfigCode}
                >
                  {listConfigCode.map((e: any) => (
                    <Option value={e.configCode} key={e.configName}>
                      {e.configName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Box>
          )}
        </Flex>
        {paymentPartner === "VNPAY" && (
          <Flex my="4" w={"100%"} alignItems="center">
            <Box>
              <MainButton
                text="Upload file"
                onClick={() => {
                  handleClick();
                  // setIsModalReconciliationProgress(true);
                }}
                classButton={!resUpload && "border-red"}
                icon={
                  <>
                    <IconUpload />
                  </>
                }
              />
              <input
                type="file"
                onChange={handleFileSelect}
                ref={hiddenFileInput}
                id="hiddenFileInput"
                style={{ display: "none" }}
              />
            </Box>
            {!resUpload && isReconciliation && (
              <Text pl="2" color={"red"}>
                Vui lòng upload file đối soát
              </Text>
            )}
            <Box display={"flex"} alignItems="center" pl="3">
              {resUpload && (
                <>
                  <IconSelected />
                  <Flex pl="2">
                    <Text color="#202027" fontSize="15px" fontWeight={500}>
                      Tổng cộng:{" "}
                    </Text>
                    <Text color="#202027" fontSize="15px" pl="1">
                      {" "}
                      {resUpload?.totalTransaction} giao dịch
                    </Text>
                  </Flex>
                </>
              )}
            </Box>
          </Flex>
        )}
        <MainButton
          text="Đối soát"
          classButton="color-FFA6A6 w-123"
          htmlType="submit"
          icon={
            <>
              <IconDownload />
            </>
          }
        />

        <HSeparator my="5" />
      </Box>
    </Form>
  );
};

export default Reconciliation;
