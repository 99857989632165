// Chakra imports
import { Box, Flex } from "@chakra-ui/react";
import { HorizonLogo } from "components/icons/HorizonLogo";
import { Text } from "@chakra-ui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { EnumPermission } from "util/Constant";

// Custom components

interface P {
  isCenter?: boolean;
}

const SidebarBrand: FC<P> = ({ isCenter }: P) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  return (
    <Flex
      alignItems={isCenter ? "center" : "flex-start"}
      flexDirection="column"
      cursor={"pointer"}
      paddingLeft={"19px"}
    >
      <Box
        mb="25px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {!authInfor?.avatarUrl ? (
          <HorizonLogo />
        ) : (
          <img
            src={authInfor?.avatarUrl}
            alt=""
            style={{ maxWidth: "140px", maxHeight: "55.367px" }}
          />
        )}
        <Box display="flex" flexDirection={"column"}>
          <Text
            ml={3}
            style={{
              color: "#9C9C9C",
              fontWeight: 700,
              fontSize: "26px",
              lineHeight: "1",
            }}
          >
            {authInfor?.accountType === EnumPermission.ADMIN
              ? "Admin Edubills"
              : authInfor?.accountType === EnumPermission.CSDT
              ? authInfor?.schoolCode
              : authInfor
              ? authInfor?.orgCode
              : "Edubills"}
          </Text>

          <Box ml={3} lineHeight="1">
            Education
          </Box>
        </Box>
      </Box>
      {/* <HSeparator mb="20px" /> */}
    </Flex>
  );
};

export default SidebarBrand;
