import { Box } from "@chakra-ui/react";
import { Button } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { ADMIN } from "util/Constant";
import OrganizationInformation from "./Components/OrganizationInformation";
import "./style.css";
import TrainingFacilitiesCpn from "components/trainingFacilities";
import { RootState } from "store";
import ModalAddTrainingFacilities from "../AddNewOrganization/Components/ModalAddTrainingFacilities";
import LoginAcoount from "./Components/LoginAcoount";
import { handleGetOrganztionDetail } from "util/general";

const OrganizationDetail = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;

  const [activeBtn, setAcitiveBtn] = useState(1);
  const [isVisibleModalAdd, setIsVisibleModalAdd] = useState(false);
  const ID = useMemo(() => window.location.hash.split("/")[3], []);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetOrganztionDetail(
      dispatch,
      ID,
      OrganizationAdminService.OrganizationDetail
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setActiveRoute("/organization-management"));
    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBreadcrumb([]));
      dispatch(setBrandText(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    detailOrganization &&
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );

    if (detailOrganization && activeBtn === 2) {
      const list = [
        {
          name: detailOrganization.orgName,
          link: `${ADMIN}/organization-detail/${detailOrganization.orgCode}`,
        },
        {
          name: "Tài khoản đăng nhập",
          link: `${ADMIN}/organization-detail/${detailOrganization.orgCode}`,
        },
      ];
      dispatch(setBreadcrumb(list));
    }
    if (detailOrganization && activeBtn === 3) {
      const list = [
        {
          name: detailOrganization.orgName,
          link: `${ADMIN}/organization-detail/${detailOrganization.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/organization-detail/${detailOrganization.orgCode}`,
        },
      ];
      dispatch(setBreadcrumb(list));
    }
    // eslint-disable-next-line
  }, [activeBtn, dispatch, detailOrganization]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <Box className="tabs-panel">
        <Button
          onClick={() => setAcitiveBtn(1)}
          className={`organization-information ${
            activeBtn === 1 ? "active" : ""
          }`}
        >
          Thông tin tổ chức
        </Button>
        <Button
          className={`login-account ${activeBtn === 2 ? "active" : ""}`}
          onClick={() => setAcitiveBtn(2)}
        >
          Tài khoản đăng nhập
        </Button>
        <Button
          onClick={() => setAcitiveBtn(3)}
          className={`training-facilities ${activeBtn === 3 ? "active" : ""}`}
        >
          Cơ sở đào tạo
        </Button>
      </Box>
      <Box>
        {activeBtn === 1 && <OrganizationInformation />}
        {activeBtn === 3 && (
          <TrainingFacilitiesCpn
            setVisibleModal={() => setIsVisibleModalAdd(true)}
          />
        )}
        {activeBtn === 2 && <LoginAcoount />}
      </Box>
      <ModalAddTrainingFacilities
        isOpen={isVisibleModalAdd}
        onClose={() => setIsVisibleModalAdd(false)}
        id={detailOrganization?.orgCode}
      />
    </Box>
  );
};

export default OrganizationDetail;
