import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Modal } from "antd";

import { HSeparator } from "components/separator/Separator";
import React from "react";
import { globalStyles } from "theme/styles";
import Cloes from "views/admin/OrganizationManagement/Icon/Cloes";

interface p {
  onClose: () => void;
  isOpen: boolean;
}

const ModalReconciliationFailed = ({ onClose, isOpen }: p) => {
  return (
    <Modal
      title="Đối soát giao dịch"
      open={isOpen}
      onCancel={onClose}
      footer={<></>}
      closeIcon={
        <>
          <Cloes />
        </>
      }
    >
      <HSeparator />
      <Box px="16px" py="16px">
        <Flex justifyContent="center" flexDirection="column">
          <Text
            color={globalStyles.colors.text[202027]}
            fontWeight={500}
            fontSize="18px"
            textAlign={"center"}
            my="4"
          >
            Đối soát thất bại
          </Text>
          <Image
            src={require("../../../../assets/img/dashboards/Error.png")}
            mx="auto"
            style={{ width: "30px" }}
          />
          {/* <img src={require("@assets/img/Error.png")} /> */}

          <Text
            my="4"
            color={globalStyles.colors.text[202027]}
            fontWeight={400}
            fontSize="14px"
            textAlign={"center"}
          >
            Lỗi hệ thống
          </Text>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ModalReconciliationFailed;
