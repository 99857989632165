import { Box, Flex, Text } from "@chakra-ui/react";
import IconEdit from "components/icons/IconEdit";
import IconRemove from "components/icons/IconRemove";

import { PaymentConfigPartnerACB } from "service/OrganizationAdmin/Organization";

import { globalStyles } from "theme/styles";

interface P {
  item: PaymentConfigPartnerACB;
  index: number;
  isEdit: boolean;
  handleEditRow: (index: number) => void;
  handleRemoveRow: (index: number) => void;
}

const RenderRow = ({
  item,
  handleRemoveRow,
  index,
  handleEditRow,
  isEdit,
}: P) => {
  return (
    <>
      {/* configName */}
      <td>
        <Box className="d-flex gap-10" cursor="pointer" position="relative">
          <Text color="#045993" fontSize="15px" fontWeight="400" p="5px" pl="0">
            {item.configName}
          </Text>
        </Box>
      </td>
      {/* clientId */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.clientId}
        </Text>
      </td>
      {/* clientSecret */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.clientSecret}
        </Text>
      </td>
      {/* providerId */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.providerId}
        </Text>
      </td>
      {/* customerCode */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.customerCode}
        </Text>
      </td>
      {/* customerName */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.customerName}
        </Text>
      </td>
      {/* accountNumber */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.accountNumber}
        </Text>
      </td>
      {/* virtualAccountPrefix */}
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.extraData.virtualAccountPrefix}
        </Text>
      </td>

      <td>
        {!isEdit && (
          <Flex pl="0" justifyContent={"start"} alignItems={"center"}>
            <Box
              pr="2"
              onClick={() => handleRemoveRow(index)}
              cursor="pointer"
              width="47.5px"
            >
              {item.canRemove && <IconRemove />}
            </Box>
            {/* )} */}
            <Box
              pr="20px"
              cursor="pointer"
              onClick={() => handleEditRow(index)}
            >
              <IconEdit />
            </Box>
          </Flex>
        )}
      </td>
    </>
  );
};

export default RenderRow;
