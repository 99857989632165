import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TransactionType } from "service/OrganizationAdmin/Organization";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { ADMIN, CODE_SUCCESS, CSDT, EnumPermission } from "util/Constant";
import ViewDetailTransaction from "./View";

const TransactionDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ID = useMemo(() => window.location.hash.split("/")[3], []);
  const [transaction, setTransaction] = useState<TransactionType | undefined>(
    undefined
  );
  const { appPermission } = useSelector<RootState, Type_Initstate>(
    (state) => state.app
  );

  // https://api.mybill.aqtech.edu.vn/api/payment-history?limit=20&offset=0&createdAt[sort]=desc

  const onHandleGetTransactionDetail = async () => {
    try {
      dispatch(setLoadingApp(true));
      let query = `?TransactionNo[eq]=${ID}`;

      // FIXME: change url to fix call API error
      const URL = "/payment-history";
      const res = await NetWorkService.Get({
        url: URL + query,
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setTransaction(respons.data.records[0]);
      } else {
        // setUsetInfor(undefined);
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    dispatch(setActiveRoute("/transaction-management"));
    dispatch(
      setBrandText({
        name: "Chi tiết giao dịch",
        link: `${appPermission === EnumPermission.ADMIN ? ADMIN : CSDT}/transaction-details/${ID}`,
      })
    );

    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBrandText());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onHandleGetTransactionDetail();
    // eslint-disable-next-line
  }, [ID]);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex
        alignItems={"center"}
        cursor="pointer"
        onClick={() =>
          history.push(
            `${
              appPermission === EnumPermission.ADMIN ? ADMIN : CSDT
            }/transaction-management`
          )
        }
        w={"fit-content"}
      >
        <ChevronLeftIcon color={"#045993"} />
        <Text
          color={"#045993"}
          fontSize="15px"
          fontWeight={600}
          textDecorationLine="underline"
        >
          Quay lại
        </Text>
      </Flex>

      <Text my="3" fontWeight={500} fontSize="15px" color="#202027">
        Thông tin chi tiết
      </Text>

      <ViewDetailTransaction transaction={transaction} />
    </Box>
  );
};

export default TransactionDetail;
