import { Box, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import Table from "components/table";
import moment from "moment";
import { globalStyles } from "theme/styles";

interface IProps {
  activityList: any[];
  totalRow: number;
  loading: boolean;

  pageSize: number;
  currentPage: number;
  handleSearch: (args: any) => Promise<void>;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains" | "lte",
    filterType: "number" | "text" | "date",
    showTime?: boolean
  ) => any;
}

const View = ({
  loading,
  activityList,
  pageSize,
  totalRow,
  currentPage,
  getColumnDateProps,
  onChangeCurrentPage,
  getColumnSearchProps,
}: IProps) => {
  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "7%",
      align: "center",
    },
    {
      title: "Tên tài khoản",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("user.username", "contains", "text"),
      render: (value) => (
        <span
          style={{
            cursor: "pointer",
            color: "#045993",
          }}
        >
          {value}
        </span>
      ),
    },
    {
      title: "Người thực hiện",
      dataIndex: "fullName",
      key: "fullName",
      ...getColumnSearchProps("user.fullName", "contains", "text"),
    },
    {
      title: "Thao tác",
      dataIndex: "activity",
      key: "activity",
      ...getColumnSearchProps("activity", "contains", "text"),
    },
    {
      title: "Thời gian thực hiện",
      dataIndex: "activityTime",
      key: "activityTime",
      ...getColumnDateProps("activityTime", "contains", "date"),
      render: (value) => (
        <span>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</span>
      ),
    },
  ];

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text fontWeight={600} color={"#202027"}>
          Nhật ký thao tác
        </Text>
      </Flex>
      <Table
        columns={columns}
        dataSource={activityList}
        text={"lần thao tác"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"1200px"}
      />
    </Box>
  );
};

export default View;
