import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { globalStyles } from "theme/styles";
import TabsPanel from "./Components/TabsPanel";
import FormBasicInformation from "./Components/FormBasicInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  setBrandText,
  setBreadcrumb,
  setSchoolDetail,
  Type_Initstate,
} from "store/appSlice";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { setLoadingApp } from "store/appSlice";
import { validResponse } from "util/API";
import { message } from "antd";
import { RootState } from "store";
import * as Sentry from "@sentry/browser";

const BasicInformation = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);

  const { GetDetailShool } = OrganizationAdminService;

  const handleGetDetail = useCallback(async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetDetailShool(orgCode, ID);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const response = res.data;
        dispatch(setSchoolDetail(response.data));
      } else {
        if (res && validResponse(res)) {
          message.error(`${res.data.data?.message}`);
          Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  }, [ID, orgCode, GetDetailShool, dispatch]);

  useEffect(() => {
    handleGetDetail();
    // eslint-disable-next-line
  }, [handleGetDetail]);

  useEffect(() => {
    if (schoolDetail) {
      const list = [
        {
          name: schoolDetail.orgName,
          link: `${ADMIN}/organization-detail/${schoolDetail.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${schoolDetail.orgCode}`,
        },
        {
          name: schoolDetail.schoolName,
          link: `${ADMIN}/${schoolDetail.orgCode}/school-information/${schoolDetail.schoolCode}`,
        },
      ];

      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );
      dispatch(setBreadcrumb(list));
    }
  }, [dispatch, schoolDetail]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={1} ID={ID} orgCode={orgCode} />
      <Box>
        <FormBasicInformation />
      </Box>
    </Box>
  );
};

export default BasicInformation;
