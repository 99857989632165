import { Box } from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { globalStyles } from "theme/styles";
import TabsPanel from "components/TabsPanel";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setBrandText, setBreadcrumb, Type_Initstate } from "store/appSlice";
import { ADMIN } from "util/Constant";
import TrainingFacilitiesCpn from "components/trainingFacilities";
import ModalAddTrainingFacilities from "./Components/ModalAddTrainingFacilities";
import { RootState } from "store";
import { handleGetOrganztionDetail } from "util/general";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";

const TrainingFacilities = () => {
  const [isVisibleModalAdd, setIsVisibleModalAdd] = useState(false);
  const dispatch = useDispatch();
  const orgCode = useMemo(() => window.location.hash.split("/")[3], []);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;

  useEffect(() => {
    !detailOrganization &&
      handleGetOrganztionDetail(
        dispatch,
        orgCode,
        OrganizationAdminService.OrganizationDetail
      );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (detailOrganization) {
      const list = [
        {
          name: detailOrganization.orgName,
          link: `${ADMIN}/add-new-organization/${orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${orgCode}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );
      dispatch(setBreadcrumb(list));
    }
  }, [detailOrganization, dispatch, orgCode]);

  return (
    <Box
      mt={10}
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      borderTopLeftRadius={0}
      position="relative"
    >
      <TabsPanel activeBtn={3} />
      <Box>
        <TrainingFacilitiesCpn
          setVisibleModal={() => setIsVisibleModalAdd(true)}
        />
      </Box>

      <ModalAddTrainingFacilities
        isOpen={isVisibleModalAdd}
        onClose={() => setIsVisibleModalAdd(false)}
        id={detailOrganization?.orgCode}
      />
    </Box>
  );
};

export default TrainingFacilities;
