import { Box } from "@chakra-ui/react";
import { Input } from "antd";
import React from "react";
import { PaymentConfigPartnerACB } from "service/OrganizationAdmin/Organization";

interface P {
  item: PaymentConfigPartnerACB;
  index: number;
  onChangeValue: (
    index: number,
    key: string,
    values: string,
    type: "nested" | "plane"
  ) => void;
}

const RenderRowInput = ({ item, index, onChangeValue }: P) => {
  return (
    <>
      {/* configName */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập tên tk"
            onChange={(e) =>
              onChangeValue(index, "configName", e.target.value, "plane")
            }
            defaultValue={item.configName}
          />
        </Box>
      </td>
      {/* clientId */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập client ID"
            onChange={(e) =>
              onChangeValue(index, "clientId", e.target.value, "nested")
            }
            defaultValue={item.extraData.clientId}
          />
        </Box>
      </td>
      {/* clientSecret */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập clientSecret"
            onChange={(e) =>
              onChangeValue(index, "clientSecret", e.target.value, "nested")
            }
            defaultValue={item.extraData.clientSecret}
          />
        </Box>
      </td>
      {/* providerId */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập providerId"
            onChange={(e) =>
              onChangeValue(index, "providerId", e.target.value, "nested")
            }
            defaultValue={item.extraData.providerId}
          />
        </Box>
      </td>
      {/* customerCode */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập customerCode"
            onChange={(e) =>
              onChangeValue(index, "customerCode", e.target.value, "nested")
            }
            defaultValue={item.extraData.customerCode}
          />
        </Box>
      </td>
      {/* customerName */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập customerName"
            onChange={(e) =>
              onChangeValue(index, "customerName", e.target.value, "nested")
            }
            defaultValue={item.extraData.customerName}
          />
        </Box>
      </td>
      {/* accountNumber */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập accountNumber"
            onChange={(e) =>
              onChangeValue(index, "accountNumber", e.target.value, "nested")
            }
            defaultValue={item.extraData.accountNumber}
          />
        </Box>
      </td>
      {/* virtualAccountPrefix */}
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập virtualAccountPrefix"
            onChange={(e) =>
              onChangeValue(
                index,
                "virtualAccountPrefix",
                e.target.value,
                "nested"
              )
            }
            defaultValue={item.extraData.virtualAccountPrefix}
          />
        </Box>
      </td>

      <td></td>
    </>
  );
};

export default RenderRowInput;
