import { Box, Flex } from "@chakra-ui/react";
import { Form, FormInstance, Input, Popconfirm, Select } from "antd";
import MainButton from "components/button";
import ButtonFooter from "components/ButtonFooter";
import "../UserManager.style.css";

import React, { FC, useEffect } from "react";
import { IUserAdmin } from "service/Auth";
import { PATTERN_PHONE, REGEX_USERNAME } from "util/Constant";
const { Option } = Select;

interface IProps {
  isDisabled: boolean;
  initData: IUserAdmin | undefined;
  onFinish: (values: any) => void;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  confirm: (e?: string) => void;
  cancel: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const FormAddUser: FC<IProps> = ({
  initData,
  onFinish,
  isDisabled,
  setIsDisabled,
  cancel,
  confirm,
}: IProps) => {
  const formRef = React.useRef<FormInstance>(null);
  // const [isEdit, setIsEdit] = useState(false);z

  const handleSetData = () => {
    formRef?.current?.setFieldsValue({
      fullName: initData.fullName,
      username: initData.username,
      personnelCode: initData.personnelCode,
      phone: initData.phone,
      email: initData.email,
      roles: initData.roles[0],
    });
  };

  useEffect(() => {
    initData && handleSetData();
    // eslint-disable-next-line
  }, [initData]);

  useEffect(() => {
    !isDisabled && initData && handleSetData();
    // eslint-disable-next-line
  }, [isDisabled]);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Box maxW={"755px"}>
        <Form.Item
          labelAlign="left"
          label="Họ và tên"
          name="fullName"
          rules={[{ required: true, message: "Yêu cầu nhập họ và tên!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Họ và tên"
            disabled={!isDisabled}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tên tài khoản"
          name="username"
          rules={[
            { required: true, message: "Yêu cầu nhập tên tài khoản!" },
            {
              pattern: REGEX_USERNAME,
              message:
                "Tên đăng nhập không được chứa khoảng trắng hoặc ký tự đặc biệt",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Tên tài khoản"
            disabled={!isDisabled || !!initData?.username}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã nhân sự"
          name="personnelCode"
          rules={[{ required: true, message: "Yêu cầu nhập mã nhân sự!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Mã nhân sự"
            disabled={!isDisabled}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              pattern: PATTERN_PHONE,
              message: "Vui lòng nhập đúng định dạng số điện thoại!",
            },
            {
              required: true,
              message: "Vui lòng nhập số điện thoại!",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Số điện thoại"
            disabled={!isDisabled}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Vui lòng nhập Email!" },
            { type: "email", message: "Vui lòng nhập đúng định dạng email!" },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Email"
            disabled={!isDisabled}
          />
        </Form.Item>

        <Form.Item
          name="roles"
          labelAlign="left"
          label="Quyền"
          rules={[{ required: true, message: "Yêu cầu chọn quyền!" }]}
          initialValue={"APPROVE_UPDATE_REFUND_VNP"}
        >
          <Select
            placeholder="Quyền"
            className="w-full custom-input"
            defaultValue={"APPROVE_UPDATE_REFUND_VNP"}
            disabled={!isDisabled}
          >
            <Option value="APPROVE_UPDATE_REFUND_VNP">Quản lý</Option>
            <Option value="APPROVE_VIEW">Nhân viên</Option>
          </Select>
        </Form.Item>

        {initData ? (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            // mt="6"
          >
            <ButtonFooter
              isEdit={isDisabled}
              setIsEdit={setIsDisabled}
              text2={"Cập nhật"}
              formRef={formRef}
            />

            {isDisabled && (
              <>
                {initData.status === 1 ? (
                  <Popconfirm
                    title="Xác nhận vô hiệu hoá tài khoản"
                    description="Bạn có chắc chắn muốn thực hiện thao tác này không?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => confirm()}
                    onCancel={cancel}
                    className="py-2 custom-popconfirm"
                  >
                    <MainButton
                      text="Vô hiệu hoá TK"
                      classButton={"ml-3 button-danger"}
                    />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    title="Xác nhận kích hoạt lại tài khoản"
                    description="Bạn có chắc chắn muốn thực hiện thao tác này không?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => confirm('active')}
                    onCancel={cancel}
                    className="py-2 custom-popconfirm"
                  >
                    <MainButton
                      text="Kích hoạt tài khoản"
                      classButton={"ml-3 button-info"}
                    />
                  </Popconfirm>
                )}
              </>
            )}
          </Flex>
        ) : (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <MainButton htmlType="submit" text="Thêm mới" />
          </Flex>
        )}
      </Box>
    </Form>
  );
};

export default FormAddUser;
