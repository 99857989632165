import { Box, Button as ChakraButton, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { globalStyles } from "theme/styles";
import { formatMoney } from "util/general";
import moment from "moment";
import Table from "components/table";
import { Button } from "antd";
import MainButton from "components/button";
import { useHistory } from "react-router-dom";
import { CSDT, EnumRoles } from "util/Constant";
import { IRefundHistory } from "types/general";
import ModalConfirm from "./ModalConfirm";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";

interface IProps {
  billList: any[];
  totalRow: number;
  loading: boolean;
  pageSize: number;
  isVisible: boolean;
  currentPage: number;
  selectedRows: IRefundHistory[];
  rowSelection: {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => void;
  };
  setIsvisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleApproveRefund: () => Promise<void>;
  handleRejectRefund: () => Promise<void>;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
}

const View = ({
  loading,
  billList,
  pageSize,
  totalRow,
  currentPage,
  selectedRows,
  isVisible,
  setIsvisible,
  getColumnDateProps,
  onChangeCurrentPage,
  handleApproveRefund,
  handleRejectRefund,
  getColumnSearchProps,
  rowSelection,
}: IProps) => {
  const history = useHistory();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [textModal, setTextModal] = useState<string>("");

  const columns: ColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "175px",
      render: (value) => (
        <Box pr="22px">
          {value ? moment(value).format("HH:mm:ss DD/MM/YYYY") : ""}
        </Box>
      ),
      ...getColumnDateProps("createdAt", "eq", "date"),
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "orderId",
      key: "orderId",
      width: "150px",
      ...getColumnSearchProps("orderId", "contains", "text"),
    },
    {
      title: "Người tạo",
      dataIndex: "createdBy",
      key: "createdBy",
      width: "150px",
      ...getColumnSearchProps("createdBy", "contains", "text"),
    },
    {
      title: "Tên sinh viên",
      dataIndex: "ten_sv",
      key: "ten_sv",
      width: "150px",
      ...getColumnSearchProps("ten_sv", "contains", "text"),
    },
    {
      title: "Mã sinh viên",
      dataIndex: "ma_sv",
      key: "ma_sv",
      width: "150px",
      ...getColumnSearchProps("ma_sv", "contains", "text"),
    },
    {
      title: "Số phiếu thu",
      dataIndex: "so_phieu_bao",
      key: "so_phieu_bao",
      width: "150px",
      ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
    },
    {
      title: "Giá trị (VND)",
      dataIndex: "amount",
      key: "amount",
      width: "150px",
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
      align: "right",
      ...getColumnSearchProps("amount", "eq", "text"),
    },
    {
      title: "Lý do",
      dataIndex: "reason",
      key: "reason",
      // width: "210px",
      ...getColumnSearchProps("reason", "eq", "text"),
    },
  ];

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text color={globalStyles.colors.text[202027]} fontWeight="600">
          Danh sách yêu cầu hoàn tiền
        </Text>
        <ChakraButton
          colorScheme="whatsapp"
          backgroundColor={"#5FAD67"}
          borderRadius="6px"
          onClick={() => history.push(`${CSDT}/refund-management`)}
        >
          Tạo giao dịch
        </ChakraButton>
      </Flex>
      <Table
        columns={columns}
        dataSource={billList}
        text={"yêu cầu"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"1500px"}
        type="radio"
        rowSelection={rowSelection}
      />
      {authInfor?.roles.length && authInfor?.roles[0] === EnumRoles.ADMIN && (
        <Flex
          flexDirection={"row"}
          alignContent="center"
          justifyContent="flex-end"
          mt="4"
        >
          <Box mr="2">
            <MainButton
              disabled={selectedRows.length === 0}
              htmlType="submit"
              text={"Phê duyệt"}
              onClick={() => {
                setIsvisible(true);
                setTextModal("phê duyệt");
              }}
            />
          </Box>
          <Button
            className="custom-button color-FFA6A6"
            // styles={{ color: "#FFA6A6" }}
            disabled={selectedRows.length === 0}
            style={{
              width: "95px",
            }}
            onClick={() => {
              setIsvisible(true);
              setTextModal("từ chối");
            }}
          >
            Từ chối
          </Button>
        </Flex>
      )}
      <ModalConfirm
        text={textModal}
        isOpen={isVisible}
        onClose={() => setIsvisible(false)}
        handleApproveRefund={handleApproveRefund}
        handleRejectRefund={handleRejectRefund}
      />
    </Box>
  );
};

export default View;
