import { Box } from "@chakra-ui/react";
import { Col, Form, FormInstance, Input, InputNumber, Row, Select } from "antd";
import React, { memo, useEffect, useState } from "react";
import { IBill } from "service/Bill/Bill";

const { Option } = Select;
interface P {
  initValue: any;
  selectedRows: IBill[];
  formRef: React.MutableRefObject<FormInstance<any>>;
}

const FormRefund = ({ formRef, initValue, selectedRows }: P) => {
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    // console.log(selectedRows);
    if (selectedRows.length !== 0) {
      formRef?.current.setFieldsValue({
        amount: selectedRows[0].amount,
      });
      formRef?.current.setFieldsValue({
        orderId: selectedRows[0].orderId,
      });
    }
    // eslint-disable-next-line
  }, [selectedRows]);

  useEffect(() => {
    if (initValue) {
      formRef?.current.setFieldsValue({
        ten_sv: initValue.HoTen,
      });
    } else {
      formRef?.current.resetFields();
    }

    // eslint-disable-next-line
  }, [initValue]);

  return (
    <Form
      name="basic"
      autoComplete="off"
      labelCol={{ span: 6 }}
      ref={formRef}
      layout="vertical"
    >
      <Box mt="3">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="ten_sv" label="Tên sinh viên" labelAlign="left">
              <Input
                className="custom-input "
                placeholder="Tên sinh viên"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="orderId"
              label="Mã đơn hàng"
              labelAlign="left"
              rules={[
                { required: true, message: "Mã đơn hàng không được để trống" },
              ]}
            >
              <Input
                className="custom-input"
                placeholder="Mã đơn hàng"
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="transType"
              label="Loại hoàn tiền"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Loại hoàn tiền không được để trống",
                },
              ]}
            >
              <Select
                className="w-full custom-input"
                style={{
                  height: "40px",
                }}
                onChange={(e) => setDisable(e !== "03")}
              >
                <Option value="02">Hoàn toàn bộ</Option>
                <Option value="03">Hoàn một phần</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="amount"
              label="Giá trị hoàn (VND)"
              labelAlign="left"
              rules={[
                { required: true, message: "Giá trị hoàn không được để trống" },
              ]}
            >
              <InputNumber
                onChange={(e) => console.log(e)}
                disabled={disable}
                formatter={(e) => `${e}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                className="custom-input w-full"
                placeholder="Giá trị hoàn"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="reason"
              label="Lý do"
              labelAlign="left"
              rules={[{ required: true, message: "Lý do không được để trống" }]}
            >
              <Input.TextArea showCount maxLength={200} />
            </Form.Item>
          </Col>
        </Row>
      </Box>
    </Form>
  );
};

export default memo(FormRefund);
