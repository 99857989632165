import { Box } from "@chakra-ui/react";
import { Input } from "antd";
import React from "react";
import { ITID } from "../..";

interface P {
  item: ITID;
  index: number;
  onChangeValue: (index: number, key: string, values: string) => void;
}

const RenderRowInput = ({ item, index, onChangeValue }: P) => {
  return (
    <>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập tên"
            onChange={(e) => onChangeValue(index, "TIDName", e.target.value)}
            defaultValue={item.TIDName}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập mã"
            onChange={(e) => onChangeValue(index, "tmnCode", e.target.value)}
            defaultValue={item.tmnCode}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập mã"
            onChange={(e) =>
              onChangeValue(index, "tmnHashSecret", e.target.value)
            }
            defaultValue={item.tmnHashSecret}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập stk"
            onChange={(e) =>
              onChangeValue(index, "bankAccountNumber", e.target.value)
            }
            defaultValue={item.bankAccountNumber}
          />
        </Box>
      </td>
      <td>
        <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập ghi chú"
            onChange={(e) => onChangeValue(index, "note", e.target.value)}
            defaultValue={item.note}
          />
        </Box>
      </td>
      <td>
        {/* <Box p="3" pl="0">
          <Input
            className="custom-input"
            placeholder="Nhập ghi chú"
            onChange={(e) => onChangeValue(index, "desc", e.target.value)}
            defaultValue={item.desc}
          />
        </Box> */}
      </td>
    </>
  );
};

export default RenderRowInput;
