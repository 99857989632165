import { Button, Flex, Text } from "@chakra-ui/react";
import { DatePicker, Form, message, Select } from "antd";
import { HSeparator } from "components/separator/Separator";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import OsaService from "service/OSA/Osa";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { globalStyles } from "theme/styles";
import { validResponse } from "util/API";
import { CODE_SUCCESS, dateFormat } from "util/Constant";

const { Option } = Select;
const { RangePicker } = DatePicker;

export enum ReportClassifyEnum {
  TRANSACTION = "TRANSACTION",
  TRANSACTION_SUMMARY = "TRANSACTION_SUMMARY",
}
const ViewReport = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [reportClassify, setReportClassify] = useState<ReportClassifyEnum>(
    ReportClassifyEnum.TRANSACTION
  );

  const { ReportDaily, ReportSummaryTrans } = OrganizationAdminService;

  const handleDownloadExcel = async (args: {
    orgCode: string;
    schoolCode: string;
    id: string;
    fileName: string;
  }) => {
    try {
      const { id, orgCode, schoolCode, fileName } = args;

      const res = await OsaService.RECONCILE_DOWNLOAD(orgCode, schoolCode, id);
      if (res.status) {
        // create element
        const urlDownload = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = urlDownload;
        link.setAttribute("download", fileName);
        link.click();
        message.success("Tải báo cáo thành công");
      } else {
        message.error(`Không thể xuất báo cáo. Vui lòng thử lại`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exportReport = async (
    classify: ReportClassifyEnum,
    payload: { orgCode: string; schoolCode: string; day: string | string[] }
  ) => {
    switch (classify) {
      case ReportClassifyEnum.TRANSACTION:
        const transPayload = {
          reportClassify,
          day: moment(new Date(payload.day as string)).format("YYYY-MM-DD"),
        };
        const transReport: any = await ReportDaily(
          payload.orgCode,
          payload.schoolCode,
          transPayload
        );

        const isExportTransSuccess =
          transReport &&
          validResponse(transReport) &&
          transReport.data.code === CODE_SUCCESS &&
          transReport.data.data.success !== false;

        if (!isExportTransSuccess) {
          const msgErr =
            transReport && validResponse(transReport)
              ? transReport.data.data.message
              : "Có lỗi xảy ra !";
          message.error(msgErr);
        }

        handleDownloadExcel({
          fileName: transReport.data.data.fileName,
          id: transReport.data.data.fileId,
          orgCode: payload.orgCode,
          schoolCode: payload.schoolCode,
        });

        return true;

      case ReportClassifyEnum.TRANSACTION_SUMMARY:
        const summaryPayload = {
          fromDate: moment(new Date(payload.day[0])).format("YYYY-MM-DD"),
          toDate: moment(new Date(payload.day[1])).format("YYYY-MM-DD"),
        };
        const summaryReport: any = await ReportSummaryTrans(
          payload.orgCode,
          payload.schoolCode,
          summaryPayload
        );

        const isExportSummarySuccess =
          summaryReport &&
          validResponse(summaryReport) &&
          summaryReport.data.code === CODE_SUCCESS &&
          summaryReport.data.data.success !== false;

        if (!isExportSummarySuccess) {
          const msgErr =
            summaryReport && validResponse(summaryReport)
              ? summaryReport.data.data.message
              : "Có lỗi xảy ra !";
          message.error(msgErr);
        }

        const fromDateFormat = moment(new Date(payload.day[0])).format(
          "DDMMYY"
        );
        const todateFromat = moment(new Date(payload.day[1])).format("DDMMYY");
        const fileName = `edubill_summary_transaction_report_${fromDateFormat}_${todateFromat}.xlsx`;

        handleDownloadExcel({
          fileName: fileName,
          id: summaryReport.data.data._id,
          orgCode: payload.orgCode,
          schoolCode: payload.schoolCode,
        });

        return true;
      default:
        break;
    }
  };

  const onFinish = async (value: any) => {
    try {
      dispatch(setLoadingApp(true));
      const { reportClassify, day } = value;

      return await exportReport(reportClassify, {
        orgCode: authInfor?.orgCode,
        schoolCode: authInfor?.schoolCode,
        day,
      });
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      // Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
      // onClose();
    }
  };

  const generateDateField = (key: ReportClassifyEnum) => {
    switch (key) {
      case ReportClassifyEnum.TRANSACTION:
        return (
          <Form.Item
            name="day"
            label="Ngày"
            labelAlign="left"
            rules={[{ required: true, message: "Vui lòng chọn ngày" }]}
          >
            <DatePicker
              format={dateFormat}
              className="custom-input w-full"
              placeholder="Chọn ngày"
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
        );

      case ReportClassifyEnum.TRANSACTION_SUMMARY:
        return (
          <Form.Item
            name="day"
            label="Từ ngày - đến ngày"
            labelAlign="left"
            rules={[{ required: true, message: "Vui lòng chọn ngày" }]}
          >
            <RangePicker
              format={dateFormat}
              className="custom-input w-full"
              placeholder={["Từ ngày", "đến ngày"]}
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
            />
          </Form.Item>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    reportClassify && form.setFieldsValue({ day: undefined });
    // eslint-disable-next-line
  }, [reportClassify]);

  return (
    <>
      <Form name="report" onFinish={onFinish} labelCol={{ span: 3 }} form={form}>
        <Form.Item
          name="reportClassify"
          label="Mẫu báo cáo"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng chọn mẫu báo cáo" }]}
        >
          <Select
            className="w-full custom-input"
            placeholder="Chọn mẫu báo cáo"
            onChange={(e) => setReportClassify(e)}
          >
            <Option value={ReportClassifyEnum.TRANSACTION}>
              Giao dịch theo ngày
            </Option>
            <Option value={ReportClassifyEnum.TRANSACTION_SUMMARY}>
              Tổng hợp giao dịch
            </Option>
          </Select>
        </Form.Item>
        {generateDateField(reportClassify)}

        <HSeparator mb="4" />

        <Flex justifyContent="space-between" alignItems="center">
          <Text
            color={globalStyles.colors.text[202027]}
            fontWeight="500"
            fontSize="15px"
            mb="4"
          >
            Xem trước
          </Text>

          <Button
            colorScheme="whatsapp"
            backgroundColor={"#5FAD67"}
            borderRadius="6px"
            type="submit"
            // htmlType="submit"
          >
            <i
              className="fa-solid fa-download "
              style={{
                color: "#fff",
              }}
            />
            <Text ml="2" color={"#fff"}>
              Excel
            </Text>
          </Button>
        </Flex>
      </Form>
    </>
  );
};

export default ViewReport;
