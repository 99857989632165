import { Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CODE_SUCCESS } from "util/Constant";
import FormOrganization from "./FormOrganization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { message } from "antd";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { useEffect, useMemo } from "react";
import { RootState } from "store";
import { OrganizationInput } from "types/general";
import { handleGetOrganztionDetail, handleThrowError } from "util/general";
import * as Sentry from "@sentry/browser";

const OrganizationInformation = () => {
  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;
  const ID = useMemo(() => window.location.hash.split("/")[3], []);
  const { OrganizationUpdate } = OrganizationAdminService;

  const onFinish = async (values: OrganizationInput) => {
    try {
      const dataPost = {
        ...values,
        startTime: new Date(values.startTime).getTime(),
        endTime: new Date(values.endTime).getTime(),
        status:
          values.status === "Hoạt động"
            ? 1
            : values.status === "Không hoạt động"
            ? 0
            : +values.status,
      };
      dispatch(setLoadingApp(true));
      const res = await OrganizationUpdate(
        detailOrganization.orgCode,
        dataPost
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        handleGetOrganztionDetail(
          dispatch,
          ID,
          OrganizationAdminService.OrganizationDetail
        );
        message.success("Cập nhật thành công");
      } else {
        if (res && validResponse(res)) {
          // message.error(`${res.data.data?.message}`);
          handleThrowError(res.data.data?.errors ? res.data.data?.errors : res.data.data?.message);
          res.data.data?.errors ? Sentry.captureException(res.data.data?.errors) : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    handleGetOrganztionDetail(
      dispatch,
      ID,
      OrganizationAdminService.OrganizationDetail
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Box mt="5">
      <FormOrganization onFinish={onFinish} />
    </Box>
  );
};

export default OrganizationInformation;
