import { Box, Flex } from "@chakra-ui/react";
import { DatePicker, Select, Input, Switch } from "antd";
import dayjs from "dayjs";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  ClientCode,
  ItypeFee,
  PaymentConfigNavigation,
  PaymentConfigPartnerACB,
  PaymentMethodDetail,
} from "service/OrganizationAdmin/Organization";
import { ENABLE, DISABLE } from "util/Constant";
import { IArgs } from ".";

interface P {
  index: number;
  prioritys: number[];
  listTID: PaymentConfigPartnerACB[];
  typeFee: ItypeFee[];
  item: PaymentConfigNavigation;
  paymentMethod: PaymentMethodDetail;
  onChangeValue: ({
    index,
    isNavigateRules,
    name,
    valueName,
    values,
    rule,
  }: IArgs) => void;
  isDefaultItem: boolean;
  listClientCode: ClientCode[];
}

const { Option } = Select;

const RenderRowInput = ({
  item,
  index,
  listTID,
  typeFee,
  prioritys,
  isDefaultItem,
  paymentMethod,
  onChangeValue,
  listClientCode,
}: P) => {
  const { RangePicker } = DatePicker;
  const [hiddenTransAmount, setHiddenTransAmount] = useState(false);
  const [hiddenAccumulationTrans, setHiddenAccumulationTrans] = useState(false);
  // const [listpaymentMethod, sePaymentMethod] = useState<PaymentMethodDetail.>([]);
  const isDateInRange = useMemo(
    () =>
      dayjs(item.periodTo).format("DD/MM/YYYY") >=
        dayjs().format("DD/MM/YYYY") &&
      dayjs(item.periodFrom).format("DD/MM/YYYY") <=
        dayjs().format("DD/MM/YYYY"),
    [item]
  );
  const checkActiveStatus = useMemo(
    () =>
      dayjs(item.periodTo).isAfter(dayjs().add(-1, "day")) &&
      item.status === ENABLE,
    [item]
  );
  const [isActive, setIsActive] = useState(checkActiveStatus);

  useEffect(() => {
    onChangeValue({
      index,
      isNavigateRules: false,
      name: "status",
      valueName: "",
      values: isActive ? ENABLE : DISABLE,
    });
    // eslint-disable-next-line
  }, [isActive]);


  // get payment method
  const renderPaymentMethod = useCallback(() => {
    const allMethod = { _id: "all", name: "Tất cả" };
    if (paymentMethod) {
      const listFilter = paymentMethod.paymentMethod.filter(
        (item) => item.status === 1
      );
      const listRender = [allMethod, ...listFilter];
      return listRender.map((item) => (
        <Option value={item._id} key={item._id}>
          {item.name}
        </Option>
      ));
    }
    return [];
  }, [paymentMethod]);

  const handleGetKeyRender = (key: string, isUseValue?: boolean) => {
    if (item.navigateRules.length < 1) {
      return "";
    }
    const valueName = item.navigateRules.find((e) => e.key === key);
    return valueName
      ? `${!isUseValue ? valueName.valueName : valueName.valueCode}`
      : "";
  };

  useEffect(() => {
    setHiddenTransAmount(
      !!item.navigateRules.find((e) => e.key === "transAmount")
    );
    setHiddenAccumulationTrans(
      !!item.navigateRules.find((e) => e.key === "accumulationTrans")
    );
  }, [item]);

  const onChangeStatus = (checked: boolean) => {
    setIsActive(checked);
  };

  return (
    <>
      {/* piority */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e: any) =>
              onChangeValue({
                index,
                isNavigateRules: false,
                name: "priority",
                valueName: "",
                values: e,
              })
            }
            defaultValue={item.priority}
            disabled={isDefaultItem}
          >
            {prioritys.map((e) => (
              <Select.Option value={e} key={e}>{`${e}`}</Select.Option>
            ))}
          </Select>
        </Box>
      </td>
      {/* configuration */}
      <td>
        <Input
          className="custom-input"
          placeholder="Nhập tên cấu hình"
          onChange={(e) =>
            onChangeValue({
              index,
              isNavigateRules: false,
              name: "navRuleName",
              valueName: "",
              values: e.target.value,
            })
          }
          defaultValue={
            item.navRuleName === "default" ? "Mặc định" : item.navRuleName
          }
          disabled={isDefaultItem}
        />
      </td>
      {/* clientCode */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) =>
              onChangeValue({
                index,
                isNavigateRules: true,
                name: "clientCode",
                valueName: e,
                values: e,
                rule: "eq",
              })
            }
            defaultValue={
              !handleGetKeyRender("clientCode")
                ? "Tất cả"
                : handleGetKeyRender("clientCode")
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            {listClientCode
              ?.filter((item) => item._id !== "all")
              ?.map((e, i) => (
                <Select.Option value={e._id} key={i}>
                  {e.name === "s5wXjKJzrZ0"
                    ? "EDUBILL"
                    : e.name === "cx615qhsFTL"
                    ? "EDUSOFT"
                    : e.name}
                </Select.Option>
              ))}
          </Select>
        </Box>
      </td>
      {/* groupStudent*/}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) =>
              onChangeValue({
                index,
                isNavigateRules: true,
                name: "groupStudent",
                valueName: e,
                values: e,
              })
            }
            defaultValue={
              !handleGetKeyRender("groupStudent")
                ? "Tất cả"
                : handleGetKeyRender("groupStudent")
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
          </Select>
        </Box>
      </td>
      {/* accumulationTrans */}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Select
            className="w-full custom-input mw-100 "
            onChange={(e) => {
              onChangeValue({
                index,
                isNavigateRules: true,
                name: "accumulationTrans",
                valueName:
                  item.navigateRules.find((e) => e.key === "accumulationTrans")
                    ?.valueName || "",
                values:
                  item.navigateRules.find((e) => e.key === "accumulationTrans")
                    ?.valueCode || "",
                rule: e,
              });
              setHiddenAccumulationTrans(e !== "all");
            }}
            defaultValue={
              item.navigateRules.find((e) => e.key === "accumulationTrans")
                ? item.navigateRules.find((e) => e.key === "accumulationTrans")
                    ?.rule
                : "all"
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            <Select.Option value={"lt"}>{">"}</Select.Option>
            <Select.Option value={"gt"}>{"<"}</Select.Option>
            <Select.Option value={"eq"}>{"="}</Select.Option>
            <Select.Option value={"lte"}>{">="}</Select.Option>
            <Select.Option value={"gte"}>{"<="}</Select.Option>
          </Select>
          {hiddenAccumulationTrans && (
            <Input
              className="custom-input"
              placeholder="Nhập số giao dịch tích lũy"
              onChange={(e) =>
                onChangeValue({
                  index,
                  isNavigateRules: true,
                  name: "accumulationTrans",
                  valueName: e.target.value,
                  values: e.target.value,
                  rule: item.navigateRules.find(
                    (e) => e.key === "accumulationTrans"
                  ).rule,
                })
              }
              defaultValue={handleGetKeyRender("accumulationTrans", true)}
              disabled={isDefaultItem}
              type="number"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
            />
          )}
        </Flex>
      </td>
      {/* paymentMethod */}
      <td>
        <Select
          className="w-full custom-input"
          onChange={(e) =>
            onChangeValue({
              index,
              isNavigateRules: true,
              name: "paymentMethod",
              valueName: e,
              values: e,
              rule: "eq",
            })
          }
          defaultValue={
            !handleGetKeyRender("paymentMethod")
              ? "Tất cả"
              : handleGetKeyRender("paymentMethod")
          }
          disabled={isDefaultItem}
        >
          {renderPaymentMethod()}
        </Select>
      </td>
      {/* typeFee */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) =>
              onChangeValue({
                index,
                isNavigateRules: true,
                name: "typeFee",
                valueName:
                  typeFee.find((el) => el.typeFeeCode === e).typeFeeName || "",
                values: e,
                rule: "eq",
              })
            }
            defaultValue={
              !handleGetKeyRender("typeFee")
                ? "Tất cả"
                : handleGetKeyRender("typeFee")
            }
            disabled={isDefaultItem}
          >
            {typeFee?.map((e, i) => (
              <Select.Option value={e.typeFeeCode} key={i}>
                {e.typeFeeName}
              </Select.Option>
            ))}
          </Select>
        </Box>
      </td>
      {/* transAmount */}
      <td>
        <Flex p="3" pl="0" gap={2}>
          <Select
            className="w-full custom-input mw-100"
            onChange={(e) => {
              onChangeValue({
                index,
                isNavigateRules: true,
                name: "transAmount",
                valueName:
                  item.navigateRules.find((e) => e.key === "transAmount")
                    ?.valueName || "",
                values:
                  item.navigateRules.find((e) => e.key === "transAmount")
                    ?.valueCode || "",
                rule: e,
              });
              setHiddenTransAmount(e !== "all");
            }}
            defaultValue={
              item.navigateRules.find((e) => e.key === "transAmount")
                ? item.navigateRules.find((e) => e.key === "transAmount").rule
                : "all"
            }
            disabled={isDefaultItem}
          >
            <Select.Option value={"all"}>{"Tất cả"}</Select.Option>
            <Select.Option value={"lt"}>{">"}</Select.Option>
            <Select.Option value={"gt"}>{"<"}</Select.Option>
            <Select.Option value={"eq"}>{"="}</Select.Option>
            <Select.Option value={"lte"}>{">="}</Select.Option>
            <Select.Option value={"gte"}>{"<="}</Select.Option>
          </Select>
          {hiddenTransAmount && (
            <Input
              className="custom-input"
              placeholder="Nhập giá trị giao dịch"
              onChange={(e) =>
                onChangeValue({
                  index,
                  isNavigateRules: true,
                  name: "transAmount",
                  valueName: e.target.value,
                  values: e.target.value,
                  rule: item.navigateRules.find((e) => e.key === "transAmount")
                    .rule,
                })
              }
              defaultValue={handleGetKeyRender("transAmount", true)}
              disabled={isDefaultItem}
              type="number"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
            />
          )}
        </Flex>
      </td>
      {/* configCode */}
      <td>
        <Box p="3" pl="0">
          <Select
            className="w-full custom-input"
            onChange={(e) =>
              onChangeValue({
                index,
                isNavigateRules: false,
                name: "configCode",
                valueName: e,
                values: e,
                rule: "eq",
              })
            }
            defaultValue={item.configCode}
          >
            {listTID.map((e, i) => (
              <Select.Option value={e.configCode} key={i}>
                {e.configName}
              </Select.Option>
            ))}
          </Select>
        </Box>
      </td>

      {/* effectiveDate */}
      <td>
        <Box p="3" pl="0">
          <RangePicker
            className="w-full custom-input mw-250"
            placeholder={["Từ ngày", "Đến ngày"]}
            format={"YYYY/MM/DD"}
            onChange={(_, dateStrings: string[]) => {
              const isActiveSwitch =
                dateStrings.join("").length > 0 &&
                dayjs(dateStrings[1]).isAfter(dayjs().add(-1, "day"));
              setIsActive(isActiveSwitch);
              onChangeValue({
                index,
                isNavigateRules: false,
                name: "effectiveDate",
                valueName: "",
                values: dateStrings,
              });
            }}
            defaultValue={[dayjs(item.periodFrom), dayjs(item.periodTo)]}
            disabled={[isDefaultItem, isDefaultItem]}
          />
        </Box>
      </td>
      {/* status */}
      <td>
        <Switch
          checked={isDefaultItem || isActive}
          disabled={isDefaultItem || !isDateInRange}
          onChange={onChangeStatus}
        />
      </td>
    </>
  );
};

export default RenderRowInput;
