import { Box, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { globalStyles } from "theme/styles";
import { ADMIN, CSDT, EnumPermission } from "util/Constant";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Type_Initstate } from "store/appSlice";
import { formatMoney } from "util/general";
import moment from "moment";
import HeaderFilter from "components/headerFilter";
import Table from "components/table";
import { useHistory } from "react-router-dom";

interface IProps {
  billList: any[];
  totalRow: number;
  loading: boolean;

  pageSize: number;
  currentPage: number;
  handleSearch: (args: any) => Promise<void>;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnDateProps: (
    dataIndex: any,
    type: "eq" | "contains" | "lte",
    filterType: "number" | "text" | "date",
    showTime?: boolean
  ) => any;
  getColumnSelectProps: (dataIndex: any) => any;
}

const View = ({
  loading,
  billList,
  pageSize,
  totalRow,
  currentPage,
  handleSearch,
  getColumnDateProps,
  onChangeCurrentPage,
  getColumnSearchProps,
  getColumnSelectProps,
}: IProps) => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { appPermission } = appState;
  const history = useHistory();
  const [columns, setColumns] = useState<ColumnsType<any>>([
    {
      title: "Thời gian",
      dataIndex: "createDate",
      key: "createDate",
      width: "190px",
      ...getColumnDateProps("createDate", "lte", "date"),
      render: (value) => (
        <span>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</span>
      ),
    },
    {
      title: "Mã giao dịch",
      dataIndex: "TransactionNo",
      key: "TransactionNo",
      width: "140px",
      ...getColumnSearchProps("TransactionNo", "contains", "text"),
      render: (value, _) => (
        <Text
          textDecoration={"underline"}
          color={"#045993"}
          cursor="pointer"
          onClick={() => {
            history.push(`${ADMIN}/transaction-details/${_.TransactionNo}`);
          }}
        >
          {value}
        </Text>
      ),
    },
    {
      title: "Mã đơn hàng",
      dataIndex: "orderId",
      key: "orderId",
      width: "250px",
      ...getColumnSearchProps("orderId", "eq", "text"),
      render: (value) => <Text color={"#045993"}>{value}</Text>,
    },
    {
      title: "mã hoá đơn",
      dataIndex: "transactionCode",
      key: "transactionCode",
      // width: "250px",
      ...getColumnSearchProps("transactionCode", "eq", "text"),
      render: (value) => <Text color={"#045993"}>{value}</Text>,
    },
    {
      title: "Tên tài khoản",
      dataIndex: "orderId1",
      key: "orderId1",
      width: "250px",
      ...getColumnSearchProps("orderId", "eq", "text"),
      render: (value) => <Text color={"#045993"}>{value}</Text>,
    },

    {
      title: "Tên cơ sở",
      dataIndex: "schoolName",
      key: "schoolName",
      width: "300px",
      render: (value) => <div>{value}</div>,
    },
    {
      title: "Mã cơ sở",
      dataIndex: "schoolCode",
      key: "schoolCode",
      width: "150px",
      ...getColumnSearchProps("schoolCode", "contains", "text"),
    },
    {
      title: "Mã tổ chức",
      dataIndex: "orgCode",
      key: "orgCode",
      width: "150px",
      ...getColumnSearchProps("orgCode", "contains", "text"),
    },
    {
      title: "Mã sinh viên",
      dataIndex: "ma_sv",
      key: "ma_sv",
      width: "250px",
      ...getColumnSearchProps("ma_sv", "contains", "text"),
    },
    {
      title: "Số phiếu thu",
      dataIndex: "so_phieu_bao",
      key: "so_phieu_bao",
      width: "140px",
      ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
      render: (value) => (
        <Text className="text-ellipsis" color={"#045993"}>
          {value}
        </Text>
      ),
    },
    {
      title: "Nội dung",
      dataIndex: "noi_dung",
      key: "noi_dung",
      // width: "22%",
      render: (value) => (
        <div
          // className="text-ellipsis"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      ...getColumnSearchProps("noi_dung", "contains", "text"),
    },
    {
      title: "Giá trị (VNĐ)",
      dataIndex: "amount",
      key: "amount",
      render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
      align: "end",
      ...getColumnSearchProps("amount", "eq", "text"),
      width: "140px",
    },
    {
      title: "Đối tác thanh toán",
      dataIndex: "paymentChannel",
      key: "paymentChannel",
      width: "180px",
      ...getColumnSearchProps("paymentChannel", "contains", "text"),
    },
    {
      title: "Phương thức thanh toán",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      width: "250px",
      ...getColumnSearchProps("paymentMethod", "contains", "text"),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: "130px",
      ...getColumnSelectProps("status"),
      render: (value, _) => {
        return (
          <div>
            <Box>
              {value === 1 ? (
                <Text fontWeight={600} color={"#5FAD67"}>
                  Thành công
                </Text>
              ) : value === 2 ? (
                <Text fontWeight={600} color={"#F3C262"}>
                  Đang chờ xử lý
                </Text>
              ) : (
                <Text fontWeight={600} color={"#FF2323"}>
                  Thất bại
                </Text>
              )}
            </Box>
          </div>
        );
      },
    },
  ]);

  useEffect(() => {
    appPermission === EnumPermission.CSDT &&
      setColumns([
        {
          title: "Thời gian",
          dataIndex: "createDate",
          key: "createDate",
          width: "190px",
          ...getColumnDateProps("createDate", "lte", "date"),
          render: (value) => (
            <span>{moment(value).format("HH:mm:ss DD/MM/YYYY")}</span>
          ),
        },
        {
          title: "Mã giao dịch",
          dataIndex: "TransactionNo",
          key: "TransactionNo",
          width: "140px",
          ...getColumnSearchProps("TransactionNo", "contains", "text"),
          render: (value, data) => (
            <Text
              textDecoration={"underline"}
              color={"#045993"}
              cursor="pointer"
              onClick={() => {
                history.push(`${CSDT}/transaction-details/${data.TransactionNo}`);
              }}
            >
              {value}
            </Text>
          ),
        },
        {
          title: "Mã đơn hàng",
          dataIndex: "orderId",
          key: "orderId",
          width: "250px",
          ...getColumnSearchProps("orderId", "eq", "text"),
          render: (value) => <Text color={"#045993"}>{value}</Text>,
        },

        {
          title: "Mã sinh viên",
          dataIndex: "ma_sv",
          key: "ma_sv",
          width: "250px",
          ...getColumnSearchProps("ma_sv", "contains", "text"),
        },
        {
          title: "Số phiếu thu",
          dataIndex: "so_phieu_bao",
          key: "so_phieu_bao",
          width: "140px",
          ...getColumnSearchProps("so_phieu_bao", "contains", "text"),
          render: (value) => (
            <Text className="text-ellipsis" color={"#045993"}>
              {value}
            </Text>
          ),
        },
        {
          title: "Nội dung",
          dataIndex: "noi_dung",
          key: "noi_dung",
          // width: "22%",
          render: (value) => (
            <div
              // className="text-ellipsis"
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ),
          ...getColumnSearchProps("noi_dung", "contains", "text"),
        },
        {
          title: "Giá trị (VNĐ)",
          dataIndex: "amount",
          key: "amount",
          render: (value) => <Box pr="20px">{formatMoney(value)}</Box>,
          align: "end",
          ...getColumnSearchProps("amount", "eq", "text"),
          width: "140px",
        },
        {
          title: "Đối tác thanh toán",
          dataIndex: "paymentChannel",
          key: "paymentChannel",
          width: "180px",
          ...getColumnSearchProps("paymentChannel", "contains", "text"),
        },
        {
          title: "Phương thức thanh toán",
          dataIndex: "paymentMethod",
          key: "paymentMethod",
          width: "250px",
          ...getColumnSearchProps("paymentMethod", "contains", "text"),
        },
        {
          title: "Trạng thái",
          dataIndex: "status",
          key: "status",
          width: "130px",
          ...getColumnSelectProps("status"),
          render: (value, _) => {
            return (
              <div>
                <Box>
                  {value === 1 ? (
                    <Text fontWeight={600} color={"#5FAD67"}>
                      Thành công
                    </Text>
                  ) : value === 2 ? (
                    <Text fontWeight={600} color={"#F3C262"}>
                      Đang chờ xử lý
                    </Text>
                  ) : (
                    <Text fontWeight={600} color={"#FF2323"}>
                      Thất bại
                    </Text>
                  )}
                </Box>
              </div>
            );
          },
        },
      ]);
    // eslint-disable-next-line
  }, [appPermission]);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text fontWeight={600} color={"#202027"}>
          Danh sách giao dịch
        </Text>
      </Flex>
      {appPermission === EnumPermission.ADMIN && (
        <HeaderFilter dataIndex="schoolCode" handleSearch={handleSearch} />
      )}
      <Table
        columns={columns}
        dataSource={billList}
        text={"giao dịch"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={appPermission === EnumPermission.ADMIN ? "2800px" : "2200px"}
      />
    </Box>
  );
};

export default View;
