import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import MainButton from "components/button";
import { Button, Form, FormInstance, Input, Select } from "antd";

const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
  onClose: () => void;
  formRef: React.MutableRefObject<FormInstance<any>>;
}

const FormAddTrainingFacilities = ({ onFinish, onClose, formRef }: IProps) => {
  const onReset = () => {
    formRef?.current?.setFieldsValue({
      schoolName: "",
      schoolCode: "",
      golive: "",
    });
  };

  return (
    <Form
      name="FormAddTrainingFacilities"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      ref={formRef}
    >
      <Box>
        <Form.Item
          labelAlign="left"
          label="Tên cơ sở"
          name="schoolName"
          rules={[{ required: true, message: "Vui lòng nhập tên cơ sở!" }]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã cơ sở"
          name="schoolCode"
          rules={[{ required: true, message: "Vui lòng nhập mã cơ sở!" }]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Trạng thái"
          className="Môi trường"
          name="golive"
          // initialValue={initValue?.status}
          rules={[{ required: true, message: "Vui lòng chọn môi trường!" }]}
        >
          <Select className="w-330 custom-input">
            <Option value={true}>GOLIVE</Option>
            <Option value={false}>UAT</Option>
          </Select>
        </Form.Item>
      </Box>
      <Flex flexDirection={"row"} alignContent="center" justifyContent="center">
        <Button
          className="custom-button button-dash"
          onClick={() => {
            onClose();
            onReset();
          }}
          style={{
            width: "95px",
          }}
        >
          Hủy
        </Button>
        <Box ml="2">
          <MainButton htmlType="submit" text="Xác nhận" />
        </Box>
      </Flex>
    </Form>
  );
};

export default FormAddTrainingFacilities;
