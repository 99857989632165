import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, Empty, message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import {
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  setSchoolDetail,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { ADMIN, CODE_SUCCESS } from "util/Constant";
import TabsPanel from "../SetUpPaymentVNPAY/Components/TabsPanel";
import SetUpID from "./Components/SetUpTIDACB";
import {
  ItypeFee,
  ClientCode,
  PaymentConfigPartnerACB,
  PaymentConfigNavigation,
  ResponsePaymentConfigNavigation,
} from "service/OrganizationAdmin/Organization";
import { validResponse } from "util/API";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import * as Sentry from "@sentry/browser";
import { handleThrowError } from "util/general";
import TransactionNavigation from "./Components/TransactionNavigationACB";
import { ResponseBase } from "types/general";

const SetUpPaymentACB = () => {
  const {
    PaymentConfigPartner,

    GetDetailShool,
    GetSchoolTypeFee,
    GetListClientCode,
    GetPaymentConfigNavigation,
  } = OrganizationAdminService;

  const dispatch = useDispatch();
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const history = useHistory();
  const { schoolDetail } = appState;

  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);

  const [activeBtn, setActiveBtn] = useState(1);
  const [listConfigPartner, setListConfigPartner] = useState<
    PaymentConfigPartnerACB[]
  >([]);
  const [typeFee, setTypeFee] = useState<ItypeFee[]>([]);
  const [listClientCode, setListClientCode] = useState<ClientCode[]>([]);

  const [transactionNavigation, setTransactionNavigation] = useState<
    PaymentConfigNavigation[]
  >([]);

  useEffect(() => {
    if (schoolDetail) {
      const list = [
        {
          name: schoolDetail.orgName,
          link: `${ADMIN}/organization-detail/${schoolDetail.orgCode}`,
        },
        {
          name: "Cơ sở đào tạo",
          link: `${ADMIN}/training-facilities/${orgCode}`,
        },
        {
          name: schoolDetail.schoolName,
          link: `${ADMIN}/${schoolDetail.orgCode}/school-information/${schoolDetail.schoolCode}`,
        },
        {
          name: "Đối tác TGTT",
          link: `${ADMIN}/${orgCode}/set-up-payment-acb/${schoolCode}/${ID}`,
        },
      ];
      dispatch(
        setBrandText({
          name: "Quản lý tổ chức",
          link: `${ADMIN}/organization-management`,
        })
      );

      dispatch(setBreadcrumb(list));
    }
  }, [dispatch, schoolDetail, ID, orgCode, schoolCode]);

  const handleGetListClientCode = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetListClientCode(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setListClientCode(...res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleGetSchoolTypeFee = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetSchoolTypeFee(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        res.data.data[0] && setTypeFee(res.data.data[0].typeFee);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleGetDetailPaymentParner = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await PaymentConfigPartner(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase()
      );
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setListConfigPartner(res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleGetDetail = useCallback(async () => {
    try {
      dispatch(setLoadingApp(true));
      const res = await GetDetailShool(orgCode, schoolCode);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const response = res.data;
        dispatch(setSchoolDetail(response.data));
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  }, [GetDetailShool, dispatch, orgCode, schoolCode]);

  // Lay danh sach NAV config
  const handleGetConfigPaymentNavigation = async () => {
    try {
      dispatch(setLoadingApp(true));
      const res: ResponseBase<ResponsePaymentConfigNavigation, boolean> =
        await GetPaymentConfigNavigation(
          orgCode,
          schoolCode,
          ID.toLocaleUpperCase()
        );
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setTransactionNavigation(
          res.data.data.sort((a, b) => a.priority - b.priority)
        );
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    !schoolDetail && handleGetDetail();
    handleGetDetailPaymentParner();
    handleGetSchoolTypeFee();
    handleGetListClientCode();
    // handleGetConfigPaymentNavigation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box pb={5}>
        <Flex
          alignItems={"center"}
          cursor="pointer"
          w={"fit-content"}
          onClick={() =>
            history.push(
              `${ADMIN}/${schoolDetail.orgCode}/tgtt-partner/${schoolDetail.schoolCode}`
            )
          }
        >
          <ChevronLeftIcon color={"#045993"} />
          <Text
            color={"#045993"}
            fontSize="15px"
            fontWeight={600}
            textDecorationLine="underline"
            w={"fit-content"}
          >
            Quay lại
          </Text>
        </Flex>
      </Box>
      <Box
        mt={10}
        p={{ base: "10px", md: "10px" }}
        minH="calc(100vh - 140px)"
        background={globalStyles.colors.bg_color[200]}
        borderRadius="6px"
        borderTopLeftRadius={0}
        position="relative"
      >
        <TabsPanel activeBtn={activeBtn} setActiveBtn={setActiveBtn} />
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="20px"
          fontWeight="600"
          my="4"
        >
          Thiết lập ACB
        </Text>
        {activeBtn === 1 && (
          <Box>
            <Text
              color={globalStyles.colors.text[202027]}
              fontSize="14px"
              fontWeight="600"
              my="3"
              mt="12"
            >
              Thiết lập tài khoản
            </Text>
            <SetUpID
              listTID={listConfigPartner}
              setListTID={setListConfigPartner}
              handleGetDetailPaymentParner={handleGetDetailPaymentParner}
            />
          </Box>
        )}
        {activeBtn === 2 && (
          <Box>
            {listConfigPartner.length > 0 ? (
              <>
                <Text
                  color={globalStyles.colors.text[202027]}
                  fontSize="14px"
                  fontWeight="600"
                  my="3"
                  mt="5"
                >
                  Thiết lập điều hướng giao dịch
                </Text>

                <TransactionNavigation
                  typeFee={typeFee}
                  listTID={listConfigPartner}
                  listClientCode={listClientCode}
                  transactionNavigation={transactionNavigation}
                  // handleGetConfigPaymentNavigation: () => Promise<void></void>
                  setTransactionNavigation={setTransactionNavigation}
                  handleGetConfigPaymentNavigation={
                    handleGetConfigPaymentNavigation
                  }
                />
              </>
            ) : (
              <Box mt="100">
                <Empty />
              </Box>
            )}
          </Box>
        )}
        {activeBtn !== 2 && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="center"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              style={{
                width: "95px",
              }}
              onClick={() =>
                history.push(
                  `${ADMIN}/${schoolDetail.orgCode}/tgtt-partner/${schoolDetail.schoolCode}`
                )
              }
            >
              Quay lại
            </Button>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default SetUpPaymentACB;
