import { FormInstance } from "antd";
import React from "react";
import {
  IPartners,
  PaymentMethod,
} from "service/OrganizationAdmin/Organization";
// import ColPercent from "./ColPercent";
import ColRule from "./ColRule";

interface P {
  isEdit: boolean;
  status: {
    _id: string;
    status: number;
  }[];
  item: PaymentMethod;
  listTgtt: IPartners[];
  signValue: {
    [key: string]: string;
  };
  setSignValue: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  formRef: React.MutableRefObject<FormInstance<any>>;
}

const ColConfig = ({
  item,
  status,
  isEdit,
  formRef,
  listTgtt,
  signValue,
  setSignValue,
}: P) => {
  // const [onlyOne, setOnlyOne] = useState(true);
  // const [ruleConfig, setRuleConfig] = useState(false);

  // useEffect(() => {
  //   onlyOne && setRuleConfig(false);
  // }, [onlyOne]);

  // useEffect(() => {
  //   item.ruleConfig.length === 2 && setRuleConfig(true);
  // }, [item]);

  return (
    <>
      <ColRule
        item={item}
        status={status}
        isEdit={isEdit}
        listTgtt={listTgtt}
        signValue={signValue}
        setSignValue={setSignValue}
      />
      {/* <ColPercent
        item={item}
        isEdit={isEdit}
        formRef={formRef}
        ruleConfig={ruleConfig}
      /> */}
    </>
  );
};

export default ColConfig;
