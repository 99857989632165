import { Box } from "@chakra-ui/react";
import { FormInstance, message } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputOrganizationCreate } from "service/Auth";
import AuthService from "service/Auth/AuthService";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleGetOrganztionDetail, handleThrowError } from "util/general";
import FormLoginAccount from "./FormLoginAccount";
import * as Sentry from "@sentry/browser";

const LoginAcoount = () => {
  const dispatch = useDispatch();
  const orgCode = useMemo(() => window.location.hash.split("/")[3], []);
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { detailOrganization } = appState;
  const formRef = React.useRef<FormInstance>(null);
  const [isEdit, setIsEdit] = useState(false);

  const [organizationCreate, setOrganizationCreate] = useState<
    InputOrganizationCreate | undefined
  >(undefined);

  useEffect(() => {
    onHandleGetDetailAccount();
    !detailOrganization &&
      handleGetOrganztionDetail(
        dispatch,
        orgCode,
        OrganizationAdminService.OrganizationDetail
      );
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));
      const orgCode = detailOrganization.orgCode;
      const newData = {
        ...values,
        orgCode: detailOrganization.orgCode,
        status:
          values.status === "Hoạt động"
            ? 1
            : values.status === "Không hoạt động"
            ? 0
            : +values.status,
      };

      const { status, ...rest } = newData;

      const res = !organizationCreate
        ? await AuthService.AuthCreateOrganization(orgCode, rest)
        : await AuthService.UpdateOsa(orgCode, organizationCreate._id, newData);
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        message.success(
          !organizationCreate
            ? "Tạo mới tài khoản tổ chức thành công"
            : "Chỉnh sửa tài khoản tổ chức thành công"
        );
        handleGetOrganztionDetail(
          dispatch,
          orgCode,
          OrganizationAdminService.OrganizationDetail
        );

        setOrganizationCreate(values);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors ? Sentry.captureException(res.data.data?.errors) : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
      setIsEdit(false);
    }
  };

  const onHandleGetDetailAccount = async () => {
    try {
      const query = `orgCode[eq]=${orgCode}`;
      dispatch(setLoadingApp(true));
      const res = await AuthService.AuthGetCreateOrganization(query);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        res.data.data?.records[0] &&
          setOrganizationCreate(res.data.data.records[0]);
      } else {
        message.error("Có lỗi xảy ra !");
        Sentry.captureMessage("Có lỗi xảy ra !")
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error)
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    organizationCreate &&
      formRef?.current?.setFieldsValue({
        username: organizationCreate.username,
        fullName: organizationCreate.fullName,
        email: organizationCreate.email,
        phone: organizationCreate.phone,
        address: organizationCreate.address,
        status: organizationCreate.status ? "Hoạt động" : "Không hoạt động",
      });
  }, [organizationCreate]);

  useEffect(() => {
    !isEdit &&
      organizationCreate &&
      formRef?.current?.setFieldsValue({
        username: organizationCreate.username,
        fullName: organizationCreate.fullName,
        email: organizationCreate.email,
        phone: organizationCreate.phone,
        address: organizationCreate.address,
        status: organizationCreate.status ? "Hoạt động" : "Không hoạt động",
      });
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [organizationCreate]);

  return (
    <Box mt="5">
      <FormLoginAccount
        onFinish={onFinish}
        formRef={formRef}
        organizationCreate={organizationCreate}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </Box>
  );
};

export default LoginAcoount;
