import { NetWorkService } from "util/API";
import { BillResponsive } from "./Bill";
export const BILL = "/bill";

const { Get } = NetWorkService;
export default class BillService {
  static GetAllBill = async (query: string) => {
    return await Get<BillResponsive>({
      url: BILL,
    });
  };
}
