import { useDispatch, useSelector } from "react-redux";
import { CODE_SUCCESS } from "util/Constant";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { message } from "antd";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import FormBasicInformation from "./FormBasicInformation";
import { RootState } from "store";
import { handleGetDetail, handleThrowError } from "util/general";
import { memo, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/browser";

const OrganizationInformation = () => {
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;
  const dispatch = useDispatch();
  const ID = useMemo(() => window.location.hash.split("/")[4], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const [isSearch, setIsSearch] = useState({
    isSearchCCCD: false,
    isSearchMaSV: false,
  });

  const { UpdateSchool, GetDetailShool } = OrganizationAdminService;

  const onFinish = async (values: any) => {
    try {
      dispatch(setLoadingApp(true));

      const dataPost = {
        ...values,
        ...isSearch,
        // roles: [values.roles],
        status:
          values.status === "Hoạt động"
            ? 1
            : values.status === "Không hoạt động"
            ? 0
            : +values.status,
      };
      const { Secret_key, API_domain, ...rest } = dataPost;
      const res = await UpdateSchool(
        schoolDetail.orgCode,
        schoolDetail.schoolCode,
        rest
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        handleGetDetail(dispatch, GetDetailShool, orgCode, ID);
        message.success("Cập nhật thành công");
      } else {
        if (res && validResponse(res)) {
          // message.error(`${res.data.data?.message}`);
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    setIsSearch({
      isSearchCCCD: schoolDetail?.isSearchCCCD,
      isSearchMaSV: schoolDetail?.isSearchMaSV,
    });
  }, [schoolDetail]);

  return (
    <div>
      <FormBasicInformation
        isSearch={isSearch}
        onFinish={onFinish}
        setIsSearch={setIsSearch}
      />
    </div>
  );
};

export default memo(OrganizationInformation);
