import { InputRef } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CODE_SUCCESS } from "util/Constant";
import { NetWorkService, validResponse } from "util/API";
import View from "./View";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  Type_Initstate,
} from "store/appSlice";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import { IPlainOptionsMap } from "types/general";
import { RootState } from "store";
import useUploadHook from "util/hook/useUploadHook";
import * as Sentry from "@sentry/browser";
// import { handleThrowError } from "util/general";

const ReceiptsOSA = () => {
  const HEGHT = 36;
  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Đã thu", value: 1, type: "eq" },
    { name: "Chưa thu", value: 0, type: "eq" },
    { name: "Đang thanh toán", value: "2", type: "eq" },
  ];
  const defaultChecked = "";

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { authInfor } = appState;

  const [billList, setBillList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const searchInput = useRef<InputRef>(null);

  const {
    getColumnDateProps,
    getColumnSearchProps,
    getColumnSelectProps,
    handleSearch,
    handleSetQuery,
  } = FilterAntdHook({
    searchedColumn,
    setSearchedColumn,
    setCurrentPage,
    searchInput,
    plainOptionsMap,
    defaultChecked,
  });

  const { handleFileSelect, isLoading } = useUploadHook({
    url: `/organization/${authInfor?.orgCode}/school/${authInfor?.schoolCode}/bill/upload/excel`,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
        expaned: e?.chi_tiet ? (e?.chi_tiet.trim() ? "1" : "") : "",
        height: e.noi_dung
          ? `${e.noi_dung.split("<br>").length * HEGHT}px`
          : `${HEGHT}px`,
        children: e?.chi_tiet
          ? e?.chi_tiet.split(",").map((el: any, idex: number) => ({
              key: e._id + el + `${idex}`,
              id_sv: "",
              stt: "",
              tong_thu: "",
              mien_giam: "",
              ngay_tao: "",
              trang_thai: "",
              date_line: "",
              tt_sv: "",
              id_truong: "",
              id_nhom_ct: "",
              hoc_ky: "",
              so_phieu_bao: "",
              noi_dung: el,
              chi_tiet: "",
              ma_loai_thu: "",
              is_cho_nhap_so_tien: "",
              phai_thu: "",
            }))
          : undefined,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;

      const BILL = `/organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/bill`;

      const res = await NetWorkService.Get({
        url: BILL + handleSetQuery(query),
      });
      if (
        res &&
        validResponse(res) &&
        res.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        const respons = res.data;
        setBillList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        // validResponse(res) &&
        //   handleThrowError(
        //     res.data.data?.errors
        //       ? res.data.data?.errors
        //       : res.data.data?.message
        //   );
        setTotalRow(0);
        setBillList([]);
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchedColumn && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage && authInfor) {
      getData();
    }
    // eslint-disable-next-line
  }, [currentPage, authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <View
      loading={loading}
      pageSize={pageSize}
      billList={billList}
      totalRow={totalRow}
      currentPage={currentPage}
      handleSearch={handleSearch}
      getColumnDateProps={getColumnDateProps}
      onChangeCurrentPage={onChangeCurrentPage}
      getColumnSearchProps={getColumnSearchProps}
      getColumnSelectProps={getColumnSelectProps}
      handleFileSelect={handleFileSelect}
      isLoading={isLoading}
    />
  );
};

export default ReceiptsOSA;
