import { Box, Flex, Text } from "@chakra-ui/react";
import { Checkbox, message, Tooltip } from "antd";
import IconEdit from "components/icons/IconEdit";
import IconRemove from "components/icons/IconRemove";
import { useMemo } from "react";
import { PaymentPartnerDetail } from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { globalStyles } from "theme/styles";
import { validResponse } from "util/API";
import { handleThrowError } from "util/general";
import { ITID } from "../..";
import { uniq } from "lodash";
import * as Sentry from "@sentry/browser";

interface P {
  item: ITID;
  index: number;
  isEdit: boolean;
  handleEditRow: (index: number, _id: string) => void;
  handleRemoveRow: (index: number) => void;
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
}

const RenderRow = ({
  item,
  handleRemoveRow,
  index,
  handleEditRow,
  isEdit,
  paymentPartnerDetail,
  handleGetDetailPaymentParner,
}: P) => {
  const { UpdateStarSettingTID } = OrganizationAdminService;

  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);

  const handleTickStar = async () => {
    try {
      const response = await UpdateStarSettingTID(
        orgCode,
        schoolCode,
        paymentPartnerDetail.paymentPartner,
        { _id: item._id }
      );
      if (response) {
        handleGetDetailPaymentParner();
        message.success("Thiết lập TID miễn phí thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const isShowStar = useMemo(
    () =>
      paymentPartnerDetail && paymentPartnerDetail?.TID[index]?.TIDStar === 1,
    [paymentPartnerDetail, index]
  );

  const isUsedTID = useMemo(() => {
    const listTID = paymentPartnerDetail?.transactionNavigation?.map(
      (item) => item?.TID?._id
    );
    return uniq(listTID).includes(item?._id);
    // eslint-disable-next-line
  }, [paymentPartnerDetail]);

  return (
    <>
      <td>
        <Box className="d-flex gap-10" cursor="pointer" position="relative">
          <Tooltip title="Thiết lập TID miễn phí">
            <Checkbox
              checked={isShowStar}
              className="checkbox-tidstar"
              onChange={handleTickStar}
            />
          </Tooltip>
          <Text color="#045993" fontSize="15px" fontWeight="400" p="5px" pl="0">
            {item.TIDName}
          </Text>
        </Box>
      </td>
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.tmnCode}
        </Text>
      </td>
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.tmnHashSecret}
        </Text>
      </td>
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.bankAccountNumber}
        </Text>
      </td>
      <td>
        <Text
          color={globalStyles.colors.text[202027]}
          fontSize="15px"
          fontWeight={400}
          p="5px"
          pl="0"
        >
          {item.note}
        </Text>
      </td>
      <td>
        {!isEdit && (
          <Flex pl="0" justifyContent={"end"} alignItems={"center"}>
            {!isUsedTID && (
              <Box
                pr="2"
                onClick={() => handleRemoveRow(index)}
                cursor="pointer"
              >
                <IconRemove />
              </Box>
            )}
            <Box
              pr="20px"
              cursor="pointer"
              onClick={() => handleEditRow(index, item._id)}
            >
              <IconEdit />
            </Box>
          </Flex>
        )}
      </td>
    </>
  );
};

export default RenderRow;
