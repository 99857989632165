import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import ViewReport from "./ViewReport";
import {
  OrganizationItem,
  SchoolDetail,
} from "service/OrganizationAdmin/Organization";
import { message } from "antd";
import { handleThrowError } from "util/general";

const LIMIT = 99999;

const Report = () => {
  const dispatch = useDispatch();
  const [orgList, setOrgList] = useState<OrganizationItem[]>([]);
  const [orgItem, setOrgItem] = useState<string | undefined>(undefined);
  const [schoolList, setSchoolList] = useState<SchoolDetail[]>([]);

  const handleGetOrganization = async () => {
    try {
      dispatch(setLoadingApp(true));
      let query = `?limit=${LIMIT}&createdAt[sort]=desc`;
      const URL = "/organization";
      const res = await NetWorkService.Get({
        url: URL + query,
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setOrgList(respons.data.records);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const handleGetSchoolList = async () => {
    try {
      dispatch(setLoadingApp(true));
      let query = `?limit=${LIMIT}&createdAt[sort]=desc`;
      const URL = `/organization/${orgItem}/school`;
      const res = await NetWorkService.Get({
        url: URL + query,
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setSchoolList(respons.data.records);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    orgItem && handleGetSchoolList();
    console.log(orgItem)
    // eslint-disable-next-line
  }, [orgItem]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    handleGetOrganization();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      position="relative"
    >
      <ViewReport
        orgList={orgList}
        schoolList={schoolList}
        setOrgItem={setOrgItem}
      />
    </Box>
  );
};

export default Report;
