import { AnyAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { Dispatch } from "react";
import {
  OrganizationDetailResponsive,
  SchoolDetailResponsive,
} from "service/OrganizationAdmin/Organization";
import {
  setDetailOrganization,
  setLoadingApp,
  setSchoolDetail,
} from "store/appSlice";
import { IErrors, ResponseBase } from "types/general";
import { validResponse } from "./API";
import { CODE_SUCCESS } from "./Constant";
import * as Sentry from "@sentry/browser";

// sét giá trị của một cookie nhất định
export const saveValueInCookie = (key: string, value: string) => {
  var currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  var expires = currentDate.toUTCString();
  document.cookie = `${key}=${value}; expires=${expires};`;
};

// đọc giá trị của một cookie nhất đinh
export const readValueCookie = (value: string) => {
  const cookies = document.cookie.split(";");
  const cookieObj: any = {};
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().split("=");
    cookieObj[cookie[0]] = cookie[1];
  }
  return cookieObj[value];
};

export const formatMoney = (money: number) => {
  let _money = String(money);
  _money = _money.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  return _money;
};

export const deleteCookie = (cookieName: string) => {
  document.cookie =
    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const handleGetOrganztionDetail = async (
  dispatch: Dispatch<AnyAction>,
  ID: string,
  OrganizationDetail: (
    id: string
  ) => Promise<ResponseBase<OrganizationDetailResponsive, boolean>>
) => {
  try {
    dispatch(setLoadingApp(true));
    const res = await OrganizationDetail(ID);
    if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
      localStorage.setItem("addOrganization", JSON.stringify(res.data.data));
      dispatch(setDetailOrganization(res.data.data));
    } else {
      message.error("Có lỗi xảy ra !");
      Sentry.captureMessage("Có lỗi xảy ra !");
    }
  } catch (error) {
    message.error("Có lỗi xảy ra !");
    Sentry.captureException(error)
  } finally {
    dispatch(setLoadingApp(false));
  }
};

export const handleGetDetail = async (
  dispatch: Dispatch<AnyAction>,
  GetDetailShool: (
    orgCode: string,
    schoolCode: string
  ) => Promise<ResponseBase<SchoolDetailResponsive, boolean>>,
  orgCode: string,
  ID: string
) => {
  try {
    dispatch(setLoadingApp(true));
    const res = await GetDetailShool(orgCode, ID);
    if (
      res &&
      validResponse(res) &&
      res.data.code === CODE_SUCCESS &&
      res.data.data.success !== false
    ) {
      const response = res.data;
      dispatch(setSchoolDetail(response.data));
    } else {
      if (res && validResponse(res)) {
        message.error(`${res.data.data?.message}`);
        Sentry.captureMessage(res.data.data?.message);        
      }
    }
  } catch (error) {
    Sentry.captureException(error)
  } finally {
    dispatch(setLoadingApp(false));
  }
};

export const handleThrowError = (errors: IErrors[] | string) => {
  console.log(errors, "this is errors");
  if (typeof errors === "object") {
    errors.forEach((e) => {
      e.constraints.forEach((el) => {
        message.error(`${el}`);
      });
    });
  } else {
    message.error(errors);
  }
  Sentry.captureException(errors)
};

export const changeValueFieldToAll = (value: string) => {
  if (!value) {
    return "Tất cả";
  }

  if (value.toLowerCase() === "default") {
    return "Mặc định";
  }

  if (value.toLowerCase() === "any" || value.toLowerCase() === "all") {
    return "Tất cả";
  }
  return value;
};
