import { Box } from "@chakra-ui/react";
import React from "react";
import { globalStyles } from "theme/styles";

const Sign = () => {
  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      Dashboard
    </Box>
  );
};

export default Sign;
