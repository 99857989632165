import { RootState } from "store";
import { Button, ButtonProps } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { EnumPermission } from "util/Constant";
import { Type_Initstate } from "store/appSlice";

type IPButton = ButtonProps & {
  text: string;
  classButton?: string;
  icon?: React.ReactNode;
};

const MainButton = (props: IPButton) => {
  const { classButton, text, icon, ...rest } = props;
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);

  const { appPermission } = appState;

  const backgroundBtn = useMemo(() => {
    switch (appPermission) {
      case EnumPermission.ADMIN:
        return "button-admin";
      case EnumPermission.CSDT:
        return "button-csdt";
      case EnumPermission.OSA:
        return "button-osa";

      default:
        return "button-admin";
    }
  }, [appPermission]);

  return (
    <Button
      {...rest}
      className={`custom-button ${classButton} ${backgroundBtn}`}
    >
      {icon && <span style={{ marginRight: "5px" }}>{icon}</span>} {text}
    </Button>
  );
};

export default MainButton;
