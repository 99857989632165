import { message } from "antd";

import { useDispatch } from "react-redux";
import AuthService from "service/Auth/AuthService";
import { setAuthInfor, setLoadingApp } from "store/appSlice";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import * as Sentry from "@sentry/browser";
import { AuthInfor } from "service/Auth";

const UseGetAvatarUrlHook = () => {
  const dispatch = useDispatch();
  const { DetailCSDT } = AuthService;

  const onHandleGetAvatarUrl = async (data: AuthInfor) => {
    try {
      dispatch(setLoadingApp(true));
      const { orgCode, schoolCode } = data;
      const res = await DetailCSDT(orgCode, schoolCode);
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        dispatch(
          setAuthInfor({ ...data, avatarUrl: res.data.data?.avatarUrl })
        );
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : (res.data.data?.message as any)
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message as any);
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra !");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  return { onHandleGetAvatarUrl };
};

export default UseGetAvatarUrlHook;
