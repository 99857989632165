import { Box, Flex } from "@chakra-ui/react";
import { Form, FormInstance, Input } from "antd";
import MainButton from "components/button";
import ButtonFooter from "components/ButtonFooter";
import React, { useEffect, useState } from "react";
import { IBranch } from "service/OSA/Osa.d";

interface IProps {
  onFinish: (values: any) => void;
  branch: IBranch;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  formRef: React.MutableRefObject<FormInstance<any>>;
}

const FormUpdateBranch = ({
  onFinish,
  branch,
  isEdit,
  setIsEdit,
  formRef,
}: IProps) => {
  useEffect(() => {
    if (branch && !isEdit) {
      formRef?.current?.setFieldsValue({
        branchName: branch.branchName,
        branchCode: branch.branchCode,
      });
    }
  }, [branch, isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [branch]);

  return (
    <Form
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Box w="80%" maxW="755px">
        <Form.Item
          name="branchName"
          label="Tên chi nhánh"
          labelAlign="left"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập tên chi nhánh!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Tên chi nhánh"
            disabled={!isEdit}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã chi nhánh"
          name="branchCode"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập mã chi nhánh!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Mã chi nhánh"
            disabled
          />
        </Form.Item>

        <Flex justifyContent="flex-end">
          <ButtonFooter
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            text="Xác nhận"
            text2="Cập nhật"
            formRef={formRef}
          />
        </Flex>
      </Box>
    </Form>
  );
};

export default FormUpdateBranch;
