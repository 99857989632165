import { IBranch } from "./Osa.d";
import { NetWorkService } from "util/API";

const { Post, Get, Get_URL_RESOURCE } = NetWorkService;

export default class OsaService {
  static UPDATE_BRANCH = async (
    orgCode: string,
    branchCode: string,
    data: any
  ) => {
    return await Post<{ code: number; data: IBranch }>({
      url: `/organization/${orgCode}/branch/${branchCode}/update`,
      body: { ...data },
    });
  };
  static CREATE_BRANCH = async (orgCode: string, data: any) => {
    return await Post<{ code: number; data: IBranch }>({
      url: `/organization/${orgCode}/branch/create`,
      body: { ...data },
    });
  };
  static DETAIL_BRANCH = async (orgCode: string, branchCode: string) => {
    return await Get<{ code: number; data: IBranch }>({
      url: `/organization/${orgCode}/branch/${branchCode}/detail`,
    });
  };
  // Lấy danh sách cơ sở đào tạo thuộc tổ chức
  static GET_ALL_SCHOOL_BELONGING_ORG = async (orgCode: string) => {
    return await Get<any>({
      url: `/organization/${orgCode}/school`,
    });
  };

  //Tải xuống báo cáo lịch sử giao dịch

  static EXPORT_REPORT_EXCEL = async (
    orgCode: string,
    schoolCode: string,
    startTime: number,
    endTime: number
  ) => {
    return await Get<Blob>({
      url: `/organization/${orgCode}/school/${schoolCode}/report/payment-history/download/excel?createDate[gte]=${startTime}&createDate[lte]=${endTime}`,
      responseType: "blob",
    });
  };

  // Download file đối soát (Excel)
  static RECONCILE_DOWNLOAD = async (
    orgCode: string,
    schoolCode: string,
    ID: string
  ) => {
    return await Get_URL_RESOURCE<Blob>({
      url: `/organization/${orgCode}/school/${schoolCode}/reconcile/download/${ID}`,
      responseType: "blob",
    });
  };
}
