import { Box, Flex, Text } from "@chakra-ui/react";
import { Button, message } from "antd";
import MainButton from "components/button";
import IconAdd from "components/icons/IconAdd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import {
  ItypeFee,
  ClientCode,
  INavigateRulesBIDV,
  PaymentMethodDetail,
  PaymentConfigNavigation,
  PaymentConfigPartnerONEPAY,
} from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { validResponse } from "util/API";
import { CODE_SUCCESS, ENABLE, DISABLE } from "util/Constant";
import { handleThrowError } from "util/general";

import RenderRow from "./RenderRow";
import RenderRowInput from "./RenderRowInput";
import * as Sentry from "@sentry/browser";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setLoadingApp, Type_Initstate } from "store/appSlice";
import { ResponseBase } from "types/general";

interface IProps {
  listTID: PaymentConfigPartnerONEPAY[];
  typeFee: ItypeFee[];
  transactionNavigation: PaymentConfigNavigation[];
  handleGetConfigPaymentNavigation: () => Promise<void>;
  setTransactionNavigation: React.Dispatch<
    React.SetStateAction<PaymentConfigNavigation[]>
  >;
  listClientCode: ClientCode[];
}

export const NAV_RULE_KEYS = [
  "clientCode",
  "groupStudent",
  "accumulationTrans",
  "paymentMethod",
  "typeFee",
  "transAmount",
];

export type IArgs = {
  index: number;
  name: string;
  values: string | string[] | number;
  isNavigateRules: boolean;
  valueName: string;
  rule?: string;
};

const TransactionNavigation = ({
  listTID,
  typeFee,
  transactionNavigation,
  setTransactionNavigation,
  handleGetConfigPaymentNavigation,
  listClientCode,
}: IProps) => {
  const dispatch = useDispatch();
  const [cloneTransactionNavigation, setCloneTransactionNavigation] = useState<
    PaymentConfigNavigation[]
  >([]);
  const [isEdit, setIsEdit] = useState(false);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);
  const [indexEdit, setIndexEdit] = useState(0);
  const [prioritys, setPrioritys] = useState<Array<number>>([]);
  const [itemEdit, seItemEdit] = useState<PaymentConfigNavigation | undefined>(
    undefined
  );

  const {
    CreatePaymentNavigation,
    UpdatePaymentNavigation,
    DelPaymentNavigation,
  } = OrganizationAdminService;

  const handleEditRow = (index: number) => {
    setIsEdit(true);
    let currenIndex = cloneTransactionNavigation[index];
    currenIndex = { ...currenIndex, isEdit: true };

    setIndexEdit(index);
    seItemEdit(currenIndex);

    setCloneTransactionNavigation([
      ...cloneTransactionNavigation.slice(0, index),
      currenIndex,
      ...cloneTransactionNavigation.slice(index + 1),
    ]);
  };

  const handleSetValueInNavigateRules = (
    key: string,
    index: number,
    value: string | string[] | number,
    valueName: string,
    rule: string
  ) => {
    let arrClone = [...cloneTransactionNavigation];
    const navigateRules = arrClone[index].navigateRules;

    let currenIndex = navigateRules.findIndex((e) => e.key === key);
    let updateValue = navigateRules[currenIndex];
    updateValue = { ...updateValue, valueCode: value, valueName, rule, key };
    return [
      ...navigateRules.slice(0, currenIndex),
      { ...updateValue },
      ...navigateRules.slice(currenIndex + 1),
    ];
  };

  // Hàm để loại bỏ các phần tử trùng lặp
  function removeDuplicates(array: INavigateRulesBIDV[]) {
    return array.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t["key"] === item["key"])
    );
  }

  const onChangeValue = ({
    index,
    isNavigateRules,
    name,
    valueName,
    values,
    rule,
  }: IArgs) => {
    let arrClone = [...cloneTransactionNavigation];
    let currenIndex = arrClone[index];

    const navigateRules = removeDuplicates(
      handleSetValueInNavigateRules(
        name,
        index,
        values,
        valueName,
        rule
      ).filter((e) => {
        if (e.rule !== "all" && e.key !== "priority" && e.valueCode !== "all") {
          return { ...e };
        }
      })
    );

    if (name === "effectiveDate" && Array.isArray(values)) {
      const periodFrom = dayjs(values[0]).valueOf();
      const periodTo = dayjs(values[1]).valueOf();
      currenIndex = {
        ...currenIndex,
        periodFrom,
        periodTo,
        status: dayjs(periodTo).isAfter(dayjs().add(-1, "day"))
          ? ENABLE
          : DISABLE,
      };
    } else {
      currenIndex = !isNavigateRules
        ? { ...currenIndex, [name]: values }
        : { ...currenIndex, navigateRules };
    }

    setCloneTransactionNavigation([
      ...arrClone.slice(0, index),
      currenIndex,
      ...arrClone.slice(index + 1),
    ]);
  };

  // hàm xoá row
  const handleRemoveRow = (index: number) => {
    const itemRemove = cloneTransactionNavigation[index];
    const newTransactionNavigation = [
      ...cloneTransactionNavigation.slice(0, index),
      ...cloneTransactionNavigation.slice(index + 1),
    ];
    setCloneTransactionNavigation(newTransactionNavigation);
    handleDeleteRowInServer(itemRemove._id);
  };

  const handleAddRow = () => {
    const newTransactionNavigation: PaymentConfigNavigation = {
      paymentPartner: "ONEPAY",
      navRuleName: "",
      priority: 1,
      configCode: "",
      status: ENABLE,
      isEdit: true,
      navigateRules: [
        // {
        //   key: "clientCode",
        //   valueCode: "s5wXjKJzrZ0",
        //   valueName: "s5wXjKJzrZ0",
        //   rule: "eq",
        // },
        // {
        //   key: "groupStudent",
        //   valueCode: "0",
        //   valueName: "Thu trọn gói năm",
        //   rule: "eq",
        // },
      ],
      periodFrom: dayjs().valueOf(),
      periodTo: dayjs().valueOf(),
    };

    setCloneTransactionNavigation([
      newTransactionNavigation,
      ...cloneTransactionNavigation,
    ]);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setCloneTransactionNavigation([...transactionNavigation]);
  };

  const handleConfirm = () => {
    let err = false;
    let listData = cloneTransactionNavigation.map((e) => ({
      ...e,
      isEdit: false,
    }));

    let currenIndex: any = listData[indexEdit];
    const { isDefault, __v, ...rest } = currenIndex;

    for (const property in rest) {
      if (!rest[`${property}`] && property !== "isEdit") {
        err = true;
      }
    }
    if (!err) {
      !itemEdit
        ? handlePostDataToServer(listData[0])
        : handlePostDataToServer(listData.find((e) => e._id === itemEdit._id));
    } else {
      message.error("Yêu cầu nhập đầy đủ các trường");
      Sentry.captureMessage("Yêu cầu nhập đầy đủ các trường");
    }
  };

  // post Set config
  const handleAddConfig = async (valuePost: PaymentConfigNavigation) => {
    const { status, ...rest } = valuePost;
    const response = await CreatePaymentNavigation(rest, orgCode, schoolCode);
    return response;
  };

  // patch Update Config
  const handleUpdateConfig = async (
    valuePost: PaymentConfigNavigation,
    idParam: string
  ) => {
    const {
      isDefault,
      _id,
      paymentPartner,
      createdAt,
      updatedAt,
      config,
      __v,
      id,
      // TIDId,
      ...rest
    } = valuePost;
    const navigateRules = valuePost.navigateRules.reduce((a, b: any) => {
      const { createdAt, updatedAt, ...res } = b;
      a.push({ ...res });

      return a;
    }, []);

    const dataPost = { ...rest, navigateRules };

    const response = await UpdatePaymentNavigation(
      dataPost,
      orgCode,
      schoolCode,
      idParam
    );
    return response;
  };

  // delete
  const handleDeleteRowInServer = async (_id: string) => {
    try {
      const response = await DelPaymentNavigation(orgCode, schoolCode, _id);
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        await handleGetConfigPaymentNavigation();
        message.success("Thiết lập điều hướng giao dịch thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    } finally {
      seItemEdit(undefined);
      dispatch(setLoadingApp(false));
      setIsEdit(false);
    }
  };

  const handlePostDataToServer = async (input: PaymentConfigNavigation) => {
    try {
      dispatch(setLoadingApp(true));

      let response: ResponseBase<any, boolean> | undefined = undefined;

      const { isEdit, ...rest } = input;
      if (!itemEdit) {
        response = await handleAddConfig(rest);
      } else {
        response = await handleUpdateConfig(rest, input._id);
      }

      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        setIsEdit(false);
        seItemEdit(undefined);
        await handleGetConfigPaymentNavigation();
        message.success("Thiết lập điều hướng giao dịch thành công");
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  const appState = useSelector<RootState, Type_Initstate>((state) => state.app);
  const { schoolDetail } = appState;

  const [paymentMethod, setPaymentMethod] = useState<
    PaymentMethodDetail | undefined
  >(undefined);

  const { PaymentMethodDetail } = OrganizationAdminService;

  const handleGetPaymentMethodDetail = async () => {
    try {
      const res = await PaymentMethodDetail(orgCode, schoolCode);
      if (res && validResponse(res) && res.data.code === CODE_SUCCESS) {
        setPaymentMethod(res.data.data);
      } else {
        message.error("Có lỗi xảy ra");
        Sentry.captureMessage("Có lỗi xảy ra");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (schoolDetail) {
      handleGetPaymentMethodDetail();
    }
    // eslint-disable-next-line
  }, [schoolDetail]);

  useEffect(() => {
    setCloneTransactionNavigation(transactionNavigation);
    // eslint-disable-next-line
  }, [transactionNavigation]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < cloneTransactionNavigation.length - 1; i++) {
      arr.push(i + 1);
    }
    setPrioritys([...arr]);
    // eslint-disable-next-line
  }, [cloneTransactionNavigation]);

  useEffect(() => {
    handleGetConfigPaymentNavigation(); // eslint-disable-next-line
  }, []);

  return (
    <Box backgroundColor={"#fff"} p="4" mt="6" className="box-shadow">
      <Box overflowX={"scroll"}>
        <table className="my-table">
          <thead>
            <tr>
              {!isEdit && (
                <th className="mw-125">
                  <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                    Hành động
                  </Text>
                </th>
              )}
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  STT ưu tiên
                  {/* <span style={{ color: "#FF2323" }}> *</span> */}
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Cấu hình
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Client code
                  {/* <span style={{ color: "#FF2323" }}> *</span> */}
                </Text>
              </th>
              <th className="mw-125">
                <Text
                  color="#A0A6B1"
                  fontSize="15px"
                  fontWeight="500"
                  width={"150px"}
                >
                  Nhóm sinh viên
                  {/* <span style={{ color: "#FF2323" }}> *</span> */}
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Số GD tích lũy
                  {/* <span style={{ color: "#FF2323" }}> *</span> */}
                </Text>
              </th>
              <th className="mw-335">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  PTTT
                  {/* <span style={{ color: "#FF2323" }}> *</span> */}
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Mã loại thu
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Giá trị GD
                  {/* <span style={{ color: "#FF2323" }}> *</span> */}
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Tên tài khoản
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>

              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Ngày hiệu lực
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
              <th className="mw-125">
                <Text color="#A0A6B1" fontSize="15px" fontWeight="500">
                  Trạng thái
                  <span style={{ color: "#FF2323" }}> *</span>
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {cloneTransactionNavigation.map((e, i) => (
              <tr key={i}>
                {e.isEdit ? (
                  <RenderRowInput
                    item={e}
                    index={i}
                    typeFee={typeFee}
                    listTID={listTID}
                    prioritys={prioritys}
                    paymentMethod={paymentMethod}
                    listClientCode={listClientCode}
                    isDefaultItem={e.isDefault}
                    onChangeValue={onChangeValue}
                  />
                ) : (
                  <RenderRow
                    item={e}
                    index={i}
                    isEdit={isEdit}
                    handleEditRow={handleEditRow}
                    handleRemoveRow={handleRemoveRow}
                    isDefaultItem={e.isDefault}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
      <Box className="max-w-1078">
        {!isEdit && listTID.length !== 0 && (
          <Flex mt="6" justifyContent={"center"} alignItems={"center"}>
            <Box
              onClick={() => {
                handleAddRow();
                setIsEdit(true);
              }}
              cursor="pointer"
            >
              <IconAdd />
            </Box>
          </Flex>
        )}

        {isEdit && (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <Button
              className="custom-button button-dash"
              onClick={handleCancel}
              style={{
                width: "95px",
              }}
            >
              Hủy
            </Button>
            <Box ml="2">
              <MainButton text="Hoàn tất" onClick={handleConfirm} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default TransactionNavigation;
