import { Box, Flex, Text } from "@chakra-ui/react";
import type { ColumnsType } from "antd/es/table";
import Table from "components/table";
import React from "react";
import { globalStyles } from "theme/styles";
import { IPlainOptionsMap } from "types/general";

interface IProps {
  studentList: any[];
  totalRow: number;
  loading: boolean;
  pageSize: number;
  currentPage: number;
  isLoading: boolean;
  handleFileSelect: (event: any) => void;
  handleSearch: (args: any) => Promise<void>;
  onChangeCurrentPage: (e: any) => void;
  getColumnSearchProps: (
    dataIndex: any,
    type: "eq" | "contains",
    filterType: "number" | "text" | "date"
  ) => any;
  getColumnSelectProps1?: (
    dataIndex: any,
    defaultChecked: string,
    plainOptionsMap: {
      name: string;
      value: string | number;
      type: "eq" | "ne" | "contains";
    }[]
  ) => any;
}

const View = ({
  loading,
  pageSize,
  totalRow,
  isLoading,
  currentPage,
  studentList,
  handleFileSelect,
  onChangeCurrentPage,
  getColumnSearchProps,
  getColumnSelectProps1,
}: IProps) => {
  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Thu gói trọn năm", value: 0, type: "eq" },
  ];

  const columns: ColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: "4%",
      align: "center",
    },
    {
      title: "Họ và tên",
      dataIndex: "HoTen",
      key: "HoTen",
      ...getColumnSearchProps("HoTen", "contains", "text"),
      render: (value, _) => {
        return (
          <span
            // onClick={() => handleNavigate(_.MaSV)}
            style={{
              color: "#045993",
              cursor: "pointer",
            }}
          >
            {value}
          </span>
        );
      },
    },
    {
      title: "Nhóm sinh viên",
      dataIndex: "NhomSV",
      key: "NhomSV",
      ...getColumnSelectProps1("NhomSV", "", plainOptionsMap),
      render: (value, _) => {
        return (
          <div>
            <Box>
              {value === 0 ? (
                <Text fontWeight={600} color={"#5FAD67"}>
                  Thu gói trọn năm
                </Text>
              ) : (
                <Text fontWeight={600} color={"#FF2323"}></Text>
              )}
            </Box>
          </div>
        );
      },
    },
    {
      title: "Mã sinh viên",
      dataIndex: "MaSV",
      key: "MaSV",
      ...getColumnSearchProps("MaSV", "contains", "text"),
    },
    {
      title: "CCCD",
      dataIndex: "SoCMND",
      key: "SoCMND",
      ...getColumnSearchProps("SoCMND", "contains", "text"),
    },
    {
      title: "Niên khóa",
      dataIndex: "NienKhoa",
      key: "NienKhoa",
      ...getColumnSearchProps("NienKhoa", "contains", "text"),
    },
    {
      title: "Khoa",
      dataIndex: "Khoa",
      key: "Khoa",
      ...getColumnSearchProps("Khoa", "contains", "text"),
    },
    {
      title: "Ngành",
      dataIndex: "Nganh",
      key: "Nganh",
      ...getColumnSearchProps("Nganh", "contains", "text"),
    },
    {
      title: "Mã lớp",
      dataIndex: "MaLop",
      key: "MaLop",
      ...getColumnSearchProps("MaLop", "contains", "text"),
    },
    {
      title: "Số điện thoại",
      dataIndex: "Tel",
      key: "Tel",
      ...getColumnSearchProps("Tel", "contains", "text"),
      width: "150px",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      ...getColumnSearchProps("Email", "contains", "text"),
      width: "300px",
    },
    {
      title: "Số lần GD miễn phí",
      dataIndex: "countFee",
      key: "countFee",
      ...getColumnSearchProps("countFee", "contains", "text"),
    },
  ];

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
    >
      <Flex py="3" pt="4" justifyContent="space-between" align="center">
        <Text fontWeight={600} color={"#202027"}>
          Danh sách sinh viên
        </Text>
        {/* <Flex>
          <Box>
            <Button
              colorScheme="whatsapp"
              backgroundColor={"#5FAD67"}
              borderRadius="6px"
              onClick={handleClick}
              isLoading={isLoading}
            >
              <IconUpload /> Excel
            </Button>
            <input
              type="file"
              onChange={handleFileSelect}
              ref={hiddenFileInput}
              id="hiddenFileInput"
              style={{ display: "none" }}
            />
          </Box>
          <Box pl="2">
            <Button
              colorScheme="whatsapp"
              backgroundColor={"#5FAD67"}
              borderRadius="6px"
              // onClick={() => handleNavigate()}
            >
              Thêm mới
            </Button>
          </Box>
        </Flex> */}
      </Flex>
      <Table
        columns={columns}
        dataSource={studentList}
        text={"sinh viên"}
        currentPage={currentPage}
        loading={loading}
        onChangeCurrentPage={onChangeCurrentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        width={"2400px"}
      />
    </Box>
  );
};

export default View;
