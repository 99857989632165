import { Box, Flex, Text } from "@chakra-ui/react";
import { Form, FormInstance, Input, message } from "antd";
import ButtonFooter from "components/ButtonFooter";
import React, { useEffect, useMemo, useState } from "react";
import { PaymentPartnerDetail } from "service/OrganizationAdmin/Organization";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { globalStyles } from "theme/styles";
import { validResponse } from "util/API";
import { CODE_SUCCESS } from "util/Constant";
import { handleThrowError } from "util/general";
import * as Sentry from "@sentry/browser";

interface IProps {
  formRef: React.MutableRefObject<FormInstance<any>>;
  paymentPartnerDetail: PaymentPartnerDetail;
  handleGetDetailPaymentParner: () => Promise<void>;
}

const FormSetUp = ({
  formRef,
  paymentPartnerDetail,
  handleGetDetailPaymentParner,
}: IProps) => {
  const ID = useMemo(() => window.location.hash.split("/")[5], []);
  const orgCode = useMemo(() => window.location.hash.split("/")[2], []);
  const schoolCode = useMemo(() => window.location.hash.split("/")[4], []);
  const [isEdit, setIsEdit] = useState(false);

  const { PaymentPartnerUpdate } = OrganizationAdminService;

  const onFinish = async (values: any) => {
    try {
      // add _id
      values._id = paymentPartnerDetail._id;
      const response = await PaymentPartnerUpdate(
        orgCode,
        schoolCode,
        ID.toLocaleUpperCase(),
        values
      );
      if (
        response &&
        validResponse(response) &&
        response.data.code === CODE_SUCCESS &&
        response.data.data.success !== false
      ) {
        setIsEdit(false);
        message.success("Chỉnh sửa thông tin chung thành công");
        handleGetDetailPaymentParner();
      } else {
        if (response && validResponse(response)) {
          handleThrowError(
            response.data.data?.errors
              ? response.data.data?.errors
              : response.data.data?.message
          );
          response.data.data?.errors
            ? Sentry.captureException(response.data.data?.errors)
            : Sentry.captureMessage(response.data.data?.message);
          // message.error(`${response.data.data.message}`);
        }
      }
    } catch (error) {
      console.log(error, "this is error");
      Sentry.captureException(error);
    }
  };

  const handleDisable = () => {
    if (paymentPartnerDetail && !isEdit) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isEdit && paymentPartnerDetail) {
      formRef?.current?.setFieldsValue({
        partnerCode: paymentPartnerDetail.partnerCode,
        partnerName: paymentPartnerDetail.partnerName,
        abbreviation: paymentPartnerDetail.abbreviation,
        type: paymentPartnerDetail.type,
        taxCode: paymentPartnerDetail.taxCode,
        address: paymentPartnerDetail.address,
      });
    }
    // eslint-disable-next-line
  }, [isEdit]);

  return (
    <Form
      name="basic"
      autoComplete="off"
      labelCol={{ span: 6 }}
      ref={formRef}
      onFinish={onFinish}
    >
      <Box w="80%" maxW="755px" mt={5}>
        <Flex justifyContent={"space-between"} mb="5">
          <Text
            color={globalStyles.colors.text[202027]}
            fontSize="14px"
            fontWeight="600"
            my="3"
          >
            Thông tin chung
          </Text>
          {paymentPartnerDetail && (
            <ButtonFooter
              formRef={formRef}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
          )}
        </Flex>

        <Form.Item
          name="partnerCode"
          label="Mã đại lý"
          labelAlign="left"
          rules={[{ required: true, message: "Vui lòng nhập mã đại lý!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Mã đại lý"
            disabled={handleDisable()}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tên đại lý"
          name="partnerName"
          rules={[{ required: true, message: "Vui lòng nhập tên đại lý!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Tên đại lý"
            disabled={handleDisable()}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tên viết tắt"
          name="abbreviation"
          rules={[{ required: true, message: "Vui lòng nhập tên viết tắt!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Tên viết tắt"
            disabled={handleDisable()}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Loại hình"
          name="type"
          rules={[{ required: true, message: "Vui lòng nhập loại hình!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Loại hình"
            disabled={handleDisable()}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã số thuế"
          name="taxCode"
          rules={[{ required: true, message: "Vui lòng nhập Mã số thuế!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Mã số thuế"
            disabled={handleDisable()}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Địa chỉ đăng ký kinh doanh"
          name="address"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập địa chỉ đăng ký kinh doanh!",
            },
          ]}
        >
          <Input
            className="custom-input"
            placeholder="Địa chỉ đăng ký kinh doanh"
            disabled={handleDisable()}
          />
        </Form.Item>
      </Box>
    </Form>
  );
};

export default FormSetUp;
