import { Box, Flex, Text } from "@chakra-ui/react";
import { Form, FormInstance, Input } from "antd";
import MainButton from "components/button";
import React, { FC } from "react";
import { globalStyles } from "theme/styles";
import "./formLoginStyle.css";

interface IProps {
  active: number;
  onFinish: (values: any) => void;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}

const FormLogin: FC<IProps> = ({ active, onFinish, setActive }: IProps) => {
  const formRef = React.useRef<FormInstance>(null);

  const FormAdmin = () => {
    return (
      <>
        <Form.Item
          labelAlign="left"
          label="Tài khoản"
          name="username"
          rules={[
            { required: true, message: "Tài khoản không được để trống!" },
          ]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: "Mật khẩu không được để trống!" }]}
        >
          <Input.Password className="custom-input w-330" />
        </Form.Item>
      </>
    );
  };
  const FormCSDT = () => {
    return (
      <>
        <Form.Item
          labelAlign="left"
          label="Mã tổ chức"
          name="orgCode"
          rules={[
            { required: true, message: "Mã tổ chức không được để trống!" },
          ]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mã CSĐT"
          name="schoolCode"
          rules={[{ required: true, message: "Mã CSĐT không được để trống!" }]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tài khoản"
          name="username"
          rules={[
            { required: true, message: "Tài khoản không được để trống!" },
          ]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: "Mật khẩu không được để trống!" }]}
        >
          <Input.Password className="custom-input w-330" />
        </Form.Item>
      </>
    );
  };
  const FormOSA = () => {
    return (
      <>
        <Form.Item
          labelAlign="left"
          label="Mã tổ chức"
          name="orgCode"
          rules={[
            { required: true, message: "Mã tổ chức không được để trống!" },
          ]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Tài khoản"
          name="username"
          rules={[
            { required: true, message: "Tài khoản không được để trống!" },
          ]}
        >
          <Input className="custom-input w-330" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: "Mật khẩu không được để trống!" }]}
        >
          <Input.Password className="custom-input w-330" />
        </Form.Item>
      </>
    );
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      style={{ maxWidth: 530 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      className="form-login"
      ref={formRef}
    >
      <Text
        color={globalStyles.colors.text[200]}
        textAlign="center"
        fontSize={"26px"}
        fontWeight="500"
      >
        Đăng nhập
      </Text>
      <Flex justifyContent={"space-between"} px="8" my="5">
        <Box>
          <Text
            onClick={() => setActive(1)}
            textAlign="center"
            fontSize={"15px"}
            fontWeight="500"
            color={
              active === 1
                ? globalStyles.colors.text[202027]
                : globalStyles.colors.text.A7A7A7
            }
            cursor="pointer"
          >
            Quản trị Edubills
          </Text>
          <Box
            w="100px"
            h="2px"
            backgroundColor={`${globalStyles.colors.text[45993]}`}
            mx="auto"
            mt="1"
            visibility={active === 1 ? "inherit" : "hidden"}
          />
        </Box>
        <Box>
          <Text
            onClick={() => setActive(2)}
            color={
              active === 2
                ? globalStyles.colors.text[202027]
                : globalStyles.colors.text.A7A7A7
            }
            textAlign="center"
            fontSize={"15px"}
            fontWeight="500"
            cursor="pointer"
          >
            Người dùng Cơ sở đào tạo
          </Text>
          <Box
            w="100px"
            h="2px"
            backgroundColor={`${globalStyles.colors.text[45993]}`}
            mx="auto"
            mt="1"
            visibility={active === 2 ? "inherit" : "hidden"}
          />
        </Box>
        <Box>
          <Text
            onClick={() => setActive(3)}
            color={
              active === 3
                ? globalStyles.colors.text[202027]
                : globalStyles.colors.text.A7A7A7
            }
            textAlign="center"
            fontSize={"15px"}
            fontWeight="500"
            cursor="pointer"
          >
            Người dùng Tổ chức
          </Text>
          <Box
            w="100px"
            h="2px"
            backgroundColor={`${globalStyles.colors.text[45993]}`}
            mx="auto"
            mt="1"
            visibility={active === 3 ? "inherit" : "hidden"}
          />
        </Box>
      </Flex>
      <Box p="3" pl={6}>
        {active === 1 && <FormAdmin />}
        {active === 2 && <FormCSDT />}
        {active === 3 && <FormOSA />}
        <Flex
          flexDirection={"row"}
          alignContent="center"
          justifyContent="center"
        >
          <Box ml="2">
            <MainButton htmlType="submit" text="Xác nhận" />
          </Box>
        </Flex>
      </Box>
    </Form>
  );
};

export default FormLogin;
