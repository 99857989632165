import { Box } from "@chakra-ui/react";
import { Button } from "antd";
import React from "react";

interface P {
  activeBtn: number;
  setActiveBtn: React.Dispatch<React.SetStateAction<number>>;
}

const TabsPanel = ({ activeBtn, setActiveBtn }: P) => {
  return (
    <Box className="tabs-panel">
      <Button
        className={`organization-information ${
          activeBtn === 1 ? "active" : ""
        }`}
        onClick={() => {
          setActiveBtn(1);
        }}
      >
        Thiết lập tài khoản thanh toán
      </Button>
      <Button
        className={`training-facilities ${activeBtn === 2 ? "active" : ""}`}
        onClick={() => {
          setActiveBtn(2);
        }}
      >
        Thiết lập điều hướng giao dịch
      </Button>
    </Box>
  );
};

export default TabsPanel;
