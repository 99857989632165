import { Box, Flex } from "@chakra-ui/react";
import { Form, FormInstance, Input, Select } from "antd";
import MainButton from "components/button";
import ButtonFooter from "components/ButtonFooter";
import React, { useEffect, useState } from "react";
import { InputOrganizationCreate } from "service/Auth";
import { PATTERN_PHONE } from "util/Constant";

const { Option } = Select;

interface IProps {
  onFinish: (values: any) => void;
  organizationCreate: InputOrganizationCreate;
}

const FormLoginAccount = ({ onFinish, organizationCreate }: IProps) => {
  const formRef = React.useRef<FormInstance>(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    organizationCreate &&
      formRef?.current?.setFieldsValue({
        username: organizationCreate.username,
        fullName: organizationCreate.fullName,
        email: organizationCreate.email,
        phone: organizationCreate.phone,
        address: organizationCreate.address,
        status: organizationCreate.status ? "Hoạt động" : "Không hoạt động",
      });
  }, [organizationCreate]);

  useEffect(() => {
    !isEdit &&
      organizationCreate &&
      formRef?.current?.setFieldsValue({
        username: organizationCreate.username,
        fullName: organizationCreate.fullName,
        email: organizationCreate.email,
        phone: organizationCreate.phone,
        address: organizationCreate.address,
        status: organizationCreate.status ? "Hoạt động" : "Không hoạt động",
      });
    // eslint-disable-next-line
  }, [isEdit]);

  useEffect(() => {
    setIsEdit(false);
  }, [organizationCreate]);

  return (
    <Form
      name="basic"
      onFinish={(values) => {
        onFinish(values);
        setIsEdit(false);
      }}
      autoComplete="off"
      labelCol={{ span: 4 }}
      ref={formRef}
    >
      <Box w="80%" maxW="755px">
        <Form.Item
          name="username"
          label="Tên tài khoản"
          labelAlign="left"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập tên tài khoản!" }]}
        >
          <Input
            className="custom-input"
            placeholder="Tên tài khoản"
            disabled={!!organizationCreate}
          />
        </Form.Item>
        <Form.Item
          name="fullName"
          label="Họ và tên"
          labelAlign="left"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập họ và tên!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Họ và tên"
            defaultValue={organizationCreate?.fullName}
            disabled={organizationCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          labelAlign="left"
          className="text-bold"
          rules={[
            { required: true, message: "Vui lòng nhập Email!" },
            { type: "email", message: "Vui lòng nhập đúng định dạng email!" },
          ]}
        >
          <Input
            className="custom-input "
            placeholder="Email"
            defaultValue={organizationCreate?.email}
            disabled={organizationCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Số điện thoại"
          labelAlign="left"
          className="text-bold"
          rules={[
            {
              pattern: PATTERN_PHONE,
              message: "Vui lòng nhập đúng định dạng số điện thoại!",
            },
            {
              required: true,
              message: "Vui lòng nhập số điện thoại!",
            },
          ]}
        >
          <Input
            className="custom-input "
            placeholder="Số điện thoại"
            defaultValue={organizationCreate?.phone}
            disabled={organizationCreate ? !isEdit : false}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Địa chỉ"
          labelAlign="left"
          className="text-bold"
          rules={[{ required: true, message: "Vui lòng nhập Địa chỉ!" }]}
        >
          <Input
            className="custom-input "
            placeholder="Địa chỉ"
            defaultValue={organizationCreate?.address}
            disabled={organizationCreate ? !isEdit : false}
          />
        </Form.Item>
        {organizationCreate && (
          <Form.Item
            labelAlign="left"
            label="Trạng thái"
            className="text-bold"
            name="status"
            // initialValue={initValue?.status}
            rules={[{ required: false, message: "Vui lòng nhập trạng thái!" }]}
          >
            <Select
              className="w-full custom-input"
              defaultValue={"Hoạt động"}
              disabled={organizationCreate ? !isEdit : false}
            >
              <Option value="1">Hoạt động</Option>
              <Option value="0">Không hoạt động</Option>
            </Select>
          </Form.Item>
        )}

        {organizationCreate ? (
          <ButtonFooter
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            text2={"Cập nhật"}
            formRef={formRef}
          />
        ) : (
          <Flex
            flexDirection={"row"}
            alignContent="center"
            justifyContent="flex-end"
            mt="6"
          >
            <MainButton htmlType="submit" text="Xác nhận" />
          </Flex>
        )}
      </Box>
    </Form>
  );
};

export default FormLoginAccount;
