import { Box } from "@chakra-ui/react";
import { Button } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setActiveRoute, setBreadcrumb } from "store/appSlice";
import { ADMIN } from "util/Constant";

interface P {
  activeBtn: number;
}

const TabsPanel = ({ activeBtn }: P) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const orgCode = useMemo(() => window.location.hash.split("/")[3], []);

  useEffect(() => {
    dispatch(setActiveRoute("/organization-management"));
    return () => {
      dispatch(setActiveRoute(""));
      dispatch(setBreadcrumb([]));
      // localStorage.clear();
    };
  }, [dispatch]);

  return (
    <Box className="tabs-panel">
      <Button
        className={`organization-information ${
          activeBtn === 1 ? "active" : ""
        }`}
        onClick={() => {
          history.push(`${ADMIN}/add-new-organization/${orgCode}`);
        }}
      >
        Thông tin tổ chức
      </Button>
      <Button
        className={`login-account ${activeBtn === 2 ? "active" : ""}`}
        onClick={() => history.push(`${ADMIN}/login-account/${orgCode}`)}
      >
        Tài khoản đăng nhập
      </Button>
      <Button
        className={`training-facilities ${activeBtn === 3 ? "active" : ""}`}
        onClick={() => history.push(`${ADMIN}/training-facilities/${orgCode}`)}
      >
        Cơ sở đào tạo
      </Button>
    </Box>
  );
};

export default TabsPanel;
