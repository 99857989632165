import { Box } from "@chakra-ui/react";
import { InputRef, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrganizationAdminService from "service/OrganizationAdmin/OrganizationAdminService";
import { RootState } from "store";
import {
  setActiveRoute,
  setBrandText,
  setBreadcrumb,
  setLoadingApp,
  Type_Initstate,
} from "store/appSlice";
import { globalStyles } from "theme/styles";
import { IPlainOptionsMap } from "types/general";
import { NetWorkService, validResponse } from "util/API";
import { CODE_SUCCESS, EnumPermission } from "util/Constant";
import { handleThrowError } from "util/general";
import { FilterAntdHook } from "util/hook/filterAntdHook";
import TableManager from "./Components/TableManager";
import * as Sentry from "@sentry/browser";

const DebtBrickHistory = () => {
  const dispatch = useDispatch();
  const [billList, setBillList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState(0);
  const [pageSize] = useState(20);
  const [searchedColumn, setSearchedColumn] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const searchInput = useRef<InputRef>(null);

  const { appPermission, authInfor } = useSelector<RootState, Type_Initstate>(
    (state) => state.app
  );

  const plainOptionsMap: IPlainOptionsMap[] = [
    { name: "Thành công", value: "1", type: "eq" },
    { name: "Đang chờ xử lý", value: "2", type: "eq" },
    { name: "Thất bại", value: "0", type: "eq" },
  ];
  const defaultChecked = "";

  const {
    handleSearch,
    handleSetQuery,
    getColumnDateProps,
    getColumnSearchProps,
    getColumnSelectProps,
  } = FilterAntdHook({
    searchInput,
    // plainOptions,
    searchedColumn,
    plainOptionsMap,
    defaultChecked,
    setCurrentPage,
    setSearchedColumn,
  });

  const onChangeCurrentPage = (e: any) => {
    setCurrentPage(e);
  };

  const formatData = (arr: any[]) => {
    const data = arr.map((e, i) => {
      return {
        ...e,
        key: e._id,
        stt: i + 1 + pageSize * currentPage - pageSize,
      };
    });

    return data;
  };

  const getData = async () => {
    try {
      setLoading(true);
      let query = `?limit=${pageSize}&offset=${
        pageSize * currentPage - pageSize
      }&createdAt[sort]=desc`;

      const URL =
        appPermission === EnumPermission.ADMIN
          ? "/deptBrick-history"
          : `organization/${authInfor.orgCode}/school/${authInfor.schoolCode}/deptBrick-history`;
      const res = await NetWorkService.Get({
        url: URL + handleSetQuery(query),
      });
      if (res && validResponse(res) && res.code === CODE_SUCCESS) {
        const respons = res.data;
        setBillList(formatData(respons.data.records));
        setTotalRow(
          respons?.data.pagination?.totalRows || respons.data.length || 0
        );
      } else {
        setTotalRow(0);
        setBillList([]);
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRetryBrick = async (
    orgCode: string,
    schoolCode: string,
    id: string
  ) => {
    try {
      dispatch(setLoadingApp(true));
      const res = await OrganizationAdminService.RETRY_DEPBRICK(
        orgCode,
        schoolCode,
        id
      );
      if (
        res &&
        validResponse(res) &&
        res.data.code === CODE_SUCCESS &&
        res.data.data.success !== false
      ) {
        message.success("Gọi lại gạch nợ thành công");
        setTimeout(() => {
          getData();
        }, 1000);
      } else {
        if (res && validResponse(res)) {
          handleThrowError(
            res.data.data?.errors
              ? res.data.data?.errors
              : res.data.data?.message
          );
          res.data.data?.errors
            ? Sentry.captureException(res.data.data?.errors)
            : Sentry.captureMessage(res.data.data?.message);
        } else {
          message.error("Có lỗi xảy ra");
          Sentry.captureMessage("Có lỗi xảy ra");
        }
      }
    } catch (error) {
      message.error("Có lỗi xảy ra");
      console.log(error);
      Sentry.captureException(error);
    } finally {
      dispatch(setLoadingApp(false));
    }
  };

  useEffect(() => {
    if (searchedColumn) {
      authInfor && getData();
    }
    // eslint-disable-next-line
  }, [searchedColumn]);

  useEffect(() => {
    if (currentPage) {
      authInfor && getData();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    authInfor && getData();
    // eslint-disable-next-line
  }, [authInfor]);

  useEffect(() => {
    dispatch(setActiveRoute(""));
    dispatch(setBreadcrumb([]));
    dispatch(setBrandText(undefined));
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={{ base: "10px", md: "10px" }}
      minH="calc(100vh - 140px)"
      background={globalStyles.colors.bg_color[200]}
      borderRadius="6px"
      position="relative"
    >
      <TableManager
        billList={billList}
        currentPage={currentPage}
        pageSize={pageSize}
        totalRow={totalRow}
        loading={loading}
        handleSearch={handleSearch}
        handleRetryBrick={handleRetryBrick}
        getColumnDateProps={getColumnDateProps}
        onChangeCurrentPage={onChangeCurrentPage}
        getColumnSearchProps={getColumnSearchProps}
        getColumnSelectProps={getColumnSelectProps}
      />
    </Box>
  );
};

export default DebtBrickHistory;
